import { type CSSProperties, type JSX, type MutableRefObject, memo } from "react";
import SliderPreview from "../SliderPreview";

export type IChartDatum = {
    statement: string;
    value: number;
    leftLabel: string;
    rightLabel: string;
};

export interface IProps {
    chartData: IChartDatum[];
    chartRef?: MutableRefObject<HTMLDivElement>;
    style?: CSSProperties;
}

function AnswerChart({ chartData, chartRef, style }: IProps): JSX.Element {
    return (
        <div style={style} ref={chartRef}>
            {chartData.map((datum, index) => (
                <SliderPreview
                    key={index}
                    statement={datum.statement}
                    leftLabel={datum.leftLabel}
                    rightLabel={datum.rightLabel}
                    value={datum.value}
                    showValue
                    precision={1}
                />
            ))}
        </div>
    );
}

export default memo(AnswerChart);
