import type { ValidationErrors } from "final-form";
import { useCallback, type JSX } from "react";
import { Form } from "react-final-form";
import { useMutation } from "urql";
import { useAlerts } from "../../shared/components/Alerts";
import t from "../../shared/translations";
import Input from "./Input";
import type { IData } from "./index";
import {
    type IAssociatePersonalArgs,
    type IAssociatePersonalData,
    type IAssociateReusableArgs,
    type IAssociateReusableData,
    associatePersonalMutation,
    associateReusableMutation,
} from "./queries";

interface IProps {
    data: IData;
    reusable: boolean;
}

interface IFormValues {
    email: string;
    password: string;
}

function validate(values: IFormValues) {
    const errors: ValidationErrors = {};
    if (!values.email) {
        errors.email = t("public.create-account-login.form-error-email-required");
    } else if (!values.email.includes("@")) {
        errors.email = t("public.create-account-login.form-error-email-must-be-email");
    }
    if (!values.password) {
        errors.password = t("public.create-account-login.form-error-password-required");
    }
    return errors;
}

const defaultValues: IFormValues = {
    email: "",
    password: "",
};

function LoginForm({ data, reusable }: IProps): JSX.Element {
    const [, executePersonalMutation] = useMutation<IAssociatePersonalData, IAssociatePersonalArgs>(
        associatePersonalMutation,
    );
    const [__, executeReusableMutation] = useMutation<IAssociateReusableData, IAssociateReusableArgs>(
        associateReusableMutation,
    );
    const addAlert = useAlerts();

    const onPersonalSubmit = useCallback(
        async (values: IFormValues) => {
            const result = await executePersonalMutation({
                data: {
                    ...values,
                    publicId: data.publicId,
                },
            });
            if (result.error) {
                console.error(result.error);
                addAlert(t("public.error.error"), "alert");
            } else {
                if (result.data.accountInvitationAssociateAccount.error) {
                    console.error(result.data.accountInvitationAssociateAccount.error);
                    addAlert(result.data.accountInvitationAssociateAccount.error, "alert");
                } else {
                    window.location.href = result.data.accountInvitationAssociateAccount.redirectUrl;
                }
            }
        },
        [data, executePersonalMutation],
    );

    const onReusableSubmit = useCallback(
        async (values: IFormValues) => {
            const result = await executeReusableMutation({
                data: {
                    ...values,
                    publicId: data.publicId,
                },
            });
            if (result.error) {
                console.error(result.error);
                addAlert(t("public.error.error"), "alert");
            } else {
                if (result.data.reusableAccountInvitationAssociateAccount.error) {
                    console.error(result.data.reusableAccountInvitationAssociateAccount.error);
                    addAlert(result.data.reusableAccountInvitationAssociateAccount.error, "alert");
                } else {
                    window.location.href = result.data.reusableAccountInvitationAssociateAccount.redirectUrl;
                }
            }
        },
        [data, executeReusableMutation],
    );

    const onSubmit = reusable ? onReusableSubmit : onPersonalSubmit;

    return (
        <Form onSubmit={onSubmit} validate={validate} initialValues={defaultValues}>
            {({ handleSubmit, pristine, submitting }) => (
                <form onSubmit={handleSubmit}>
                    <Input name="email" label={t("public.create-account-login.form-label-email")} />
                    <Input
                        name="password"
                        label={t("public.create-account-login.form-label-password")}
                        type="password"
                    />
                    <div className="text-center">
                        <button type="submit" disabled={pristine || submitting}>
                            {t("public.create-account-login.form-submit")}
                        </button>
                    </div>
                </form>
            )}
        </Form>
    );
}

export default LoginForm;
