import { parseDate, validDate } from "../../../../shared/dateFns";
import * as completions from "../../completionsData";
import * as milesBar from "../../milesBarData";
import * as milesLine from "../../milesLineData";
import type { ILearningPath } from "../../queries";
import * as taskCountLine from "../../taskCountLineData";
import type { IState } from "../Report";
import { v1tov2 } from "./v2";
import { type V3ChartDatum as ChartDatum, type IV3ParsedData as IParsedData, v2tov3 } from "./v3";

function replacer(_key: string, value: any): any {
    if (value instanceof Map) {
        return {
            dataType: "Map",
            value: Array.from(value.entries()),
        };
    }
    return value;
}

function reviver(_key: string, value: any, people: { id: string; firstName: string; lastName: string }[]): any {
    if (typeof value === "object" && value !== null) {
        if (value.dataType === "Map") {
            return new Map(value.value);
        }
    }
    if (typeof value === "string") {
        if (value.length > 10 && value[10] === "T") {
            const date = parseDate(value);
            if (validDate(date)) {
                return date;
            }
        }
        if (value.startsWith("$id-")) {
            const id = value.slice(4);
            const person = people.find((p) => p.id === id);
            if (person) {
                return `${person.firstName} ${person.lastName}`;
            }
            return "";
        }
    }
    return value;
}

export const VERSION = 3;

export function stringifyData(state: IState, path: ILearningPath, version: number): string {
    const charts: ChartDatum[] = [];
    for (const chartState of state.charts) {
        switch (chartState.chart) {
            case "miles-bar":
                (() => {
                    const processedData = milesBar.preprocessData(path, false);
                    const formattedData = milesBar.formatData(chartState.state, processedData, path);
                    charts.push({
                        chart: "miles-bar",
                        heading: chartState.heading,
                        data: {
                            chartData: formattedData.chartData,
                            checkpoint: formattedData.checkpoint,
                            goal: formattedData.goal,
                            deadlineGoal: formattedData.deadlineGoal,
                        },
                    });
                })();
                continue;
            case "miles-line":
                (() => {
                    const processedData = milesLine.preprocessData(path);
                    const formattedData = milesLine.formatData(chartState.state, processedData, path);
                    charts.push({
                        chart: "miles-line",
                        heading: chartState.heading,
                        data: {
                            chartData: formattedData.chartData,
                            state: {
                                yAxis: chartState.state.yAxis,
                                xAxis: chartState.state.xAxis,
                                relative: chartState.state.relative,
                                linesDeadline: chartState.state.linesDeadline,
                                maxTicks: chartState.state.maxTicks,
                            },
                            teams: processedData.teams,
                            groups: processedData.groups,
                            orgs: processedData.orgs,
                            checkpoint: formattedData.checkpoint,
                            goal: formattedData.goal,
                        },
                    });
                })();
                continue;
            case "task-count":
                (() => {
                    const processedData = taskCountLine.preprocessData(path);
                    const formattedData = taskCountLine.formatData(chartState.state, processedData);
                    charts.push({
                        chart: "task-count",
                        heading: chartState.heading,
                        data: {
                            chartData: formattedData.chartData,
                            state: {
                                xAxis: chartState.state.xAxis,
                                relative: chartState.state.relative,
                                maxTicks: chartState.state.maxTicks,
                                dataLabels: chartState.state.dataLabels,
                            },
                            tasks: formattedData.filteredTasks,
                            numMembers: processedData.numMembers,
                        },
                    });
                })();
                continue;
            case "completions-table":
                (() => {
                    const processedData = completions.preprocessData(path, false);
                    const formattedData = completions.formatData(chartState.state, processedData);
                    charts.push({
                        chart: "completions-table",
                        heading: chartState.heading,
                        data: {
                            people: formattedData.filteredPeople,
                            tasks: formattedData.filteredTasks,
                            miles: formattedData.filteredMiles,
                            counts: formattedData.filteredCounts,
                            totals: formattedData.filteredTotals,
                            completions: formattedData.completions,
                            pastDeadlines: formattedData.pastDeadlines,
                            state: {
                                showNames: chartState.state.showNames,
                                rowsPerColumn: chartState.state.rowsPerColumn,
                                groupBy: chartState.state.groupBy,
                            },
                        },
                    });
                })();
                continue;
            case "statistics":
                continue;
            default:
                continue;
        }
    }

    return JSON.stringify(
        {
            charts,
            version,
            name: state.name,
        },
        replacer,
    );
}

export function parseData(data: string, people: { id: string; firstName: string; lastName: string }[]): IParsedData {
    let parsedData = JSON.parse(data, (key, value) => reviver(key, value, people));
    if (parsedData.version === 1) {
        parsedData = v1tov2(parsedData) as any;
    }
    if (parsedData.version === 2) {
        parsedData = v2tov3(parsedData) as any;
    }
    return parsedData as IParsedData;
}
