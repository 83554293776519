import { type ComponentPropsWithoutRef, type JSX, memo } from "react";

function LikertIcon(props: ComponentPropsWithoutRef<"svg">): JSX.Element {
    return (
        <svg
            clipRule="evenodd"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="m14.086 12.852v73l72-.4c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5l-71.1.5h-2.4c-.4 0-.8-.1-1.1-.4-.2-.3-.4-.7-.4-1.1v-74.6c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5z" />
            <path d="m42.707 73.5h-18.753l1.587 1.058c.689.459.876 1.391.416 2.08-.459.689-1.391.876-2.08.416l-7.581-5.054 7.581-5.054c.689-.46 1.621-.273 2.08.416.46.689.273 1.621-.416 2.08l-1.587 1.058h18.753c.654-2.307 2.777-4 5.293-4s4.639 1.693 5.293 4h27.753l-1.587-1.058c-.689-.459-.876-1.391-.416-2.08.459-.689 1.391-.876 2.08-.416l7.581 5.054-7.581 5.054c-.689.46-1.621.273-2.08-.416-.46-.689-.273-1.621.416-2.08l1.587-1.058h-27.753c-.654 2.307-2.777 4-5.293 4s-4.639-1.693-5.293-4zm5.293-4c-1.38 0-2.5 1.12-2.5 2.5s1.12 2.5 2.5 2.5 2.5-1.12 2.5-2.5-1.12-2.5-2.5-2.5z" />
            <path d="m52.707 51.5h-28.753l1.587 1.058c.689.459.876 1.391.416 2.08-.459.689-1.391.876-2.08.416l-7.581-5.054 7.581-5.054c.689-.46 1.621-.273 2.08.416.46.689.273 1.621-.416 2.08l-1.587 1.058h28.753c.654-2.307 2.777-4 5.293-4s4.639 1.693 5.293 4h17.753l-1.587-1.058c-.689-.459-.876-1.391-.416-2.08.459-.689 1.391-.876 2.08-.416l7.581 5.054-7.581 5.054c-.689.46-1.621.273-2.08-.416-.46-.689-.273-1.621.416-2.08l1.587-1.058h-17.753c-.654 2.307-2.777 4-5.293 4s-4.639-1.693-5.293-4zm5.293-4c-1.38 0-2.5 1.12-2.5 2.5s1.12 2.5 2.5 2.5 2.5-1.12 2.5-2.5-1.12-2.5-2.5-2.5z" />
            <path d="m60.707 29.5h-36.753l1.587 1.058c.689.459.876 1.391.416 2.08-.459.689-1.391.876-2.08.416l-7.581-5.054 7.581-5.054c.689-.46 1.621-.273 2.08.416.46.689.273 1.621-.416 2.08l-1.587 1.058h36.753c.654-2.307 2.777-4 5.293-4s4.639 1.693 5.293 4h9.753l-1.587-1.058c-.689-.459-.876-1.391-.416-2.08.459-.689 1.391-.876 2.08-.416l7.581 5.054-7.581 5.054c-.689.46-1.621.273-2.08-.416-.46-.689-.273-1.621.416-2.08l1.587-1.058h-9.753c-.654 2.307-2.777 4-5.293 4s-4.639-1.693-5.293-4zm5.293-4c-1.38 0-2.5 1.12-2.5 2.5s1.12 2.5 2.5 2.5 2.5-1.12 2.5-2.5-1.12-2.5-2.5-2.5z" />
        </svg>
    );
}

export default memo(LikertIcon);
