import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import type { TaskIcon, TaskTheme } from "../../shared/types";

export interface IBuilderTask {
    id: string;
    icon: TaskIcon;
    miles: number;
    shortTitle: string;
    price: number;
    length: number;
    themes: {
        id: string;
        theme: TaskTheme;
        value: number;
    }[];
    description: string | null;
    shortDescription: string | null;
    learningCoachFeedback: boolean;
    isGroupTask: boolean;
    isMedia: boolean;
    isPeopleEvent: boolean;
    isLecture: boolean;
}

interface IBuilderState {
    loading: boolean;
    name: string;
    tasks: IBuilderTask[];
    modalTask: IBuilderTask | null;
    modalIndex: number | null;
    showThemes: boolean;
    showMiles: boolean;
    showLength: boolean;
    showPrice: boolean;
}

const initialState: IBuilderState = {
    loading: true,
    name: "",
    tasks: [],
    modalTask: null,
    modalIndex: null,
    showThemes: false,
    showMiles: false,
    showLength: false,
    showPrice: false,
};

interface IBuilderTaskOnPath {
    id: string;
    builderTask: IBuilderTask;
}

const builderSlice = createSlice({
    name: "builder",
    initialState,
    reducers: {
        initEmptyBuilderPath(state, action: PayloadAction<string>) {
            state.name = action.payload;
            state.showThemes = false;
            state.showMiles = false;
            state.showLength = false;
            state.showPrice = false;
            state.tasks = [];
            state.loading = false;
        },
        initBuilderPath(
            state,
            action: PayloadAction<{
                name: string;
                showThemes: boolean;
                showMiles: boolean;
                showLength: boolean;
                showPrice: boolean;
                taskOnPaths: IBuilderTaskOnPath[];
            }>,
        ) {
            const { name, showThemes, showMiles, showLength, showPrice, taskOnPaths } = action.payload;
            state.name = name;
            state.showThemes = showThemes;
            state.showMiles = showMiles;
            state.showLength = showLength;
            state.showPrice = showPrice;
            state.tasks = taskOnPaths.map((taskOnPath) => taskOnPath.builderTask);
            state.loading = false;
        },
        setModalTask(state, action: PayloadAction<{ task: IBuilderTask; index: number }>) {
            state.modalTask = action.payload.task;
            state.modalIndex = action.payload.index;
        },
        addTaskToPath(state, action: PayloadAction<{ task: IBuilderTask; where: "start" | "end" }>) {
            if (action.payload.where === "start") {
                state.tasks.unshift(action.payload.task);
            } else {
                state.tasks.push(action.payload.task);
            }
        },
        addTaskToPathAtIndex(state, action: PayloadAction<{ task: IBuilderTask; index: number }>) {
            state.tasks.splice(action.payload.index, 0, action.payload.task);
        },
        removeTaskFromPath(state, action: PayloadAction<number>) {
            state.tasks.splice(action.payload, 1);
        },
        moveTaskOnPath(state, action: PayloadAction<{ index: number; target: "start" | "end" | number }>) {
            const { index, target } = action.payload;
            const task = state.tasks[index];
            state.tasks.splice(index, 1);
            if (target === "start") {
                state.tasks.unshift(task);
                state.modalIndex = 0;
            } else if (target === "end") {
                state.tasks.push(task);
                state.modalIndex = state.tasks.length - 1;
            } else {
                if (target < 0) {
                    state.tasks.splice(0, 0, task);
                    state.modalIndex = 0;
                } else {
                    state.tasks.splice(target, 0, task);
                    state.modalIndex = Math.min(target, state.tasks.length - 1);
                }
            }
        },
        updatePathName(state, action: PayloadAction<string>) {
            state.name = action.payload;
        },
        toggleShowThemes(state, action: PayloadAction<boolean>) {
            state.showThemes = action.payload;
        },
        toggleShowMiles(state, action: PayloadAction<boolean>) {
            state.showMiles = action.payload;
        },
        toggleShowLength(state, action: PayloadAction<boolean>) {
            state.showLength = action.payload;
        },
        toggleShowPrice(state, action: PayloadAction<boolean>) {
            state.showPrice = action.payload;
        },
    },
});

export const {
    initEmptyBuilderPath,
    initBuilderPath,
    setModalTask,
    addTaskToPath,
    addTaskToPathAtIndex,
    removeTaskFromPath,
    moveTaskOnPath,
    updatePathName,
    toggleShowThemes,
    toggleShowMiles,
    toggleShowLength,
    toggleShowPrice,
} = builderSlice.actions;

export default builderSlice.reducer;
