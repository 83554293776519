import PulseIcon from "../../shared/components/icons/taskIcons/PulseIcon";
import t from "../../shared/translations";
import { FinishedBox } from "./stylings";
import type { JSX } from "react";

function Finished(): JSX.Element {
    return (
        <FinishedBox>
            <PulseIcon active />
            <h2>{t("public.pulse.heading-finished")}</h2>
        </FinishedBox>
    );
}

export default Finished;
