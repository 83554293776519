import type { ValidationErrors } from "final-form";
import { useCallback, type JSX } from "react";
import { Form } from "react-final-form";
import { useMutation } from "urql";
import { useAPIAlert } from "../../shared/components/Alerts";
import t from "../../shared/translations";
import Input from "../Input";

interface IProps {
    setSent: (sent: boolean) => void;
}

interface IArgs {
    email: string;
}

const mutation = `
    mutation($email:String!) {
        resetTokenSendToken(email:$email) {
            error
        }
    }
`;

interface IData {
    resetTokenSendToken: {
        error?: string;
    };
}

interface IFormValues {
    email: string;
}

const defaultValues: IFormValues = {
    email: "",
};

function validate(values: IFormValues): ValidationErrors {
    const errors: ValidationErrors = {};
    if (!values.email || !values.email.includes("@")) {
        errors.email = t("public.reset-password.form-reset-error-email");
    }
    return errors;
}

function ResetForm({ setSent }: IProps): JSX.Element {
    const [_, executeMutation] = useMutation<IData, IArgs>(mutation);
    const APIAlert = useAPIAlert();

    const submit = useCallback(
        async (values: IFormValues) => {
            const data: IArgs = { ...values };
            const result = await executeMutation(data);
            const success = APIAlert(result as any, "public.login.login-failed");
            if (success) {
                setSent(true);
            }
        },
        [executeMutation, setSent],
    );

    return (
        <Form<IFormValues> onSubmit={submit} validate={validate} initialValues={defaultValues}>
            {({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit}>
                    <Input name="email" label={t("public.reset-password.form-reset-email-label")} />
                    <button type="submit" disabled={submitting} className="button login">
                        {t("public.reset-password.form-reset-submit")}
                    </button>
                </form>
            )}
        </Form>
    );
}

export default ResetForm;
