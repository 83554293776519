import { type JSX, useState } from "react";
import styled from "styled-components";
import Closed from "./Closed";
import Finished from "./Finished";
import Footer from "./Footer";
import Header from "./Header";
import Info from "./Info";
import QuestionsForm from "./QuestionsForm";
import Sender from "./Sender";
import type { IData } from "./index";

interface IProps {
    data: IData;
}

const Wrapper = styled.div`
    display: grid;
    grid-template-rows: auto 1fr auto;
    max-width: 1000px;
    padding: 0 15px;
    margin: 0 auto;
    min-height: 100vh;
`;

const Main = styled.main`
    
`;

function CollectFeedback({ data }: IProps): JSX.Element {
    const [finished, setFinished] = useState(false);

    let mainContent = <Closed />;
    let footerVisible = false;

    if (finished) {
        mainContent = <Finished />;
    } else if (data.open) {
        footerVisible = true;
        mainContent = (
            <>
                <Sender firstName={data.firstName} lastName={data.lastName} profilePicSrc={data.profilePicSrc} />
                {data.description && <Info description={data.description} />}
                <QuestionsForm
                    id={data.id}
                    anonymous={data.anonymous}
                    questions={data.questions}
                    setFinished={setFinished}
                />
            </>
        );
    }

    return (
        <Wrapper>
            <Header heading={data.heading} />
            <Main>{mainContent}</Main>
            {footerVisible && <Footer />}
        </Wrapper>
    );
}

export default CollectFeedback;
