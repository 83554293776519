import styled from "styled-components";
import * as colors from "../colors";

export const LinkStyleButton = styled.button.attrs({
    type: "button",
})`
    margin: 0;
    padding: 0;
    display: inline;
    color: ${colors.primaryColor};
    background-color: transparent;
    cursor: pointer;
    font-size: inherit;
    
    &:hover, &:focus, &:active {
        color: ${colors.primaryDarker};
        background-color: transparent;
    }
`;
