import { parseDate, validDate } from "../../../../shared/dateFns";
import * as answer from "../../answerData";
import * as bar from "../../barData";
import * as lines from "../../linesData";
import type { ILearningPathData } from "../../queries";
import * as spread from "../../spreadData";
import type { IState } from "../Report";
import type { V1ChartDatum as ChartDatum, IV1ParsedData as IParsedData } from "./v1";

type ILearningPath = ILearningPathData["learningPath"];

function replacer(_key: string, value: any): any {
    if (value instanceof Map) {
        return {
            dataType: "Map",
            value: Array.from(value.entries()),
        };
    }
    return value;
}

function reviver(_key: string, value: any): any {
    if (typeof value === "object" && value !== null) {
        if (value.dataType === "Map") {
            return new Map(value.value);
        }
    }
    if (typeof value === "string") {
        if (value.length > 10 && value[10] === "T") {
            const date = parseDate(value);
            if (validDate(date)) {
                return date;
            }
        }
    }
    return value;
}

export function stringifyData(state: IState, path: ILearningPath, version: number): string {
    const charts: ChartDatum[] = [];
    for (const chartState of state.charts) {
        switch (chartState.chart) {
            case "lines": {
                const processedData = lines.preprocessData(path);
                const formattedData = lines.formatData(chartState.state, processedData, path);
                charts.push({
                    chart: "lines",
                    heading: chartState.heading,
                    data: {
                        chartData: formattedData.chartData,
                        fixed: path.fixed,
                        lines: chartState.state.lines,
                        labels: formattedData.labels,
                    },
                });
                continue;
            }
            case "bar": {
                const processedData = bar.preprocessData(path);
                const formattedData = bar.formatData(chartState.state, processedData);
                charts.push({
                    chart: "bar",
                    heading: chartState.heading,
                    data: {
                        chartData: formattedData.chartData,
                        fixed: path.fixed,
                        questions: formattedData.filteredQuestions,
                        dates: formattedData.filteredDates,
                        summaryStat: chartState.state.summaryStat,
                    },
                });
                continue;
            }
            case "spread": {
                const processedData = spread.preprocessData(path);
                const formattedData = spread.formatData(chartState.state, processedData);
                charts.push({
                    chart: "spread",
                    heading: chartState.heading,
                    data: {
                        chartData: formattedData.chartData,
                        questions: formattedData.filteredQuestions,
                        areaHeight: chartState.state.areaHeight,
                    },
                });
                continue;
            }
            case "answer": {
                const processedData = answer.preprocessData(path);
                const formattedData = answer.formatData(chartState.state, processedData);
                charts.push({
                    chart: "answer",
                    heading: chartState.heading,
                    data: {
                        chartData: formattedData.chartData,
                    },
                });
                continue;
            }
            default:
                continue;
        }
    }

    return JSON.stringify(
        {
            charts,
            version,
            title: state.title,
        },
        replacer,
    );
}

export function parseData(data: string): IParsedData {
    const parsedData = JSON.parse(data, (key, value) => reviver(key, value));
    return parsedData as IParsedData;
}
