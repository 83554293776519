import { animated, useTransition } from "@react-spring/web";
import { createContext, useCallback, useContext, useEffect, useState, type JSX } from "react";
import type { ReactNode } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import type { OperationResult } from "urql";
import * as colors from "../colors";
import t from "../translations";

let nextId = 0;

type IAlertType = "success" | "info" | "alert";
export type IAddFunc = (message: string, type: IAlertType) => void;

interface IAlert {
    id: number;
    message: string;
    type: IAlertType;
}

const AlertContext = createContext<IAddFunc>(null);
AlertContext.displayName = "AlertContext";

interface IAlertProps {
    alert: IAlert;
    remove: (id: number) => void;
}

interface IAlertBox {
    $type: IAlertType;
}

function colfun({ $type }: IAlertBox): string {
    if ($type === "alert") {
        return colors.alert;
    }
    if ($type === "success") {
        return colors.success;
    }
    return colors.primaryColor;
}

const AlertBox = styled.div<IAlertBox>`
    color: white;
    background-color: ${colfun};
    max-width: 1400px;
    margin: 0 auto 10px auto;
    font-size: .8rem;
    padding: .875rem 1.5rem .875rem .875rem;
    position: relative;
    border-radius: 0.3rem;
    box-shadow: 5px 4px 4px rgba(0, 0, 0, 0.4);
`;

const Close = styled.span`
    right: .25rem;
    font-size: 1.375rem;
    line-height: .9;
    margin-top: -.6875rem;
    padding: 0 6px 4px;
    position: absolute;
    top: 50%;
    cursor: pointer;
`;

function Alert({ alert, remove }: IAlertProps): JSX.Element {
    useEffect(() => {
        const timer = setTimeout(() => remove(alert.id), 30000);
        return () => {
            clearTimeout(timer);
        };
    }, [alert, remove]);
    return (
        <AlertBox $type={alert.type} className="alert-box">
            {alert.message}
            <Close onClick={() => remove(alert.id)} className="alert-close">
                ×
            </Close>
        </AlertBox>
    );
}

const Wrapper = styled.div`
    position: fixed;
    top: 10px;
    z-index: 10000;
    width: 100%;
`;

interface AlertProviderProps {
    children: ReactNode;
}
export function AlertProvider({ children }: AlertProviderProps): JSX.Element {
    const [alerts, setAlerts] = useState<IAlert[]>([]);

    const addAlert = useCallback((message: string, type: IAlertType) => {
        setAlerts((alerts) => [...alerts, { id: nextId++, message, type }]);
    }, []);

    const removeAlert = useCallback((id: number) => {
        setAlerts((alerts) => alerts.filter((a) => a.id !== id));
    }, []);

    const transition = useTransition(alerts, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        keys: (alert) => alert.id,
    });

    const portal = createPortal(
        <Wrapper className="alert-wrapper">
            {transition((style, item) => (
                <animated.div style={style}>
                    <Alert alert={item} remove={removeAlert} />
                </animated.div>
            ))}
        </Wrapper>,
        document.body,
    );

    return (
        <AlertContext.Provider value={addAlert}>
            {portal}
            {children}
        </AlertContext.Provider>
    );
}

export function useAlerts(): IAddFunc {
    const func = useContext(AlertContext);
    if (func === null) {
        throw new Error("Missing AlertProvider");
    }
    return func;
}

export function useAPIAlert() {
    const addAlert = useAlerts();
    return useCallback((result: OperationResult<any>, msgID: string): boolean => {
        if (result.error) {
            addAlert(
                t(msgID, {
                    error: result.error.message,
                }),
                "alert",
            );
            return false;
        }
        for (const field in result.data) {
            if (result.data[field].error) {
                addAlert(
                    t(msgID, {
                        error: result.data[field].error,
                    }),
                    "alert",
                );
                return false;
            }
        }
        return true;
    }, []);
}
