import type { ComponentPropsWithoutRef, JSX } from "react";
import styled from "styled-components";
import { loadJSData } from "../shared/jsData";

const Img = styled.img`
    height: 60px;
    width: 270px;
    object-fit: scale-down;
`;

function Logo(props: ComponentPropsWithoutRef<typeof Img>): JSX.Element {
    const logoSrc = loadJSData().settings.logo_src;
    return <Img {...props} src={logoSrc} />;
}

export default Logo;
