import { range } from "lodash-es";
import { memo } from "react";
import type { JSX } from "react";
import styled from "styled-components";
import * as colors from "../../shared/colors";

interface IProps {
    page: number;
    maxPage: number;
    setPage: (page: number) => void;
}

const Wrapper = styled.div`
    display: flex;
    margin: 0 -5px;
`;

interface INumProps {
    $current: boolean;
}

const Num = styled.div<INumProps>`
    border-radius: 0.3rem;
    border-width: 1px;
    border-style: solid;
    border-color: ${({ $current }) => ($current ? "none" : colors.primaryColor)};
    color: ${({ $current }) => ($current ? "white" : colors.primaryColor)};
    background-color: ${({ $current }) => ($current ? colors.primaryColor : "none")};
    cursor: pointer;
    width: 1.8rem;
    height: 1.8rem;
    margin: 0.3rem 0 0.3rem 0.3rem;
    padding: 0.2rem;
    display: grid;
    place-items: center;
    font-size: 0.9rem;
`;

function Pagination({ page, maxPage, setPage }: IProps): JSX.Element | null {
    const firstPage = Math.max(1, page - 10);
    const lastPage = Math.min(maxPage, page + 10);
    const pageNumbers = range(firstPage, lastPage + 1);

    if (maxPage === 1) {
        return null;
    }

    return (
        <Wrapper>
            {pageNumbers.map((num) => (
                <Num key={num} onClick={() => setPage(num)} $current={num === page}>
                    {num}
                </Num>
            ))}
        </Wrapper>
    );
}

export default memo(Pagination);
