import styled from "styled-components";
import { loadJSData } from "../../shared/jsData";
import type { JSX } from "react";

const Container = styled.div`
    text-align: right;
    margin: 10px 0;
`;

const Img = styled.img`
    max-width: 240px;
    max-height: 60px;
    object-fit: scale-down;
    object-position: right center;
`;

function Header(): JSX.Element {
    return (
        <Container>
            <Img src={loadJSData().player_logo_src} />
        </Container>
    );
}

export default Header;
