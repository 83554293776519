import { useField } from "react-final-form";
import PulseIcon from "../../shared/components/icons/taskIcons/PulseIcon";
import { ErrorLabel, QuestionBox } from "./stylings";
import type { JSX } from "react";

interface IProps {
    name: string;
    question: string;
}

function Question({ name, question }: IProps): JSX.Element {
    const {
        input,
        meta: { touched, error },
    } = useField(name);
    return (
        <QuestionBox>
            <PulseIcon />
            <h2>{question}</h2>
            <textarea {...input} />
            {touched && error && <ErrorLabel>{error}</ErrorLabel>}
        </QuestionBox>
    );
}

export default Question;
