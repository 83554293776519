import { formatYMD, parseDate } from "../../shared/dateFns";
import t from "../../shared/translations";
import type { IData } from "./queries";
import { InfoBox } from "./stylings";
import type { JSX } from "react";

interface IProps {
    learningPath: IData["impactTrackerReport"]["learningPath"];
    createdDatetime: string;
}

function Info({ learningPath, createdDatetime }: IProps): JSX.Element {
    const pathname = learningPath.pathname;
    const date = formatYMD(parseDate(createdDatetime));
    return (
        <InfoBox>
            <div>{t("public.impact-tracker-report.info-pathname", { pathname })}</div>
            <div>{t("public.impact-tracker-report.info-report-date", { date })}</div>
        </InfoBox>
    );
}

export default Info;
