import { type JSX, useCallback, useState } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { useQuery } from "urql";
import * as colors from "../../shared/colors";
import Loader from "../../shared/components/Loader";
import ShowError from "../../shared/components/ShowError";
import { loadJSData } from "../../shared/jsData";
import t from "../../shared/translations";
import type { Language, TaskIcon, TaskTheme } from "../../shared/types";
import type { IBuilderParams } from "./BuilderRoutes";
import ExamplePath from "./ExamplePath";
import Footer from "./Footer";

interface IArgs {
    locale: Language;
}

const query = `
    query($locale:String!) {
        builderPathList(locale:$locale) {
            id
            name
            publicId
            locale
            description
            showThemes
            showMiles
            showLength
            showPrice
            builderTaskOnPaths {
                id
                builderTask {
                    id
                    icon
                    shortTitle
                    shortDescription
                    miles
                    length
                    learningCoachFeedback
                    price
                    isGroupTask
                    isMedia
                    isPeopleEvent
                    isLecture
                    themes {
                        id
                        theme
                        value
                    }
                }
            }
        }
    }
`;

export interface IExampleTask {
    id: string;
    icon: TaskIcon;
    shortTitle: string;
    shortDescription: string | null;
    miles: number;
    length: number;
    learningCoachFeedback: boolean;
    price: number;
    isGroupTask: boolean;
    isMedia: boolean;
    isPeopleEvent: boolean;
    isLecture: boolean;
    themes: {
        id: string;
        theme: TaskTheme;
        value: number;
    }[];
}

interface IBuilderTaskOnPath {
    id: string;
    builderTask: IExampleTask;
}

export interface IExamplePath {
    id: string;
    name: string;
    publicId: string;
    locale: Language;
    description: string;
    showThemes: boolean;
    showMiles: boolean;
    showLength: boolean;
    showPrice: boolean;
    builderTaskOnPaths: IBuilderTaskOnPath[];
}

interface IData {
    builderPathList: IExamplePath[];
}

const Wrapper = styled.div`
    min-height: 100vh;
    display: grid;
    grid-template-rows: auto 1fr auto;
    margin: 0 auto;
    padding: 0 15px;
    justify-items: center;
`;

const Header = styled.header`
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;
    margin: 1rem auto;
    max-width: 1024px;
    align-items: center;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    h1 {
        margin: 0;
    }
    @media (min-width: 768px) {
        grid-template-columns: 1fr auto 1fr;
        gap: 30px;
        h1 {
            margin: 0;
        }
    }
`;

const HeaderLogo = styled.img`
    max-height: 4rem;
    max-width: 390px;
    object-fit: scale-down;
`;

const Main = styled.main`
    flex-grow: 1;
    max-width: 1024px;
    width: 100%;
`;

const FromScratch = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    margin-bottom: 2rem;
    border: 1px solid black;
    border-radius: 1rem;
    overflow: hidden;
    align-items: center;
    padding: 0.5rem;

    h3 {
        margin: 0;
    }
`;

const Or = styled.p`
    font-size: 1.7rem;
    margin: 2.2rem 0 2rem 0;
    text-align: center;
`;

const H2 = styled.h2`
    text-align: left;
`;

const BigImg = styled.img`
    display: block;
    margin: 1rem 0 3rem 0;
    width: 100%;
    max-width: 800px;
`;

const ButtonLink = styled(Link)`
    background-color: ${colors.primaryColor};
    padding: 0.5rem 1rem;
    color: ${colors.white};

    &:hover,
    &:focus,
    &:active,
    &:visited {
        color: ${colors.white};
    }
`;

const P = styled.p`
    font-size: 1.2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
`;

function Start(): JSX.Element {
    const { lang } = useParams<IBuilderParams>();
    const [open, setOpen] = useState("");

    const [result] = useQuery<IData, IArgs>({
        query,
        variables: {
            locale: lang as Language,
        },
    });

    const toggleOpen = useCallback((id: string) => {
        setOpen((openId) => (id === openId ? "" : id));
    }, []);

    let main = null;
    if (result.fetching) {
        main = <Loader />;
    } else if (result.error) {
        main = <ShowError error={t("public.error.error")} />;
    } else {
        const paths = result.data.builderPathList;
        const exampleInfo = t("public.builder-start.info-example-paths");
        const [w1, w2, w3, ...rest] = exampleInfo.split(" ");
        const infoStart = [w1, w2, w3].join(" ");
        const infoRest = rest.join(" ");
        main = (
            <>
                <div>
                    <P>{t("public.builder-start.intro")}</P>
                    <H2>{t("public.builder-start.heading-task-library")}</H2>
                    <BigImg src="/static/images/task-library.png" />
                </div>
                <div>
                    <P>
                        <strong>{infoStart}</strong> {infoRest}
                    </P>
                    <H2>{t("public.builder-start.heading-example-paths")}</H2>
                    {paths.map((path) => (
                        <ExamplePath key={path.id} path={path} toggleOpen={toggleOpen} isOpen={path.id === open} />
                    ))}
                </div>
                <Or>{t("public.builder-start.text-or")}</Or>
                <H2>{t("public.builder-start.heading-own-path")}</H2>
                <FromScratch>
                    <h3>{t("public.builder-start.heading-own-path-name")}</h3>
                    <ButtonLink to={`/path-builder/${lang}/build/`}>
                        {t("public.builder-start.link-start-from-scratch")}
                    </ButtonLink>
                </FromScratch>
            </>
        );
    }
    const logoSrc = loadJSData().settings.logo_src;
    return (
        <Wrapper>
            <Header>
                <div>
                    <HeaderLogo src={logoSrc} />
                </div>
                <h1>{t("public.builder-start.heading")}</h1>
                <div />
            </Header>
            <Main>{main}</Main>
            <Footer />
        </Wrapper>
    );
}

export default Start;
