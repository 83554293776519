import { useCallback, useState } from "react";
import type { FormEvent, JSX } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useQuery } from "urql";
import Loader from "../../shared/components/Loader";
import ShowError from "../../shared/components/ShowError";
import { loadJSData } from "../../shared/jsData";
import t from "../../shared/translations";
import type { Language, TaskIcon, TaskTheme } from "../../shared/types";
import Modal from "../Modal";
import type { IState } from "../state";
import type { IBuilderParams } from "./BuilderRoutes";
import Filter from "./Filter";
import ListHeading from "./ListHeading";
import Pagination from "./Pagination";
import SourceTask from "./SourceTask";
import TaskModal from "./TaskModal";

export type ISortBy = "shortTitle" | "miles" | "theme";
export type ISortDir = "increasing" | "decreasing";
export type ITaskType = "group-task" | "media" | "people-event" | "lecture" | "all";

interface IArgs {
    locale: Language;
    page: number;
    sortBy: ISortBy;
    sortDir: ISortDir;
    themes: TaskTheme[];
    taskType: ITaskType;
}

const query = `
    query($locale:String!,$page:Int!,$sortBy:String!,$sortDir:String!,$themes:[String!]!,$taskType:String!) {
        builderTaskPage(locale:$locale,page:$page,sortBy:$sortBy,sortDir:$sortDir,themes:$themes,taskType:$taskType) {
            countTotal
            builderTasks {
                id
                icon
                miles
                shortTitle
                price
                length
                locale
                description
                shortDescription
                learningCoachFeedback
                isGroupTask
                isMedia
                isPeopleEvent
                isLecture
                themes {
                    id
                    theme
                    value
                }
            }
        }
    }
`;

export interface ITaskTheme {
    id: string;
    theme: TaskTheme;
    value: number;
}

export interface IBuilderTask {
    id: string;
    icon: TaskIcon;
    miles: number;
    shortTitle: string;
    price: number;
    length: number;
    locale: Language;
    description: string | null;
    learningCoachFeedback: boolean;
    shortDescription: string | null;
    isGroupTask: boolean;
    isMedia: boolean;
    isPeopleEvent: boolean;
    isLecture: boolean;
    themes: ITaskTheme[];
}

interface IData {
    builderTaskPage: {
        countTotal: number;
        builderTasks: IBuilderTask[];
    };
}

const H3 = styled.h2`
    margin-top: 2rem;
`;

function Tasks(): JSX.Element {
    const { lang } = useParams<IBuilderParams>();
    const [page, setPage] = useState(1);
    const [[sortBy, sortDir], setSort] = useState<[ISortBy, ISortDir]>(["shortTitle", "increasing"]);
    const [themes, setThemes] = useState<TaskTheme[]>(loadJSData().task_data.task_themes);
    const [taskType, setType] = useState<ITaskType>("all");
    const modalTask = useSelector((state: IState) => state.builder.modalTask);
    const [result] = useQuery<IData, IArgs>({
        query,
        variables: {
            locale: lang as Language,
            page,
            sortBy,
            themes,
            sortDir,
            taskType,
        },
    });

    const onThemeChange = useCallback((event: FormEvent<HTMLInputElement>) => {
        const { checked, name } = event.currentTarget;
        setThemes((themes) => {
            if (checked) {
                return [...themes, name] as TaskTheme[];
            }
            return themes.filter((thm) => thm !== name);
        });
    }, []);

    const onTypeChange = useCallback((event: FormEvent<HTMLInputElement>) => {
        setType(event.currentTarget.value as ITaskType);
    }, []);

    const toggleAllThemes = useCallback((on: boolean) => {
        if (on) {
            setThemes(loadJSData().task_data.task_themes);
        } else {
            setThemes([]);
        }
    }, []);

    let main = null;
    if (result.fetching) {
        main = <Loader />;
    } else if (result.error) {
        main = <ShowError error={t("public.error.error")} />;
    } else {
        const { builderTasks, countTotal } = result.data.builderTaskPage;
        const pageCount = Math.ceil(countTotal / 50);
        main = (
            <>
                <Pagination setPage={setPage} page={page} maxPage={pageCount} />
                <ListHeading showDetails={true} />
                <div>
                    {builderTasks.map((task) => (
                        <SourceTask task={task} key={task.id} />
                    ))}
                </div>
                <Pagination setPage={setPage} page={page} maxPage={pageCount} />
                <Modal modal="Builder/TaskDetails">
                    <TaskModal task={modalTask} onPath={false} />
                </Modal>
            </>
        );
    }
    return (
        <>
            <H3>{t("public.builder.heading-task-library")}</H3>
            <Filter
                themes={themes}
                taskType={taskType}
                onThemeChange={onThemeChange}
                onTypeChange={onTypeChange}
                setSort={setSort}
                toggleAllThemes={toggleAllThemes}
            />
            {main}
        </>
    );
}

export default Tasks;
