import { Route, Routes } from "react-router-dom";
import Login from "./Login";
import Start from "./Start";
import ImpactTrackerReport from "./impactTrackerReport/ImpactTrackerReport";
import ActivityReport from "./learningPathActivityReport/ActivityReport";
import OutsideFeedback from "./outsideFeedback/OutsideFeedback";
import LoadPulse from "./pulse/LoadPulse";
import ResetPassword from "./reset/ResetPassword";
import ResetToken from "./reset/ResetToken";
import type { JSX } from "react";

export type IResetTokenParams = "token";
export type IOutsideFeedbackParams = "publicId";
export type IActivityReportParams = "publicId";

function Throw(): JSX.Element {
    throw new Error("Manually thrown TypeScript error");
}

function PublicRoutes(): JSX.Element {
    return (
        <Routes>
            <Route path="/throw-typescript-error" element={<Throw />} />
            <Route path="/login" element={<Login />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/reset-token/:token" element={<ResetToken />} />
            <Route path="/outside-feedback/:publicId" element={<OutsideFeedback />} />
            <Route path="/learning-path-activity-report/:publicId" element={<ActivityReport />} />
            <Route path="/impact-tracker-report/:publicId" element={<ImpactTrackerReport />} />
            <Route path="/pulse-survey/:publicId/*" element={<LoadPulse />} />
            <Route path="/index" element={<Start />} />
            <Route path="/" element={<Start />} />
        </Routes>
    );
}

export default PublicRoutes;
