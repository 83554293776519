import type { JSX } from "react";
import { loadJSData } from "../../shared/jsData";

interface IProps {
    tasks: {
        shortTitle: string;
        length: number;
        learningCoachFeedback: boolean;
        price: number;
    }[];
}

function PriceModal({ tasks }: IProps): JSX.Element {
    const { builder_base_price, builder_feedback_price, builder_price_range } = loadJSData().settings;
    const days = tasks.reduce((acc, task) => acc + task.length, 0);
    const basePrice = days * builder_base_price;
    const feedbackTasks = tasks.filter((task) => task.learningCoachFeedback);
    const feedbackPrice = feedbackTasks.length * builder_feedback_price;
    const extraPriceTasks = tasks.filter((task) => task.price > 0);
    const extraPrice = extraPriceTasks.reduce((acc, task) => acc + task.price, 0);
    const finalPrice = days * builder_base_price + feedbackTasks.length * builder_feedback_price + extraPrice;
    const lowPrice = finalPrice - finalPrice * builder_price_range;
    const highPrice = finalPrice + finalPrice * builder_price_range;
    return (
        <>
            <div>
                <h4>Price per day</h4>
                <p>
                    Total: {days} days * {builder_base_price} = {basePrice}
                </p>
            </div>
            <div>
                <h4>Price for learning coach feedback</h4>
                <ul>
                    {feedbackTasks.map((task, index) => (
                        <li key={index}>
                            {task.shortTitle}: {builder_feedback_price}
                        </li>
                    ))}
                </ul>
                <p>
                    Total: {feedbackTasks.length} tasks * {builder_feedback_price} = {feedbackPrice}
                </p>
            </div>
            <div>
                <h4>Extra price</h4>
                <ul>
                    {extraPriceTasks.map((task, index) => (
                        <li key={index}>
                            {task.shortTitle}: {task.price}
                        </li>
                    ))}
                </ul>
                <p>Total: {extraPrice}</p>
            </div>
            <div>
                <h4>Final price</h4>
                <p>
                    Total price: {finalPrice}
                    <br />
                    Price range: {lowPrice.toFixed(0)} - {highPrice.toFixed(0)}
                </p>
            </div>
        </>
    );
}

export default PriceModal;
