import { useMemo } from "react";
import type { JSX } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useClient } from "urql";
import { useAlerts } from "../../shared/components/Alerts";
import Loader from "../../shared/components/Loader";
import t from "../../shared/translations";
import type { TaskIcon, TaskTheme } from "../../shared/types";
import { useHasRole } from "../CurrentPerson";
import { type IDispatch, type IState, initBuilderPath, initEmptyBuilderPath } from "../state";
import type { IBuilderParams } from "./BuilderRoutes";
import BuiltPath from "./BuiltPath";
import Footer from "./Footer";
import Header from "./Header";
import Tasks from "./Tasks";

interface IArgs {
    id: string;
}

const query = `
    query($id:String!) {
        builderPath(id:$id) {
            id
            name
            showThemes
            showMiles
            showLength
            showPrice
            builderTaskOnPaths {
                id
                builderTask {
                    id
                    icon
                    miles
                    shortTitle
                    price
                    length
                    description
                    shortDescription
                    learningCoachFeedback
                    isGroupTask
                    isMedia
                    isPeopleEvent
                    isLecture
                    themes {
                        id
                        theme
                        value
                    }
                }
            }
        }
    }
`;

interface IData {
    builderPath: {
        id: string;
        name: string;
        showThemes: boolean;
        showMiles: boolean;
        showLength: boolean;
        showPrice: boolean;
        builderTaskOnPaths: {
            id: string;
            builderTask: {
                id: string;
                icon: TaskIcon;
                miles: number;
                shortTitle: string;
                price: number;
                length: number;
                description: string;
                shortDescription: string;
                learningCoachFeedback: boolean;
                isGroupTask: boolean;
                isMedia: boolean;
                isPeopleEvent: boolean;
                isLecture: boolean;
                themes: {
                    id: string;
                    theme: TaskTheme;
                    value: number;
                }[];
            };
        }[];
    };
}

const Wrapper = styled.div`
    min-height: 100vh;
    display: grid;
    grid-template-rows: auto 1fr;
    margin: 0 auto;
    max-width: 1024px;
    padding: 0 15px;
`;

const Main = styled.main`
    max-width: 1024px;
`;

function Builder(): JSX.Element {
    const { id } = useParams<IBuilderParams>();
    const isAdmin = useHasRole(["admin"]);
    const dispatch = useDispatch<IDispatch>();
    const client = useClient();
    const addAlert = useAlerts();
    const loading = useSelector((state: IState) => state.builder.loading);
    useMemo(() => {
        void (async () => {
            if (id) {
                const result = await client.query<IData, IArgs>(query, { id }).toPromise();
                if (result.error || !result.data?.builderPath) {
                    addAlert(t("public.error.error"), "alert");
                } else {
                    const { name, builderTaskOnPaths, showThemes, showMiles, showLength, showPrice } =
                        result.data.builderPath;
                    dispatch(
                        initBuilderPath({
                            name,
                            showThemes,
                            showMiles,
                            showLength,
                            showPrice,
                            taskOnPaths: builderTaskOnPaths,
                        }),
                    );
                }
            } else {
                dispatch(initEmptyBuilderPath(t("public.builder.default-pathname")));
            }
        })();
    }, [id]);

    let main = null;
    if (loading) {
        main = <Loader />;
    } else {
        main = (
            <>
                <Header />
                <BuiltPath />
                {isAdmin && <Tasks />}
            </>
        );
    }

    return (
        <Wrapper>
            <Main>{main}</Main>
            <Footer />
        </Wrapper>
    );
}

export default Builder;
