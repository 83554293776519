// Query invitations

export interface IArgs {
    id: string;
}

export const query = `
    query($id:String!) {
        accountInvitation(id:$id) {
            id
            publicId
            email
            teamScan
            learningPath
            closed
        }
        reusableAccountInvitation(id:$id) {
            id
            publicId
            learningPath
            closed
        }
    }
`;

export interface IReusableAccountInvitation {
    id: string;
    publicId: string;
    learningPath: string;
    closed: boolean;
}

export interface IAccountInvitation extends IReusableAccountInvitation {
    email: string;
    teamScan: boolean;
}

export interface IData {
    accountInvitation: IAccountInvitation | null;
    reusableAccountInvitation: IReusableAccountInvitation | null;
}

// Create personal account mutation

export interface ICreatePersonalArgs {
    data: {
        publicId: string;
        email: string;
        password: string;
        confirmPassword: string;
        agreedToTerms: boolean;
        firstName: string;
        lastName: string;
        avatar: string;
        gender: string;
        title: string | null;
        tel: string | null;
        birthYear: number | null;
        emailNotifications: boolean;
        profilePicture: string | null;
    };
}

export const createPersonalMutation = `
    mutation($data:CreateAccountData!) {
        accountInvitationCreateAccount(data:$data) {
            error
            redirectUrl
        }
    }
`;

export interface ICreatePersonalData {
    accountInvitationCreateAccount: {
        error: string | null;
        redirectUrl: string | null;
    };
}

// Create reusable account mutation

export interface ICreateReusableArgs {
    data: {
        publicId: string;
        email: string;
        password: string;
        confirmPassword: string;
        agreedToTerms: boolean;
        firstName: string;
        lastName: string;
        avatar: string;
        gender: string;
        title: string | null;
        tel: string | null;
        birthYear: number | null;
        emailNotifications: boolean;
        profilePicture: string | null;
    };
}

export const createReusableMutation = `
    mutation($data:CreateAccountData!) {
        reusableAccountInvitationCreateAccount(data:$data) {
            error
            redirectUrl
        }
    }
`;

export interface ICreateReusableData {
    reusableAccountInvitationCreateAccount: {
        error: string | null;
        redirectUrl: string | null;
    };
}

// Asssociate personal account mutation

export interface IAssociatePersonalArgs {
    data: {
        publicId: string;
        email: string;
        password: string;
    };
}

export const associatePersonalMutation = `
    mutation($data:AssociateAccountData!) {
        accountInvitationAssociateAccount(data:$data) {
            error
            redirectUrl
        }
    }
`;

export interface IAssociatePersonalData {
    accountInvitationAssociateAccount: {
        error: string | null;
        redirectUrl: string | null;
    };
}

// Asssociate reusable account mutation

export interface IAssociateReusableArgs {
    data: {
        publicId: string;
        email: string;
        password: string;
    };
}

export const associateReusableMutation = `
    mutation($data:AssociateAccountData!) {
        reusableAccountInvitationAssociateAccount(data:$data) {
            error
            redirectUrl
        }
    }
`;

export interface IAssociateReusableData {
    reusableAccountInvitationAssociateAccount: {
        error: string | null;
        redirectUrl: string | null;
    };
}
