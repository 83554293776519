import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

export type IModal =
    | "Builder/SavePath"
    | "Builder/Examples"
    | "Builder/TaskDetails"
    | "Builder/PathTaskDetails"
    | "Builder/PathPrice"
    | null;

interface IModalState {
    open: IModal;
}

const initialState: IModalState = {
    open: null,
};

const modalSlice = createSlice({
    name: "modal",
    initialState,
    reducers: {
        openModal: (state, action: PayloadAction<IModal>) => {
            state.open = action.payload;
        },
        closeModal: (state) => {
            state.open = null;
        },
    },
});

export const { openModal, closeModal } = modalSlice.actions;

export default modalSlice.reducer;
