import { configureStore } from "@reduxjs/toolkit";
import builderReducer from "./builderSlice";
import modalReducer from "./modalSlice";

export const store = configureStore({
    reducer: {
        builder: builderReducer,
        modal: modalReducer,
    },
});

export type IState = ReturnType<typeof store.getState>;
export type IDispatch = typeof store.dispatch;

export * from "./builderSlice";
export * from "./modalSlice";
