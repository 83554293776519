import { Route, Routes, useParams } from "react-router-dom";
import { useQuery } from "urql";
import Loader from "../../shared/components/Loader";
import ShowError from "../../shared/components/ShowError";
import t from "../../shared/translations";
import Footer from "../Footer";
import Finished from "./Finished";
import LogoContainer from "./LogoContainer";
import Pulse from "./Pulse";
import { type IArgs, type IData, query } from "./queries";
import { Header, Main, Wrapper } from "./stylings";
import type { JSX } from "react";

function LoadPulse(): JSX.Element {
    const { publicId } = useParams();
    const [result] = useQuery<IData, IArgs>({
        query,
        variables: {
            id: publicId,
        },
    });

    let main = null;
    if (result.fetching) {
        main = <Loader />;
    } else if (result.error) {
        main = <ShowError error={t("public.error.error")} />;
    } else {
        const pulse = result.data.pulse;
        if (!pulse) {
            main = <ShowError error={t("public.pulse.error-missing")} />;
        } else {
            main = (
                <Routes>
                    <Route path="/finished" element={<Finished />} />
                    <Route path="/" element={<Pulse pulse={pulse} />} />
                </Routes>
            );
        }
    }

    return (
        <Wrapper>
            <Header>
                <LogoContainer />
            </Header>
            <Main>{main}</Main>
            <Footer />
        </Wrapper>
    );
}

export default LoadPulse;
