import { type JSX, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import TaskIcon from "../../shared/components/icons/TaskIcon";
import { type IDispatch, type IState, setModalTask } from "../state";

interface IProps {
    current: number;
}

interface ITask {
    $current: boolean;
}

const Task = styled.div<ITask>`
    cursor: pointer;
    width: 90px;
    height: 90px;
    flex-shrink: 0;
    svg {
        margin-top: ${({ $current }) => ($current ? 0 : 20)}px;
        width: ${({ $current }) => ($current ? 90 : 70)}px;
        height: ${({ $current }) => ($current ? 90 : 70)}px;
    }
`;

const Wrapper = styled.div`
    display: grid;
    grid-auto-flow: column;
    justify-content: center;
    align-items: start;
    overflow-x: auto;
    overflow-y: hidden;
    height: 170px;
    background: url("/static/images/background.png") repeat-x left bottom;
`;

function SimplePathView({ current }: IProps): JSX.Element {
    const dispatch = useDispatch<IDispatch>();
    const tasks = useSelector((state: IState) => state.builder.tasks);
    const ref = useRef<HTMLDivElement>(null);

    // biome-ignore lint/correctness/useExhaustiveDependencies: Should scroll when current changes
    useEffect(() => {
        ref.current?.scrollIntoView({
            behavior: "auto",
            inline: "center",
        });
    }, [current]);
    return (
        <Wrapper>
            {tasks.map((task, index) => (
                <Task
                    key={index}
                    $current={current === index}
                    ref={current === index ? ref : undefined}
                    onClick={() => dispatch(setModalTask({ task, index }))}
                >
                    <TaskIcon icon={task.icon} />
                </Task>
            ))}
        </Wrapper>
    );
}

export default SimplePathView;
