import type { ValidationErrors } from "final-form";
import { useCallback, type JSX } from "react";
import { Form } from "react-final-form";
import styled from "styled-components";
import { useMutation } from "urql";
import { useAPIAlert } from "../shared/components/Alerts";
import { loadJSData } from "../shared/jsData";
import t from "../shared/translations";
import Checkbox from "./Checkbox";
import Input from "./Input";
import PasswordInput from "./PasswordInput";

interface IArgs {
    data: {
        email: string;
        password: string;
        rememberMe: boolean;
    };
}

const mutation = `
    mutation($data:LoginData!) {
        login(data:$data) {
            error
            redirectUrl
        }
    }
`;

interface IData {
    login: {
        error?: string;
        redirectUrl?: string;
    };
}

interface IFormValues {
    email: string;
    password: string;
    rememberMe: boolean;
}

const Main = styled.main`
    max-width: 1000px;
    padding: 0 15px;
    margin: 0 auto;
`;

const defaultValues: IFormValues = {
    email: "",
    password: "",
    rememberMe: true,
};

function validate(values: IFormValues): ValidationErrors {
    const errors: ValidationErrors = {};
    if (!values.email || !values.email.includes("@")) {
        errors.email = t("public.login.form-login-error-e-mail");
    }
    if (!values.password) {
        errors.password = t("public.login.form-login-error-password");
    }
    return errors;
}

function Login(): JSX.Element {
    const [_, executeMutation] = useMutation<IData, IArgs>(mutation);
    const APIAlert = useAPIAlert();

    const submit = useCallback(
        async (values: IFormValues) => {
            const data: IArgs = {
                data: values,
            };
            const result = await executeMutation(data);
            const success = APIAlert(result as any, "public.login.login-failed");
            if (success) {
                const searchParams = new URLSearchParams(window.location.search);
                window.location.href = searchParams.get("next") ?? result.data.login.redirectUrl;
            }
        },
        [executeMutation],
    );

    return (
        <Main id="login-page">
            <h1>
                <img src={loadJSData().settings.logo_src} alt="Logo" className="logo" />
            </h1>
            <Form<IFormValues> onSubmit={submit} validate={validate} initialValues={defaultValues}>
                {({ handleSubmit, submitting }) => (
                    <form onSubmit={handleSubmit}>
                        <Input name="email" label={t("public.login.form-login-label-e-mail")} />
                        <PasswordInput
                            name="password"
                            type="password"
                            label={t("public.login.form-login-label-password")}
                            showPassword
                            showLabel={t("public.login.button-show")}
                            hideLabel={t("public.login.button-hide")}
                        />
                        <Checkbox name="rememberMe" label={t("public.login.form-login-label-remember-me")} />
                        <button type="submit" disabled={submitting} className="button login">
                            {t("public.login.form-login-submit")}
                        </button>
                    </form>
                )}
            </Form>
            <p>
                <a href="/reset-password">{t("public.login.link-forgot-password")}</a>
                <br />
            </p>
        </Main>
    );
}

export default Login;
