import { useMemo } from "react";
import { sortBy, uniqBy } from "lodash-es";
import type { IChartDatum } from "../../shared/components/learningPathActivityCharts/MilesBarChart";
import { addDays, isSameOrBeforeDay, parseDate } from "../../shared/dateFns";
import fmtGroupName from "../../shared/fmtGroupName";
import isPublished from "../../shared/isPublished";
import type { IState, YAxis } from "./MilesBarConfig";
import type { ILearningPath, IPathMembership, IPerson, ISubmittedTask, ITask } from "./queries";

interface IFormattedSubmittedTask {
    id: string;
    miles: number;
    finishDatetime: string;
    personId: string;
    teamId: string | null;
    pathGroupId: string | null;
    orgId: string;
}

function fmtSubmittedTask(pm: IPathMembership, submittedTask: ISubmittedTask): IFormattedSubmittedTask {
    const pathGroupId = pm.pathGroup?.id;
    return {
        id: submittedTask.id,
        miles: submittedTask.miles,
        finishDatetime: submittedTask.finishDatetime,
        personId: pm.person.id,
        teamId: pm.person?.team?.id,
        pathGroupId,
        orgId: pm.person.organisation.id,
    };
}

export function fmtPersonName(person: IPerson, includeNames: boolean): string {
    if (includeNames) {
        return `${person.firstName} ${person.lastName}`;
    }
    return `$id-${person.id}`;
}

export function incrementCount(id: string, countMap: Map<string, number>) {
    const currCount = countMap.get(id) ?? 0;
    const newCount = currCount + 1;
    countMap.set(id, newCount);
}

interface IProcessedData {
    people: { id: string; name: string }[];
    teams: { id: string; name: string }[];
    teamCount: Map<string, number>;
    groups: { id: string; name: string }[];
    groupCount: Map<string, number>;
    orgs: { id: string; name: string }[];
    orgCount: Map<string, number>;
    tasks: ITask[];
    submittedTasks: IFormattedSubmittedTask[];
}

export function preprocessData(path: ILearningPath, includeNames = true): IProcessedData {
    // Do the preprocessing of the data that does not depend on the config.

    // Collect all people, teams, groups and orgs into Arrays.
    // Also collect member counts for each team, group and org into Maps so they can be used to calculate averages
    // later.
    let people: { id: string; name: string }[] = [];
    const pmMap = new Map<string, IPathMembership>();
    let teams: { id: string; name: string }[] = [];
    const teamCount = new Map<string, number>();
    let groups: { id: string; name: string }[] = [];
    const groupCount = new Map<string, number>();
    let orgs: { id: string; name: string }[] = [];
    const orgCount = new Map<string, number>();
    for (const pm of path.pathMemberships) {
        const person = pm.person;
        people.push({
            id: person.id,
            name: fmtPersonName(person, includeNames),
        });
        pmMap.set(person.id, pm);
        const team = person.team;
        if (team) {
            teams.push({
                id: team.id,
                name: team.name,
            });
            incrementCount(team.id, teamCount);
        }
        const pathGroup = pm.pathGroup;
        if (pathGroup) {
            groups.push({
                id: pathGroup.id,
                name: fmtGroupName(pathGroup),
            });
            incrementCount(pathGroup.id, groupCount);
        }
        const org = person.organisation;
        orgs.push({
            id: org.id,
            name: org.name,
        });
        incrementCount(org.id, orgCount);
    }

    // Collect and format info for each Task and each SubmittedTask into an Array.
    const tasks: ITask[] = [];
    const submittedTasks: IFormattedSubmittedTask[] = [];
    for (const item of path.learningPathItems) {
        for (const task of item.tasks) {
            if (isPublished(task)) {
                tasks.push(task);
            }
            for (const submittedTask of task.submittedTasks) {
                if (submittedTask.status !== "accepted") {
                    continue;
                }
                if (!submittedTask.miles) {
                    continue;
                }
                for (const pers of submittedTask.people) {
                    const pm = pmMap.get(pers.id);
                    if (!pm) {
                        // Skip submitters not in the learning path.
                        continue;
                    }
                    submittedTasks.push(fmtSubmittedTask(pm, submittedTask));
                }
            }
        }
    }
    // Also include submitted custom tasks in calculations
    for (const item of path.customTaskLearningPathItems) {
        for (const submittedTask of item.submittedTasks) {
            if (submittedTask.status !== "accepted") {
                continue;
            }
            if (!submittedTask.miles) {
                continue;
            }
            for (const pers of submittedTask.people) {
                const pm = pmMap.get(pers.id);
                if (!pm) {
                    // Skip submitters not in the learning path.
                    continue;
                }
                submittedTasks.push(fmtSubmittedTask(pm, submittedTask));
            }
        }
    }

    // Remove all duplicate teams, groups, and orgs
    teams = uniqBy(teams, (team) => team.id);
    groups = uniqBy(groups, (group) => group.id);
    orgs = uniqBy(orgs, (org) => org.id);

    // Sort the lists alphabetically by name for display in UI
    people = sortBy(people, (person) => pmMap.get(person.id).person.lastName);
    teams = sortBy(teams, (team) => team.name);
    groups = sortBy(groups, (group) => group.name);
    orgs = sortBy(orgs, (org) => org.name);

    return { people, teams, teamCount, groups, groupCount, orgs, orgCount, tasks, submittedTasks };
}

function fmtName(yAxis: YAxis): string {
    const grouping =
        yAxis === "person" ? "participant" : yAxis === "team" ? "team" : yAxis === "group" ? "group" : "organisation";
    return `miles-bar-chart-${grouping}.png`;
}

function calcValue(id: string, milesMap: Map<string, number>, countMap?: Map<string, number>): number {
    return (milesMap.get(id) ?? 0) / (countMap?.get(id) || 1);
}

function taskDeadline(task: ITask): Date | null {
    if (task.deadlineDay == null) {
        return null;
    }
    return addDays(parseDate(task.fixedCallToAction), task.deadlineDay);
}

interface IFormattedData {
    chartData: IChartDatum[];
    checkpoint: number;
    goal: number;
    deadlineGoal: number;
    name: string;
}

export function formatData(state: IState, processedData: IProcessedData, path: ILearningPath): IFormattedData {
    // Format data based on config

    const { yAxis, bars, linesCheckpoint, linesGoal, linesDeadline, sorting } = state;
    const { submittedTasks, people, teams, teamCount, groups, groupCount, orgs, orgCount, tasks } = processedData;

    // Creat a milesMap that is a Map from id to the number of miles that person/team/group/org has earrned in the
    // learning map, and then populate it by looping over all the submitted tasks.
    const milesMap = new Map<string, number>();
    for (const task of submittedTasks) {
        let id = null;
        if (yAxis === "person") {
            id = task.personId;
        } else if (yAxis === "team") {
            id = task.teamId;
        } else if (yAxis === "group") {
            id = task.pathGroupId;
        } else if (yAxis === "org") {
            id = task.orgId;
        }
        if (id !== null) {
            const miles = (milesMap.get(id) ?? 0) + task.miles;
            milesMap.set(id, miles);
        }
    }

    // Create an array of IChartDatum for passing to Recharts. Only include miles for those ids that have been
    // enabled in config.
    let chartData: IChartDatum[] = [];
    if (yAxis === "person") {
        for (const person of people) {
            if (!bars[person.id]) {
                continue;
            }
            chartData.push({
                label: person.name,
                value: calcValue(person.id, milesMap),
            });
        }
    } else if (yAxis === "team") {
        for (const team of teams) {
            if (!bars[team.id]) {
                continue;
            }
            chartData.push({
                label: team.name,
                value: calcValue(team.id, milesMap, teamCount),
            });
        }
    } else if (yAxis === "group") {
        for (const group of groups) {
            if (!bars[group.id]) {
                continue;
            }
            chartData.push({
                label: group.name,
                value: calcValue(group.id, milesMap, groupCount),
            });
        }
    } else if (yAxis === "org") {
        for (const org of orgs) {
            if (!bars[org.id]) {
                continue;
            }
            chartData.push({
                label: org.name,
                value: calcValue(org.id, milesMap, orgCount),
            });
        }
    }

    // Calculate checkpoint, goal and deadlineGoal if they have been enabled in config. Only calculate deadlineGoal
    // for fixed paths.
    const checkpoint = linesCheckpoint ? path.checkpoint : 0;
    const goal = linesGoal ? path.goal : 0;
    let deadlineGoal = 0;
    if (path.fixed && linesDeadline) {
        const today = new Date();
        let miles = 0;
        for (const task of tasks) {
            const deadline = taskDeadline(task);
            if (deadline != null && isSameOrBeforeDay(deadline, today)) {
                miles += task.miles;
            }
        }
        deadlineGoal = miles;
    }

    // Sort the bars by miles if nesseccary
    if (sorting === "miles") {
        chartData = sortBy(chartData, (datum) => -datum.value);
    }

    // Format name for saved file based on config.
    const name = fmtName(yAxis);

    return { chartData, checkpoint, goal, deadlineGoal, name };
}

interface IData {
    people: { id: string; name: string }[];
    teams: { id: string; name: string }[];
    groups: { id: string; name: string }[];
    orgs: { id: string; name: string }[];
    chartData: IChartDatum[];
    checkpoint: number;
    goal: number;
    deadlineGoal: number;
    name: string;
}

export function useData(path: ILearningPath, state: IState): IData {
    // Split the computing of the chart data into two separate useMemos: One for preprocessing the Learning Path data
    // that does not depend on chart config, and the other the processing that is based on chart config.

    const processedData = useMemo(() => {
        return preprocessData(path);
    }, [path]);

    const formattedData = useMemo(() => {
        return formatData(state, processedData, path);
    }, [state, processedData, path]);

    return {
        people: processedData.people,
        teams: processedData.teams,
        groups: processedData.groups,
        orgs: processedData.orgs,
        ...formattedData,
    };
}
