import { useCallback, useState, type JSX } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "urql";
import Loader from "../../shared/components/Loader";
import ShowError from "../../shared/components/ShowError";
import t from "../../shared/translations";
import type { Language, TaskIcon, TaskTheme } from "../../shared/types";
import type { IBuilderParams } from "./BuilderRoutes";
import ExamplePath from "./ExamplePath";

interface IArgs {
    locale: Language;
}

const query = `
    query($locale:String!) {
        builderPathList(locale:$locale) {
            id
            name
            publicId
            locale
            description
            showThemes
            showMiles
            showLength
            showPrice
            builderTaskOnPaths {
                id
                builderTask {
                    id
                    icon
                    shortTitle
                    shortDescription
                    miles
                    length
                    learningCoachFeedback
                    price
                    isGroupTask
                    isMedia
                    isPeopleEvent
                    isLecture
                    themes {
                        id
                        theme
                        value
                    }
                }
            }
        }
    }
`;

export interface IExampleTask {
    id: string;
    icon: TaskIcon;
    shortTitle: string;
    shortDescription: string | null;
    miles: number;
    length: number;
    learningCoachFeedback: boolean;
    price: number;
    isGroupTask: boolean;
    isMedia: boolean;
    isPeopleEvent: boolean;
    isLecture: boolean;
    themes: {
        id: string;
        theme: TaskTheme;
        value: number;
    }[];
}

interface IBuilderTaskOnPath {
    id: string;
    builderTask: IExampleTask;
}

export interface IExamplePath {
    id: string;
    name: string;
    publicId: string;
    locale: Language;
    description: string;
    showThemes: boolean;
    showMiles: boolean;
    showLength: boolean;
    showPrice: boolean;
    builderTaskOnPaths: IBuilderTaskOnPath[];
}

interface IData {
    builderPathList: IExamplePath[];
}

function ExamplesModal(): JSX.Element {
    const { lang } = useParams<IBuilderParams>();
    const [open, setOpen] = useState<string>("");
    const [result] = useQuery<IData, IArgs>({
        query,
        variables: {
            locale: lang as Language,
        },
    });

    const toggleOpen = useCallback((id: string) => {
        setOpen((openId) => {
            if (id === openId) {
                return "";
            }
            return id;
        });
    }, []);

    if (result.fetching) {
        return <Loader />;
    }
    if (result.error) {
        return <ShowError error={t("public.error.error")} />;
    }
    const paths = result.data.builderPathList;
    return (
        <>
            <h2>{t("public.builder.heading-example-paths")}</h2>
            {paths.map((path) => (
                <ExamplePath key={path.id} path={path} toggleOpen={toggleOpen} isOpen={path.id === open} />
            ))}
        </>
    );
}

export default ExamplesModal;
