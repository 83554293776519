export interface IArgs {
    id: string;
    pwd: string;
}

export const query = `
query($id:String!,$pwd:String!) {
    impactTrackerReport(id:$id,pwd:$pwd) {
        id
        version
        data
        createdDatetime
        logoSrc
        learningPath {
            id
            name
            pathname
        }
    }
}
`;

export interface IData {
    impactTrackerReport: {
        id: string;
        version: number;
        data: string;
        createdDatetime: string;
        logoSrc: string;
        learningPath: {
            id: string;
            name: string;
            pathname: string;
        };
    } | null;
}
