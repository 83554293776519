import styled from "styled-components";

interface ILegend {
    $left?: boolean;
}

export const Legend = styled.div<ILegend>`
    display: flex;
    justify-content: ${({ $left }) => ($left ? "flex-start" : "center")};
    align-items: center;
    gap: 0 2rem;
    flex-wrap: wrap;
`;

interface ILegendLabel {
    $gap?: string;
}

export const LegendLabel = styled.div<ILegendLabel>`
    display: flex;
    align-items: center;
    gap: ${({ $gap }) => $gap ?? "1rem"};;
`;

interface ILegendLine {
    $color: string;
    $borderStyle?: string;
}

export const LegendLine = styled.span<ILegendLine>`
    display: inline-block;
    border-top: 4px ${({ $borderStyle }) => $borderStyle ?? "solid"} ${({ $color }) => $color};
    width: 3rem;
`;

interface ILegendMark {
    $color: string;
}

export const LegendMark = styled.span<ILegendMark>`
    display: inline-block;
    color: ${({ $color }) => $color};
    width: 25px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    font-size: 25px;
`;
