import { useRef, type JSX } from "react";
import { type DragSourceMonitor, type DropTargetMonitor, useDrag, useDrop } from "react-dnd";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { useHasRole } from "../CurrentPerson";
import { type IDispatch, type IState, removeTaskFromPath } from "../state";
import type { ITargetTaskItem } from "./TargetTask";
import TaskBox from "./TaskBox";
import type { IBuilderTask } from "./Tasks";

interface IProps {
    task: IBuilderTask;
}

export interface ISourceTaskItem {
    type: "SOURCE_TASK";
    task: IBuilderTask;
}

interface ITaskCounts {
    [index: string]: number;
}

const tasksOnPathSelector = createSelector(
    (state: IState) => state.builder.tasks,
    (tasks): ITaskCounts => {
        return tasks.reduce((acc: ITaskCounts, task) => {
            acc[task.id] = (acc[task.id] ?? 0) + 1;
            return acc;
        }, {} as ITaskCounts);
    },
);

function SourceTask({ task }: IProps): JSX.Element {
    const isAdmin = useHasRole(["admin"]);
    const dispatch = useDispatch<IDispatch>();
    const count = useSelector(tasksOnPathSelector)[task.id] ?? 0;
    const ref = useRef<HTMLDivElement>(null);
    const [_, dropRef] = useDrop({
        accept: ["SOURCE_TASK", "TARGET_TASK"],
        drop: (item: ITargetTaskItem) => {
            dispatch(removeTaskFromPath(item.index));
        },
        collect: (monitor: DropTargetMonitor) => ({
            isOver: monitor.isOver(),
        }),
    });
    const [__, dragRef, previewRef] = useDrag({
        type: "SOURCE_TASK",
        item: () => ({ type: "SOURCE_TASK", task }),
        collect: (monitor: DragSourceMonitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    dragRef(dropRef(ref));
    return (
        <div ref={isAdmin ? ref : undefined} style={{ cursor: isAdmin ? "grab" : "default" }}>
            <TaskBox task={task} onPath={false} previewRef={previewRef} showDetails={true} count={count} />
        </div>
    );
}

export default SourceTask;
