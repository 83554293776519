import type { ValidationErrors } from "final-form";
import { useCallback, type JSX } from "react";
import { Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { createSelector } from "reselect";
import { useMutation } from "urql";
import { useAPIAlert, useAlerts } from "../../shared/components/Alerts";
import parseEmails from "../../shared/parseEmails";
import t from "../../shared/translations";
import Input from "../Input";
import Textarea from "../Textarea";
import { type IDispatch, type IState, closeModal } from "../state";
import type { IBuilderParams } from "./BuilderRoutes";

interface IArgs {
    data: {
        name: string;
        email: string;
        organisation: string;
        locale: string;
        tasks: string[];
        note: string | null;
        subject: string | null;
        showThemes: boolean;
        showMiles: boolean;
        showLength: boolean;
        showPrice: boolean;
    };
}

const mutation = `
    mutation($data:BuilderPathSaveData!) {
        builderPathSave(data:$data) {
            error
            builderPath {
                id
            }
        }
    }
`;

interface IData {
    builderPathSave: {
        error: string | null;
        builderPath: {
            id: string;
        } | null;
    };
}

interface IFormValues {
    email: string;
    organisation: string;
    note: string;
    subject: string;
}

const pathSelector = createSelector(
    (state: IState) => state.builder.name,
    (state: IState) => state.builder.showThemes,
    (state: IState) => state.builder.showMiles,
    (state: IState) => state.builder.showLength,
    (state: IState) => state.builder.showPrice,
    (state: IState) => state.builder.tasks,
    (name, showThemes, showMiles, showLength, showPrice, tasks) => ({
        name,
        showThemes,
        showMiles,
        showLength,
        showPrice,
        tasks: tasks.map((task) => task.id),
    }),
);

function validate(values: IFormValues): ValidationErrors {
    const errors: ValidationErrors = {};
    if (values.email) {
        if (!values.email.includes("@") || parseEmails(values.email).length === 0) {
            errors.email = t("public.builder.form-save-error-email-invalid");
        }
    }
    return errors;
}

function SaveModal(): JSX.Element {
    const dispatch = useDispatch<IDispatch>();
    const pathData = useSelector(pathSelector);
    const [_, executeMutation] = useMutation<IData, IArgs>(mutation);
    const APIAlert = useAPIAlert();
    const alert = useAlerts();
    const { lang } = useParams<IBuilderParams>();

    const submit = useCallback(
        async (values: IFormValues) => {
            const data: IArgs = {
                data: {
                    ...values,
                    ...pathData,
                    locale: lang,
                },
            };
            const result = await executeMutation(data);
            const success = APIAlert(result, "public.alert.builder-path-failed-to-save");
            if (success) {
                dispatch(closeModal());
                alert(t("public.alert.builder-path-save-succesfull"), "success");
            }
        },
        [executeMutation, pathData, lang],
    );

    return (
        <>
            <h2>{t("public.builder.heading-save-path")}</h2>
            <p>{t("public.builder.info-save-path")}</p>
            <Form<IFormValues> onSubmit={submit} validate={validate}>
                {({ handleSubmit, submitting, values }) => (
                    <form onSubmit={handleSubmit}>
                        <Input name="email" label={t("public.builder.form-save-label-email")} />
                        {!!values.email && values.email.includes("@") && (
                            <Input name="subject" label={t("public.builder.form-save-label-subject")} />
                        )}
                        <Input name="organisation" label={t("public.builder.form-save-label-organisation")} />
                        <Textarea name="note" label={t("public.builder.form-save-label-note")} />
                        <button type="submit" disabled={submitting}>
                            {t("public.builder.form-save-button-submit")}
                        </button>
                    </form>
                )}
            </Form>
        </>
    );
}

export default SaveModal;
