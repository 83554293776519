import { type ReactElement, type ReactNode, createContext, useContext } from "react";
import { useQuery } from "urql";
import Loader from "../shared/components/Loader";
import ShowError from "../shared/components/ShowError";
import t from "../shared/translations";
import type { Role } from "../shared/types";

const query = `
    query {
        currentPerson {
            id
            firstName
            lastName
            user {
                email
                role {
                    name
                }
            }
        }
    }
`;

interface ICurrentPerson {
    id: string;
    firstName: string;
    lastName: string;
    user: {
        email: string;
        role: {
            name: Role;
        };
    };
}

interface IData {
    currentPerson: ICurrentPerson;
}

const CurrentPersonContext = createContext<ICurrentPerson>(null);
CurrentPersonContext.displayName = "CurrentPersonContext";

interface IProps {
    children: ReactNode;
}

export const CurrentPersonProvider = ({ children }: IProps): ReactElement => {
    const [result] = useQuery<IData>({ query });

    if (result.fetching) {
        return <Loader />;
    }
    if (result.error) {
        return <ShowError error={t("public.error.error")} />;
    }
    return <CurrentPersonContext.Provider value={result.data.currentPerson}>{children}</CurrentPersonContext.Provider>;
};

export function useCurrentPerson(): ICurrentPerson {
    return useContext(CurrentPersonContext);
}

export function useHasRole(roles: Role[]): boolean {
    const person = useCurrentPerson();
    if (person == null) {
        return false;
    }
    return roles.includes(person.user.role.name);
}
