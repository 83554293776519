import { range } from "lodash-es";
import { type JSX, memo } from "react";
import type { CSSProperties, MutableRefObject } from "react";
import { Line, LineChart, ReferenceLine, ResponsiveContainer, XAxis, YAxis } from "recharts";
import * as colors from "../../colors";
import { format, formatVeryShort, getWeek } from "../../dateFns";
import t from "../../translations";
import { Legend, LegendLabel, LegendLine } from "./stylings";

export type IChartDatum =
    | {
          label: number;
          deadline: number;
      }
    | Record<string, number>;

type YAxisType = "total" | "average" | "team" | "group" | "org";
type XAxisType = "day" | "week" | "month";
type RelativeType = "calendar" | "path";

export interface IProps {
    chartData: IChartDatum[];
    state: {
        yAxis: YAxisType;
        xAxis: XAxisType;
        relative: RelativeType;
        linesDeadline: boolean;
        maxTicks: number;
    };
    chartRef?: MutableRefObject<HTMLDivElement>;
    teams: {
        id: string;
        name: string;
    }[];
    groups: {
        id: string;
        name: string;
    }[];
    orgs: {
        id: string;
        name: string;
    }[];
    checkpoint: number;
    goal: number;
    style?: CSSProperties;
}

export function fmtTick(indexOrDateNumber: number, xAxis: XAxisType, relative: RelativeType): string {
    if (relative === "path") {
        const index = indexOrDateNumber;
        if (xAxis === "week") {
            const week_number = Math.floor(index / 7) + 1;
            return t("shared.learning-path-activity-charts.tick-week", { week_number });
        }
        if (xAxis === "month") {
            const month_number = Math.floor(index / 30) + 1;
            return t("shared.learning-path-activity-charts.tick-month", { month_number });
        }
        const day_number = index + 1;
        return t("shared.learning-path-activity-charts.tick-day", { day_number });
    }
    const date = new Date(indexOrDateNumber);
    if (xAxis === "week") {
        const week_number = getWeek(date);
        return t("shared.learning-path-activity-charts.tick-week", { week_number });
    }
    if (xAxis === "month") {
        return format(date, "LLL");
    }
    return formatVeryShort(date);
}

export function domainNticks(chartData: IChartDatum[], maxTicks: number): [[number, number], number[]] {
    if (chartData.length === 0) {
        return [[0, 0], []];
    }
    const domain: [number, number] = [chartData[0].label, chartData[chartData.length - 1].label];
    let ticks: number[] = domain;
    if (chartData.length <= maxTicks) {
        // If there are fewer data points than maxTicks, just generate a tick for each data point
        ticks = range(chartData.length);
    } else if (maxTicks === 1) {
        // If maxTicks is 1 then just one tick for zero
        ticks = [0];
    } else {
        // Otherwise add one tick for zero, one for the last data point, and then evenly spaced ticks in between.
        ticks = [
            0,
            ...range(1, maxTicks - 1).map((i) => Math.trunc((i * chartData.length) / (maxTicks - 1))),
            chartData.length - 1,
        ];
    }
    ticks = ticks.map((idx) => chartData[idx].label);
    return [domain, ticks];
}

function MilesLineChart({
    chartData,
    chartRef,
    state,
    teams,
    groups,
    orgs,
    checkpoint,
    goal,
    style,
}: IProps): JSX.Element {
    const { yAxis, xAxis, relative, linesDeadline, maxTicks } = state;
    const showLegend = (yAxis !== "total" && yAxis !== "average") || checkpoint > 0 || goal > 0 || linesDeadline;
    const type = xAxis === "day" ? "stepAfter" : "linear";
    const [domain, ticks] = domainNticks(chartData, maxTicks);
    return (
        <div style={style} ref={chartRef}>
            <div>
                <ResponsiveContainer width="100%" aspect={2}>
                    <LineChart data={chartData}>
                        <YAxis type="number" />
                        <XAxis
                            dataKey="label"
                            type="number"
                            domain={domain}
                            ticks={ticks}
                            tickFormatter={(val) => fmtTick(val, xAxis, relative)}
                            minTickGap={2}
                        />
                        {yAxis === "total" && (
                            <Line
                                isAnimationActive={false}
                                dataKey="total"
                                stroke={colors.primaryColor}
                                type={type}
                                strokeWidth={2}
                                dot={false}
                            />
                        )}
                        {yAxis === "average" && (
                            <Line
                                isAnimationActive={false}
                                dataKey="average"
                                stroke={colors.primaryColor}
                                type={type}
                                strokeWidth={2}
                                dot={false}
                            />
                        )}
                        {yAxis === "team" &&
                            teams.map((team, idx) => (
                                <Line
                                    key={team.id}
                                    isAnimationActive={false}
                                    dataKey={team.id}
                                    stroke={colors.chart(idx)}
                                    type={type}
                                    strokeWidth={2}
                                    dot={false}
                                />
                            ))}
                        {yAxis === "group" &&
                            groups.map((group, idx) => (
                                <Line
                                    key={group.id}
                                    isAnimationActive={false}
                                    dataKey={group.id}
                                    stroke={colors.chart(idx)}
                                    type={type}
                                    strokeWidth={2}
                                    dot={false}
                                />
                            ))}
                        {yAxis === "org" &&
                            orgs.map((org, idx) => (
                                <Line
                                    key={org.id}
                                    isAnimationActive={false}
                                    dataKey={org.id}
                                    stroke={colors.chart(idx)}
                                    type={type}
                                    strokeWidth={2}
                                    dot={false}
                                />
                            ))}
                        {linesDeadline && (
                            <Line
                                isAnimationActive={false}
                                dataKey="deadline"
                                stroke={colors.chartLines.deadline}
                                strokeWidth={3}
                                strokeDasharray={"9 9"}
                                dot={false}
                                type={type}
                            />
                        )}
                        {!!checkpoint && (
                            <ReferenceLine
                                y={checkpoint}
                                stroke={colors.chartLines.checkpoint}
                                strokeWidth={3}
                                strokeDasharray={"9 9"}
                                alwaysShow
                            />
                        )}
                        {!!goal && (
                            <ReferenceLine
                                y={goal}
                                stroke={colors.chartLines.goal}
                                strokeWidth={3}
                                strokeDasharray={"9 9"}
                                alwaysShow
                            />
                        )}
                    </LineChart>
                </ResponsiveContainer>
            </div>
            {showLegend && (
                <Legend>
                    {yAxis === "team" &&
                        teams.map((team, idx) => (
                            <LegendLabel key={team.id}>
                                <LegendLine $color={colors.chart(idx)} />
                                {team.name}
                            </LegendLabel>
                        ))}
                    {yAxis === "group" &&
                        groups.map((group, idx) => (
                            <LegendLabel key={group.id}>
                                <LegendLine $color={colors.chart(idx)} />
                                {group.name}
                            </LegendLabel>
                        ))}
                    {yAxis === "org" &&
                        orgs.map((org, idx) => (
                            <LegendLabel key={org.id}>
                                <LegendLine $color={colors.chart(idx)} />
                                {org.name}
                            </LegendLabel>
                        ))}
                    {!!checkpoint && (
                        <LegendLabel>
                            <LegendLine $color={colors.chartLines.checkpoint} $borderStyle="dashed" />
                            {t("shared.learning-path-activity-charts.label-checkbpoint")}
                        </LegendLabel>
                    )}
                    {!!goal && (
                        <LegendLabel>
                            <LegendLine $color={colors.chartLines.goal} $borderStyle="dashed" />
                            {t("shared.learning-path-activity-charts.label-goal")}
                        </LegendLabel>
                    )}
                    {!!linesDeadline && (
                        <LegendLabel>
                            <LegendLine $color={colors.chartLines.deadline} $borderStyle="dashed" />
                            {t("shared.learning-path-activity-charts.label-deadline")}
                        </LegendLabel>
                    )}
                </Legend>
            )}
        </div>
    );
}

export default memo(MilesLineChart);
