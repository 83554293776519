import { useMemo } from "react";
import { sortBy, uniq } from "lodash-es";
import type { IChartDatum } from "../../shared/components/impactTrackerCharts/LinesChart";
import { diffDays, getTime, parseDate } from "../../shared/dateFns";
import type { IState } from "./LinesConfig";
import type { ILearningPathData } from "./queries";

type ILearningPath = ILearningPathData["learningPath"];
type IPathMembership = ILearningPath["pathMemberships"][0];
type ITask = ILearningPath["learningPathItems"][0]["tasks"][0];
type ISubmittedTask = ITask["submittedTasks"][0];
type ITaskThreadItem = ISubmittedTask["thread"][0];
type ILikertAnswer = ITaskThreadItem["taskAnswers"][0]["impactTrackerAnswerSet"]["impactLikertAnswers"][0];

export interface IAnswerDatum {
    id: string;
    taskId: string;
    statement: string;
    value: number;
    submitters: string[];
    finishDatetime: Date;
    callToActionDay: number;
    callToActionDate: Date | null;
}

interface IProcessedData {
    questions: string[];
    people: { id: string; name: string }[];
    answersMap: Map<string, IAnswerDatum[]>;
}

export function fmtAnswer(
    likertAnswer: ILikertAnswer,
    tti: ITaskThreadItem,
    submittedTask: ISubmittedTask,
    task: ITask,
    path: ILearningPath,
    pmMap: Map<string, IPathMembership>,
): IAnswerDatum {
    const pm = pmMap.get(tti.person.id);
    const pathStart = path.fixed ? parseDate(path.fixedStartDate) : parseDate(pm.floatingStartDate);
    const callToActionDate = path.fixed ? parseDate(task.fixedCallToAction) : null;
    return {
        id: likertAnswer.id,
        taskId: task.id,
        statement: likertAnswer.statement,
        value: likertAnswer.value,
        submitters: submittedTask.people.map((p) => p.id),
        finishDatetime: parseDate(submittedTask.finishDatetime),
        callToActionDay: path.fixed ? diffDays(callToActionDate, pathStart) : task.callToActionDay,
        callToActionDate,
    };
}

export function assemblePeople(path: ILearningPath): [{ id: string; name: string }[], Map<string, IPathMembership>] {
    // Collect all people into an Array.
    let people: { id: string; name: string }[] = [];
    const pmMap = new Map<string, IPathMembership>();
    for (const pm of path.pathMemberships) {
        const person = pm.person;
        people.push({
            id: person.id,
            name: `${person.firstName} ${person.lastName}`,
        });
        pmMap.set(person.id, pm);
    }
    people = sortBy(people, (person) => pmMap.get(person.id).person.lastName);
    return [people, pmMap];
}

interface ILabels {
    leftLabel: string;
    rightLabel: string;
}

export function assembleQuestionNAnswers(
    path: ILearningPath,
    pmMap: Map<string, IPathMembership>,
): [string[], Map<string, IAnswerDatum[]>, Map<string, ILabels>] {
    // Collect all questions into an Array.
    // Also create a map from question statement to all answers for that statement.
    let questions: string[] = [];
    const questionLabels = new Map<string, ILabels>();
    const answersMap = new Map<string, IAnswerDatum[]>();
    for (const item of path.learningPathItems) {
        for (const task of item.tasks) {
            for (const submittedTask of task.submittedTasks) {
                if (submittedTask.status !== "accepted") {
                    continue;
                }
                for (const tti of submittedTask.thread) {
                    if (tti.type !== "answer") {
                        continue;
                    }
                    for (const answer of tti.taskAnswers) {
                        if (answer.answerType !== "impact-tracker") {
                            continue;
                        }
                        for (const likertAnswer of answer.impactTrackerAnswerSet.impactLikertAnswers) {
                            const statement = likertAnswer.statement;
                            questions.push(statement);
                            questionLabels.set(statement, {
                                leftLabel: likertAnswer.leftLabel,
                                rightLabel: likertAnswer.rightLabel,
                            });
                            const ansArr = answersMap.get(statement) ?? [];
                            ansArr.push(fmtAnswer(likertAnswer, tti, submittedTask, task, path, pmMap));
                            answersMap.set(statement, ansArr);
                        }
                    }
                }
            }
        }
    }
    questions = uniq(questions);
    questions.sort();

    return [questions, answersMap, questionLabels];
}

export function preprocessData(path: ILearningPath): IProcessedData {
    const [people, pmMap] = assemblePeople(path);
    const [questions, answersMap] = assembleQuestionNAnswers(path, pmMap);

    return { people, questions, answersMap };
}

interface IFormattedData {
    chartData: IChartDatum[][][];
    name: string;
    labels: number[];
}

export function calcAvg(answers: { value: number }[]): number {
    const total = answers.reduce((sum, answer) => sum + answer.value, 0);
    return total / answers.length;
}

export function calcNetScore(answers: { value: number }[]): number {
    let promotersCount = 0;
    let detractorsCount = 0;

    for (const { value } of answers) {
        if (value >= 9) {
            promotersCount++;
        } else if (value < 7) {
            detractorsCount++;
        }
    }

    const totalRespondents = answers.length;
    const promotersPercentage = (promotersCount / totalRespondents) * 100;
    const detractorsPercentage = (detractorsCount / totalRespondents) * 100;
    return promotersPercentage - detractorsPercentage;
}

function fmtLabel(answers: IAnswerDatum[], path: ILearningPath): number {
    if (path.fixed) {
        return getTime(answers[0].callToActionDate);
    }
    return answers[0].callToActionDay;
}

export function collectAnswers(
    participants: Record<string, boolean>,
    statements: string[],
    answersMap: Map<string, IAnswerDatum[]>,
): Map<string, Map<string, IAnswerDatum[]>> {
    // Create a set of participants to include
    const peopleSet = new Set<string>();
    for (const [id, enabled] of Object.entries(participants)) {
        if (enabled) {
            peopleSet.add(id);
        }
    }

    // Filter answers to only include those for selected questions and participants, and assemble them into nested
    // maps based first on statement, and then taskId
    const collectedMap = new Map<string, Map<string, IAnswerDatum[]>>();
    for (const statement of statements) {
        let statementMap = collectedMap.get(statement);
        if (!statementMap) {
            statementMap = new Map<string, IAnswerDatum[]>();
            collectedMap.set(statement, statementMap);
        }
        for (const answer of answersMap.get(statement)) {
            if (!answer.submitters.some((id) => peopleSet.has(id))) {
                continue;
            }
            if (!statementMap.has(answer.taskId)) {
                statementMap.set(answer.taskId, []);
            }
            statementMap.get(answer.taskId).push(answer);
        }
    }

    return collectedMap;
}

export function formatData(state: IState, processedData: IProcessedData, path: ILearningPath): IFormattedData {
    const statements = state.lines.map((lc) => lc.statement);
    const collectedMap = collectAnswers(state.participants, statements, processedData.answersMap);

    // Figure out the tick labels based on answers
    let labels: number[] = [];
    for (const statementMap of collectedMap.values()) {
        for (const answers of statementMap.values()) {
            const label = fmtLabel(answers, path);
            labels.push(label);
        }
    }
    labels = uniq(labels);
    labels.sort((a, b) => a - b);

    // Setup the chart data
    const chartData: IChartDatum[][][] = new Array(state.lines.length);
    for (const [idx, lineConfig] of state.lines.entries()) {
        const statementMap = collectedMap.get(lineConfig.statement);
        if (lineConfig.lineType === "avg") {
            const innerData: IChartDatum[] = [];
            for (const answers of statementMap.values()) {
                const label = fmtLabel(answers, path);
                const value = calcAvg(answers);
                const datum: IChartDatum = { label, value };
                innerData.push(datum);
            }
            chartData[idx] = [innerData];
        } else if (lineConfig.lineType === "net") {
            const innerData: IChartDatum[] = [];
            for (const answers of statementMap.values()) {
                const label = fmtLabel(answers, path);
                const value = calcNetScore(answers);
                const datum: IChartDatum = { label, value };
                innerData.push(datum);
            }
            chartData[idx] = [innerData];
        } else {
            const submittersMap = new Map<string, IChartDatum[]>();
            for (const answers of statementMap.values()) {
                const label = fmtLabel(answers, path);
                for (const answer of answers) {
                    const value = answer.value;
                    const datum: IChartDatum = { label, value };
                    for (const submitter of answer.submitters) {
                        const ansArr = submittersMap.get(submitter) ?? [];
                        ansArr.push(datum);
                        submittersMap.set(submitter, ansArr);
                    }
                }
            }
            const innerData = Array.from(submittersMap.values());
            chartData[idx] = innerData;
        }
    }

    const name = "impact-tracker-lines-chart.png";

    return { name, chartData, labels };
}

interface IData {
    questions: string[];
    people: { id: string; name: string }[];
    chartData: IChartDatum[][][];
    name: string;
    labels: number[];
}

export function useData(path: ILearningPath, state: IState): IData {
    // Split the computing of the chart data into two separate useMemos: One for preprocessing the Learning Path data
    // that does not depend on chart config, and the other the processing that is based on chart config.

    const processedData = useMemo(() => {
        return preprocessData(path);
    }, [path]);

    const formattedData = useMemo(() => {
        return formatData(state, processedData, path);
    }, [state, processedData, path]);

    return {
        questions: processedData.questions,
        people: processedData.people,
        ...formattedData,
    };
}
