import { sortBy, uniqBy } from "lodash-es";
import { useMemo } from "react";
import type { IChartDatum } from "../../shared/components/learningPathActivityCharts/MilesLineChart";
import { addDays, diffDays, formatYMD, getTime, isBefore, parseDate, startOfDay } from "../../shared/dateFns";
import fmtGroupName from "../../shared/fmtGroupName";
import isPublished from "../../shared/isPublished";
import type { IState, XAxis, YAxis } from "./MilesLineConfig";
import { incrementCount } from "./milesBarData";
import type { ILearningPath, IPathMembership, ISubmittedTask, ITask } from "./queries";
import { dateRange, idRange, numberRange } from "./rangeGenerator";

interface IFormattedSubmittedTask {
    id: string;
    miles: number;
    finishDate: Date;
    personalStartDate: Date;
    personId: string;
    pathGroupId: string | null;
    teamId: string | null;
    orgId: string | null;
    diff: number;
}

function fmtSubmittedTask(
    pm: IPathMembership,
    submittedTask: ISubmittedTask,
    fixed: boolean,
    fixedStartDate: string | null,
): [number, IFormattedSubmittedTask] {
    const pathGroupId = pm.pathGroup?.id;
    let finishDate = startOfDay(parseDate(submittedTask.finishDatetime));
    const personalStartDate = fixed ? parseDate(fixedStartDate) : parseDate(pm.floatingStartDate);
    if (isBefore(finishDate, personalStartDate)) {
        // Move any finishDate that are before the start date of the learning path to be the start date of the
        // learning path so that miles aren't missed once the chart data is calculated.
        finishDate = personalStartDate;
    }
    const diff = diffDays(finishDate, personalStartDate);
    return [
        diff,
        {
            id: submittedTask.id,
            miles: submittedTask.miles,
            finishDate,
            personalStartDate,
            personId: pm.person.id,
            pathGroupId,
            teamId: pm.person?.team?.id,
            orgId: pm.person.organisation.id,
            diff,
        },
    ];
}

interface IProcessedData {
    people: { id: string; name: string }[];
    teams: { id: string; name: string }[];
    teamCount: Map<string, number>;
    groups: { id: string; name: string }[];
    groupCount: Map<string, number>;
    orgs: { id: string; name: string }[];
    orgCount: Map<string, number>;
    tasks: ITask[];
    submittedTasks: IFormattedSubmittedTask[];
    maxDay: number;
    fixedStartDate: Date | null;
}

export function preprocessData(path: ILearningPath): IProcessedData {
    // Do the preprocessing of the data that does not depend on the config.

    const fixed = path.fixed;

    // Collect all people, teams, groups and orgs into Arrays.
    // Also collect member counts for each team, group and org into Maps so they can be used to calculate averages
    // later.
    let people: { id: string; name: string }[] = [];
    const pmMap = new Map<string, IPathMembership>();
    let teams: { id: string; name: string }[] = [];
    const teamCount = new Map<string, number>();
    let groups: { id: string; name: string }[] = [];
    const groupCount = new Map<string, number>();
    let orgs: { id: string; name: string }[] = [];
    const orgCount = new Map<string, number>();

    for (const pm of path.pathMemberships) {
        const person = pm.person;
        people.push({
            id: person.id,
            name: `${person.firstName} ${person.lastName}`,
        });
        pmMap.set(person.id, pm);
        const team = person.team;
        if (team) {
            teams.push({
                id: team.id,
                name: team.name,
            });
            incrementCount(team.id, teamCount);
        }
        const pathGroup = pm.pathGroup;
        if (pathGroup) {
            groups.push({
                id: pathGroup.id,
                name: fmtGroupName(pathGroup),
            });
            incrementCount(pathGroup.id, groupCount);
        }
        const org = person.organisation;
        orgs.push(org);
        incrementCount(org.id, orgCount);
    }

    // Collect and format info for each Task and SubmittedTask into Arrays
    // Also track max day for a submitted task for creating indexMap later
    const Tasks: ITask[] = [];
    const submittedTasks: IFormattedSubmittedTask[] = [];
    let maxDay = 0;
    for (const item of path.learningPathItems) {
        for (const task of item.tasks) {
            if (isPublished(task)) {
                Tasks.push(task);
            }
            for (const submittedTask of task.submittedTasks) {
                if (submittedTask.status !== "accepted") {
                    continue;
                }
                if (!submittedTask.miles) {
                    continue;
                }
                for (const pers of submittedTask.people) {
                    const pm = pmMap.get(pers.id);
                    if (!pm) {
                        // Skip submitters not in the learning path.
                        continue;
                    }
                    const [diff, fmtTask] = fmtSubmittedTask(pm, submittedTask, fixed, path.fixedStartDate);
                    if (diff > maxDay) {
                        maxDay = diff;
                    }
                    submittedTasks.push(fmtTask);
                }
            }
        }
    }

    for (const item of path.customTaskLearningPathItems) {
        for (const submittedTask of item.submittedTasks) {
            if (submittedTask.status !== "accepted") {
                continue;
            }
            if (!submittedTask.miles) {
                continue;
            }
            for (const pers of submittedTask.people) {
                const pm = pmMap.get(pers.id);
                if (!pm) {
                    // Skip submitters not in the learning path.
                    continue;
                }
                const [diff, fmtTask] = fmtSubmittedTask(pm, submittedTask, fixed, path.fixedStartDate);
                if (diff > maxDay) {
                    maxDay = diff;
                }
                submittedTasks.push(fmtTask);
            }
        }
    }

    // Remove all duplicate teams, groups, and orgs
    teams = uniqBy(teams, (team) => team.id);
    groups = uniqBy(groups, (group) => group.id);
    orgs = uniqBy(orgs, (org) => org.id);

    // Sort the lists alphabetically by name for display in UI
    people = sortBy(people, (person) => pmMap.get(person.id).person.lastName);
    teams = sortBy(teams, (team) => team.name);
    groups = sortBy(groups, (group) => group.name);
    orgs = sortBy(orgs, (org) => org.name);

    const fixedStartDate = path.fixed ? parseDate(path.fixedStartDate) : null;

    return {
        people,
        teams,
        teamCount,
        groups,
        groupCount,
        orgs,
        orgCount,
        submittedTasks,
        tasks: Tasks,
        maxDay,
        fixedStartDate,
    };
}

interface IFormattedData {
    filteredTeams: { id: string; name: string }[];
    filteredGroups: { id: string; name: string }[];
    filteredOrgs: { id: string; name: string }[];
    name: string;
    checkpoint: number;
    goal: number;
    chartData: IChartDatum[];
}

function fmtName(xAxis: XAxis, yAxis: YAxis): string {
    const yLabel = yAxis === "org" ? "organisation" : yAxis;
    return `miles-line-chart-${xAxis}-${yLabel}.png`;
}

export function formatData(state: IState, processedData: IProcessedData, path: ILearningPath): IFormattedData {
    // Format data based on config

    const {
        yAxis,
        xAxis,
        relative,
        lines,
        startDate,
        endDate,
        startDay,
        endDay,
        linesCheckpoint,
        linesGoal,
        linesDeadline,
    } = state;
    const { teams, groups, orgs, maxDay, submittedTasks, teamCount, groupCount, orgCount, tasks, fixedStartDate } =
        processedData;

    const filteredTeams = teams.filter((team) => !!lines[team.id]);
    const filteredGroups = groups.filter((group) => !!lines[group.id]);
    const filteredOrgs = orgs.filter((org) => !!lines[org.id]);

    // Collect all the ids for the teams, groups or orgs that will be used as labels for the data series for charting
    // Use 'person' as label when counting total learning miles for all participants
    let ids = [];
    if (yAxis === "total") {
        ids = ["total"];
    } else if (yAxis === "average") {
        ids = ["average"];
    } else if (yAxis === "team") {
        ids = teams.map((team) => team.id);
    } else if (yAxis === "group") {
        ids = groups.map((group) => group.id);
    } else if (yAxis === "org") {
        ids = orgs.map((org) => org.id);
    }

    // Create a nested Map, where the key of the outer map is the day from program start the task was submitted
    // and the key of the inner map is the id of the team, group or org.
    const indexMap: Map<number, Map<string, number>> = new Map(
        numberRange(0, maxDay, 1, () => new Map(idRange(ids, () => 0))),
    );

    // Loop through all submitted tasks and fill in the number of miles earned in indexMap
    for (const task of submittedTasks) {
        let id = null;
        let includeId = false;
        if (yAxis === "total") {
            id = "total";
            includeId = lines[task.personId];
        } else if (yAxis === "average") {
            id = "average";
            includeId = lines[task.personId];
        } else if (yAxis === "team") {
            id = task.teamId;
            includeId = lines[id];
        } else if (yAxis === "group") {
            id = task.pathGroupId;
            includeId = lines[id];
        } else if (yAxis === "org") {
            id = task.orgId;
            includeId = lines[id];
        }
        if (includeId) {
            const diff = task.diff;
            const idMap = indexMap.get(diff);
            const miles = idMap.get(id) + task.miles;
            idMap.set(id, miles);
        }
    }

    // Construct a Map with key being labels for use in chart and value being the datum that will be pass to
    // Recharts. It is constructed to only include the labels that should be shown. Initialize all values to 0.
    let indexIncrement = 1;
    if (xAxis === "week") {
        indexIncrement = 7;
    } else if (xAxis === "month") {
        indexIncrement = 30;
    }
    const chartMap: Map<string | number, IChartDatum> =
        relative === "calendar"
            ? new Map(
                  dateRange(startDate, endDate, xAxis, (date) =>
                      Object.fromEntries([["label", getTime(date)], ["deadline", 0], ...idRange(ids, () => 0)]),
                  ),
              )
            : new Map(
                  numberRange(startDay + indexIncrement - 1, endDay + indexIncrement - 1, indexIncrement, (i) =>
                      Object.fromEntries([["label", i], ["deadline", 0], ...idRange(ids, () => 0)]),
                  ),
              );

    // Loop through all earned miles in the indexMap, accumulate them into the accuMap, and, if the index/date is
    // in the chartMap, set the accumulated miles in the chartMap. Also keep track of value for last date seen.
    const accuMap: Map<string, number> = new Map(idRange(ids, () => 0));
    let maxDateOrIndex: string | number = null;
    let maxValue: Record<string, number> = null;
    const linesSelected = Object.values(lines).reduce((acc, val) => +val + acc, 0);
    for (const [index, idMap] of indexMap) {
        const datum: Record<string, number> = {};
        for (const [id, miles] of idMap) {
            const accu = miles + accuMap.get(id);
            const includeId = yAxis === "total" || yAxis === "average" || lines[id];
            accuMap.set(id, accu);
            if (includeId) {
                let divisor = 1;
                if (yAxis === "average") {
                    divisor = linesSelected || 1;
                } else if (yAxis === "team") {
                    divisor = teamCount.get(id) || 1;
                } else if (yAxis === "group") {
                    divisor = groupCount.get(id) || 1;
                } else if (yAxis === "org") {
                    divisor = orgCount.get(id) || 1;
                }
                datum[id] = accu / divisor;
            }
        }

        // Use string formatted date as key in case of calendar relative, otherwise use index.
        const chartIndex = relative === "calendar" ? formatYMD(addDays(parseDate(path.fixedStartDate), index)) : index;

        const labeledDatum = chartMap.get(chartIndex);
        if (labeledDatum) {
            chartMap.set(chartIndex, { ...labeledDatum, ...datum });
        }
        if (maxDateOrIndex === null || maxDateOrIndex < chartIndex) {
            maxDateOrIndex = chartIndex;
            maxValue = datum;
        }
    }

    // Any chart entries past the last task should have their miles value set to that of the last task.
    if (maxDateOrIndex !== null) {
        for (const [key, labeledDatum] of chartMap) {
            if (key > maxDateOrIndex) {
                chartMap.set(key, { ...labeledDatum, ...maxValue });
            }
        }
    }

    // Calculate checkpoint, goal if they have been enabled in config.
    let checkpoint = 0;
    let goal = 0;
    const multiplier = yAxis === "total" ? linesSelected : 1;
    if (linesCheckpoint) {
        checkpoint = path.checkpoint * multiplier;
    }
    if (linesGoal) {
        goal = path.goal * multiplier;
    }

    function fmtChartIndex(task: ITask): string | number {
        // Chart index is a YMD formatted string, or the number of days since start of learning path.
        if (path.fixed) {
            if (relative === "calendar") {
                return formatYMD(addDays(parseDate(task.fixedCallToAction), task.deadlineDay));
            }
            if (relative === "path") {
                return diffDays(parseDate(task.fixedCallToAction), fixedStartDate) + task.deadlineDay;
            }
        }
        return task.callToActionDay + task.deadlineDay;
    }

    // Calculate the deadline goal for each datum in the chartMap if it has been enabled in config.
    if (linesDeadline) {
        for (const task of tasks) {
            if (task.deadlineDay == null) {
                continue;
            }
            const chartIndex = fmtChartIndex(task);
            for (const [index, datum] of chartMap) {
                if (chartIndex <= index) {
                    datum.deadline += task.miles * multiplier;
                }
            }
        }
    }

    // Create the name for the saved screenshot.
    const name = fmtName(xAxis, yAxis);

    // Convert chartMap to an array for Recharts
    const chartData: IChartDatum[] = Array.from(chartMap.values());

    return {
        filteredTeams,
        filteredGroups,
        filteredOrgs,
        name,
        checkpoint,
        goal,
        chartData,
    };
}

interface IData {
    people: { id: string; name: string }[];
    teams: { id: string; name: string }[];
    filteredTeams: { id: string; name: string }[];
    groups: { id: string; name: string }[];
    filteredGroups: { id: string; name: string }[];
    name: string;
    orgs: { id: string; name: string }[];
    filteredOrgs: { id: string; name: string }[];
    chartData: IChartDatum[];
    checkpoint: number;
    goal: number;
    fixedStartDate: Date | null;
}

export function useData(path: ILearningPath, state: IState): IData {
    // Split the computing of the chart data into two separate useMemos: One for preprocessing the Learning Path data
    // that does not depend on chart config, and the other the processing that is based on chart config.

    const processedData = useMemo(() => {
        return preprocessData(path);
    }, [path]);

    const formattedData = useMemo(() => {
        return formatData(state, processedData, path);
    }, [state, processedData, path]);

    return {
        people: processedData.people,
        teams: processedData.teams,
        orgs: processedData.orgs,
        groups: processedData.groups,
        fixedStartDate: processedData.fixedStartDate,
        ...formattedData,
    };
}
