import Linkify from "linkify-react";
import type { JSX } from "react";
import styled from "styled-components";
import ProfileImg from "../../shared/components/ProfileImg";
import t from "../../shared/translations";
import Box from "./Box";
import type { IPerson } from "./OutsideFeedback";

interface IProps {
    person: IPerson;
    description: string;
}

const ProfileBox = styled(Box)`
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: space-between;

    > *:first-child {
        font-size: 1.25rem;
        @media (min-width: 600px) {
            font-size: 1.5rem;
        }
    }
`;

function Info({ person, description }: IProps): JSX.Element {
    const name = `${person.firstName} ${person.lastName}`;
    return (
        <>
            <ProfileBox>
                <div>{t("public.outside-feedback.info-sender-name", { name })}</div>
                <div>
                    <ProfileImg src={person.profilePicSrc} />
                </div>
            </ProfileBox>
            <Box>
                <Linkify options={{ nl2br: true }} tagName="p">
                    {description}
                </Linkify>
            </Box>
        </>
    );
}

export default Info;
