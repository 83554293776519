import { memo, type JSX } from "react";
import styled from "styled-components";
import t from "../../shared/translations";

interface IProps {
    showDetails: boolean;
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    flex-wrap: wrap;
    padding: 0 0.2rem;
    font-weight: bold;
    > * {
        margin: 0 0.1rem;
        flex-shrink: 0;
    }
`;

const IconCol = styled.div`
    width: 50px;
    flex-shrink: 0;
`;

const NameCol = styled.div`
    flex-grow: 0;
    flex-basis: calc(100% - 50px - 0.4rem);
    @media print, (min-width: 800px) {
        flex-grow: 1;
        flex-basis: 0;
    }
`;

const SmallIconsCol = styled.div`
    width: 100%;
    text-align: left;
    @media print, (min-width: 560px) {
        width: 148px;
    }
`;

const MilesCol = styled.div`
    width: 60px;
    text-align: left;
`;

const ThemeCol = styled.div`
    width: 160px;
`;

const ButtonCol = styled.div`
    width: 80px;
    @media print, (min-width: 560px) {
        width: 80px;
    }
`;

function ListHeading({ showDetails }: IProps): JSX.Element {
    return (
        <Wrapper>
            <IconCol>{t("public.builder.list-heading-task")}</IconCol>
            <NameCol> </NameCol>
            <SmallIconsCol>{t("public.builder.list-task-type")}</SmallIconsCol>
            <ThemeCol>{t("public.builder.list-heading-themes")}</ThemeCol>
            <MilesCol>{t("public.builder.list-heading-miles")}</MilesCol>
            {showDetails && <ButtonCol> </ButtonCol>}
        </Wrapper>
    );
}

export default memo(ListHeading);
