import type { ComponentPropsWithoutRef, JSX } from "react";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    className?: string;
    id?: string;
}

function GlassesIcon(props: IProps): JSX.Element {
    return (
        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="m96.2 54c-.1-.3-7.7-25.4-9.2-30.4-1-3.4-5-6-9.1-6.1h-.1c-3.8 0-6.7 2.2-8 6-.3.8.2 1.6.9 1.9.8.3 1.6-.2 1.9-.9 1.1-3.3 3.4-4 5.1-4h.1c2.7 0 5.6 1.8 6.3 3.9 1.3 4.2 6.8 22.4 8.6 28.4h-5.4c-3.1-4.6-8.3-7.6-14.2-7.6-4.9 0-9.3 2.1-12.4 5.4-2.9-2-6.3-3.1-9.8-3.1-3.7 0-7.2 1.2-10.1 3.3-3.1-3.7-7.8-6-13-6-6.1 0-11.4 3.2-14.5 8h-6c1.8-6 7.3-24.3 8.6-28.4.6-2.1 3.5-3.9 6.3-3.9h.1c1.8 0 4 .7 5.1 4 .3.8 1.1 1.2 1.9.9s1.2-1.1.9-1.9c-1.3-3.8-4.2-6-8-6h-.1c-4.1 0-8.1 2.7-9.1 6.1-1.5 5-9.1 30.1-9.2 30.4-.1.5-.1.9.2 1.3s.7.6 1.2.6h6.5c-.7 1.9-1.1 3.9-1.1 6.1 0 9.4 7.7 17.1 17.1 17.1s17.2-7.7 17.2-17.1c0-3.1-.9-6.1-2.3-8.6 2.4-1.8 5.4-2.8 8.4-2.8 2.8 0 5.6.9 7.9 2.4-1.9 2.7-2.9 6-2.9 9.4 0 9.4 7.7 17.1 17.1 17.1s17.1-7.7 17.1-17.1c0-2.3-.5-4.5-1.3-6.5h5.8c.5 0 .9-.2 1.2-.6s.4-.9.3-1.3zm-68.8 21.8c-7.8 0-14.1-6.3-14.1-14.1s6.3-14.1 14.1-14.1 14.1 6.3 14.1 14.1-6.3 14.1-14.1 14.1zm45.4.4c-7.8 0-14.1-6.3-14.1-14.1s6.3-14.1 14.1-14.1 14.1 6.3 14.1 14.1-6.3 14.1-14.1 14.1z"
                fill="#222"
            />
        </svg>
    );
}

export default GlassesIcon;
