import type { FormEvent, JSX } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { loadJSData } from "../../shared/jsData";
import t from "../../shared/translations";
import { useHasRole } from "../CurrentPerson";
import {
    type IDispatch,
    type IState,
    toggleShowLength,
    toggleShowMiles,
    toggleShowPrice,
    toggleShowThemes,
} from "../state";
import MilesChart from "./MilesChart";
import PathLength from "./PathLength";
import PathPrice from "./PathPrice";
import ThemesChart from "./ThemesChart";

const InputRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border: 1px solid black;
    padding: 0.5rem;
    border-radius: 1rem;

    > * {
        flex-shrink: 0;
        flex-grow: 0;
        margin: 0 0.5rem;
    }

    input {
        margin: 0;
    }

    h4 {
        font-size: 1.2rem;
        margin: 0;
    }
`;

const Wrapper = styled.div`
    display: flex;
    gap: 0.8rem;
    flex-wrap: wrap;

    > * {
        margin: 0;
    }
`;

function Summaries(): JSX.Element {
    const dispatch = useDispatch<IDispatch>();
    const isAdmin = useHasRole(["admin"]);
    const showThemes = useSelector((state: IState) => state.builder.showThemes);
    const showMiles = useSelector((state: IState) => state.builder.showMiles);
    const showLength = useSelector((state: IState) => state.builder.showLength);
    const showPrice = useSelector((state: IState) => state.builder.showPrice) && loadJSData().features.cpd_price;
    const tasks = useSelector((state: IState) => state.builder.tasks);

    const toggleThemes = (event: FormEvent<HTMLInputElement>) => {
        dispatch(toggleShowThemes(event.currentTarget.checked));
    };
    const toggleMiles = (event: FormEvent<HTMLInputElement>) => {
        dispatch(toggleShowMiles(event.currentTarget.checked));
    };
    const toggleLength = (event: FormEvent<HTMLInputElement>) => {
        dispatch(toggleShowLength(event.currentTarget.checked));
    };
    const togglePrice = (event: FormEvent<HTMLInputElement>) => {
        dispatch(toggleShowPrice(event.currentTarget.checked));
    };

    return (
        <>
            {isAdmin && (
                <InputRow>
                    <h4>{t("public.builder.heading-summaries")}</h4>
                    <label>
                        <input type="checkbox" checked={showThemes} onChange={toggleThemes} />{" "}
                        {t("public.builder.label-show-themes")}
                    </label>
                    <label>
                        <input type="checkbox" checked={showMiles} onChange={toggleMiles} />{" "}
                        {t("public.builder.label-show-miles")}
                    </label>
                    <label>
                        <input type="checkbox" checked={showLength} onChange={toggleLength} />{" "}
                        {t("public.builder.label-show-length")}
                    </label>
                    {loadJSData().features.cpd_price && (
                        <label>
                            <input type="checkbox" checked={showPrice} onChange={togglePrice} />{" "}
                            {t("public.builder.label-show-price")}
                        </label>
                    )}
                </InputRow>
            )}
            <Wrapper>
                {showThemes && <ThemesChart tasks={tasks} maxWidth="300px" />}
                {showMiles && <MilesChart tasks={tasks} maxWidth="270px" />}
                {showLength && <PathLength tasks={tasks} width="150px" />}
                {showPrice && <PathPrice tasks={tasks} width="160px" pModal={isAdmin} />}
            </Wrapper>
        </>
    );
}

export default Summaries;
