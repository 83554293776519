import type { JSX, MouseEvent } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useQuery } from "urql";
import { parseData } from "../../analysis/impactTracker/report/reportData";
import Loader from "../../shared/components/Loader";
import ShowError from "../../shared/components/ShowError";
import AnswerChart from "../../shared/components/impactTrackerCharts/AnswerChart";
import BarChart from "../../shared/components/impactTrackerCharts/BarChart";
import ChartIcon from "../../shared/components/impactTrackerCharts/ChartIcon";
import LinesChart from "../../shared/components/impactTrackerCharts/LinesChart";
import SpreadChart from "../../shared/components/impactTrackerCharts/SpreadChart";
import t from "../../shared/translations";
import Footer from "../Footer";
import Info from "./Info";
import { type IArgs, type IData, query } from "./queries";
import { ChartBox, ChartCol, H1, H2, LogoImg, Main, Nav, NavAnchor, NoReport, Wrapper } from "./stylings";

function ImpactTrackerReport(): JSX.Element {
    const { publicId } = useParams();
    const [searchParams] = useSearchParams();
    const [result] = useQuery<IData, IArgs>({
        query,
        variables: {
            id: publicId,
            pwd: searchParams.get("pwd"),
        },
    });

    const scroll = (event: MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        const href = new URL(event.currentTarget.href).hash;
        const element = document.querySelector(href);
        element.scrollIntoView({ behavior: "smooth" });
    };

    let main = null;
    if (result.fetching) {
        main = <Loader />;
    } else if (result.error) {
        main = <ShowError error={t("public.error.error")} />;
    } else {
        const report = result.data.impactTrackerReport;
        if (!report) {
            main = (
                <NoReport>
                    <h1>{t("public.impact-tracker-report.info-no-report")}</h1>
                    <div>{t("public.impact-tracker-report.info-no-report")}</div>
                </NoReport>
            );
        } else {
            const { data, learningPath, createdDatetime, logoSrc } = report;
            const { charts, title } = parseData(data);
            main = (
                <>
                    <H1>
                        {title}
                        <LogoImg src={logoSrc} />
                    </H1>
                    <Info learningPath={learningPath} createdDatetime={createdDatetime} />
                    <Nav>
                        {charts.map(({ chart, heading }, index) => (
                            <NavAnchor key={index} href={`#chart-${index}`} onClick={scroll}>
                                <ChartIcon chart={chart} />
                                {heading}
                            </NavAnchor>
                        ))}
                    </Nav>
                    <ChartCol>
                        {charts.map(({ chart, data, heading }, index) => {
                            if (chart === "lines") {
                                return (
                                    <ChartBox key={index} id={`chart-${index}`}>
                                        <H2>{heading}</H2>
                                        <LinesChart
                                            chartData={data.chartData}
                                            fixed={data.fixed}
                                            lines={data.lines}
                                            labels={data.labels}
                                        />
                                    </ChartBox>
                                );
                            }
                            if (chart === "bar") {
                                return (
                                    <ChartBox key={index} id={`chart-${index}`}>
                                        <H2>{heading}</H2>
                                        <BarChart
                                            chartData={data.chartData}
                                            fixed={data.fixed}
                                            questions={data.questions}
                                            dates={data.dates}
                                            summaryStat={data.summaryStat}
                                        />
                                    </ChartBox>
                                );
                            }
                            if (chart === "spread") {
                                return (
                                    <ChartBox key={index} id={`chart-${index}`}>
                                        <H2>{heading}</H2>
                                        <SpreadChart
                                            chartData={data.chartData}
                                            questions={data.questions}
                                            areaHeight={data.areaHeight}
                                        />
                                    </ChartBox>
                                );
                            }
                            if (chart === "answer") {
                                return (
                                    <ChartBox key={index} id={`chart-${index}`}>
                                        <H2>{heading}</H2>
                                        <AnswerChart chartData={data.chartData} />
                                    </ChartBox>
                                );
                            }
                            return null;
                        })}
                    </ChartCol>
                </>
            );
        }
    }
    return (
        <Wrapper>
            <Main>{main}</Main>
            <Footer />
        </Wrapper>
    );
}

export default ImpactTrackerReport;
