import { type UseFieldConfig, useField } from "react-final-form";
import type { JSX } from "react";

interface IOwnProps {
    label?: string;
    name: string;
    className?: string;
}

type IProps = IOwnProps & UseFieldConfig<boolean>;

function Checkbox({ name, label, className, ...props }: IProps): JSX.Element {
    const {
        input,
        meta: { touched, error },
    } = useField(name, { ...props, type: "checkbox" }) as any;
    return (
        <div className={touched && error ? `error ${className}` : className || ""}>
            <label>
                <input {...input} type="checkbox" />
                {label && ` ${label}`}
            </label>
            {touched && error && <small className="error">{error}</small>}
        </div>
    );
}

export default Checkbox;
