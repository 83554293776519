import type { ValidationErrors } from "final-form";
import { useState, type JSX } from "react";
import { Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import { useMutation } from "urql";
import t from "../../shared/translations";
import Question from "./Question";
import { type IData, type ISubmitArgs, type ISubmitData, mutation } from "./queries";
import { ErrorLabel, Grid, SubmitCol } from "./stylings";

type IPulse = IData["pulse"];

interface IFormValues {
    answer1: string;
    answer2: string;
}

interface IProps {
    pulse: IPulse;
}

const defaultValues: IFormValues = {
    answer1: "",
    answer2: "",
};

function validate(values: IFormValues, pulse: IPulse): ValidationErrors {
    const errors: ValidationErrors = {};
    if (!values.answer1) {
        errors.answer1 = t("public.pulse.form-error-answer-required");
    }
    if (!!pulse.question2 && !values.answer2) {
        errors.answer2 = t("public.pulse.form-error-answer-required");
    }
    return errors;
}

function Pulse({ pulse }: IProps): JSX.Element {
    const [_, executeSubmit] = useMutation<ISubmitData, ISubmitArgs>(mutation);
    const navigate = useNavigate();
    const [error, setError] = useState("");

    const onSubmit = async (values: IFormValues) => {
        const data = {
            id: pulse.id,
            answer1: values.answer1,
            answer2: pulse.question2 ? values.answer2 : null,
        };
        const result = await executeSubmit(data);
        if (result.error) {
            console.error(result.error);
            setError(t("public.pulse.error-submit"));
        } else if (result.data.pulseSubmit.error) {
            setError(result.data.pulseSubmit.error);
        } else {
            navigate("../finished");
        }
    };

    return (
        <Form<IFormValues>
            onSubmit={onSubmit}
            validate={(values) => validate(values, pulse)}
            initialValues={defaultValues}
        >
            {({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit}>
                    <Grid>
                        <Question name="answer1" question={pulse.question1} />
                        {!!pulse.question2 && <Question name="answer2" question={pulse.question2} />}
                    </Grid>
                    <SubmitCol>
                        <button type="submit" disabled={submitting}>
                            {t("public.pulse.form-button-submit")}
                        </button>
                        {error && <ErrorLabel>{error}</ErrorLabel>}
                    </SubmitCol>
                </form>
            )}
        </Form>
    );
}

export default Pulse;
