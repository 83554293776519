export interface IArgs {
    id: string;
    pwd: string;
}

export const query = `
query($id:String!,$pwd:String!) {
    learningPathActivityReport(id:$id,pwd:$pwd) {
        id
        version
        name
        data
        createdDatetime
        people {
            id
            firstName
            lastName
        }
        learningPath {
            id
            name
            pathname
        }
    }
}
`;

interface IPerson {
    id: string;
    firstName: string;
    lastName: string;
}

export interface ILearningPath {
    id: string;
    name: string;
    pathname: string;
}

export interface IData {
    learningPathActivityReport: {
        id: string;
        version: number;
        name: string;
        data: string;
        createdDatetime: string;
        people: IPerson[];
        learningPath: ILearningPath;
    } | null;
}
