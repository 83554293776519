import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { createRoot } from "react-dom/client";
import Modal from "react-modal";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import { Provider as URQLProvider, cacheExchange, createClient, fetchExchange } from "urql";
import * as colors from "../shared/colors";
import { UrqlGlobalNotice } from "../shared/components/GlobalNotice";
import { AlertProvider } from "./../shared/components/Alerts";
import sentryExchange from "./../shared/sentryExchange";
import { CurrentPersonProvider } from "./CurrentPerson";
import PublicRoutes from "./PublicRoutes";
import { store } from "./state";

const client = createClient({
    exchanges: [cacheExchange, sentryExchange, fetchExchange],
    fetchOptions: {
        credentials: "same-origin",
        headers: {
            "X-CSRFToken": __CSRF_TOKEN__,
        },
    },
    requestPolicy: "cache-and-network",
    url: "/graphql/public",
});

// init function
export default function init() {
    const ColorStyles = createGlobalStyle`
        a {
            color: ${colors.primaryColor};
        }
        a:hover, a:active, a:focus {
            color: ${colors.primaryLighter};
        }
        button, .button {
            background-color: ${colors.primaryColor};
        }
        button:hover, .button:hover, button:active, .button:active {
            background-color: ${colors.primaryLighter};
        }
        button.disabled, button[disabled], .button.disabled, .button[disabled] {
            background-color: ${colors.primaryDarker};
        }
    `;

    const container = document.getElementById("public-page");
    Modal.setAppElement(container);
    const root = createRoot(container);
    root.render(
        <URQLProvider value={client}>
            <ReduxProvider store={store}>
                <AlertProvider>
                    <BrowserRouter>
                        <CurrentPersonProvider>
                            <DndProvider backend={HTML5Backend}>
                                <>
                                    <UrqlGlobalNotice />
                                    <PublicRoutes />
                                    <ColorStyles />
                                </>
                            </DndProvider>
                        </CurrentPersonProvider>
                    </BrowserRouter>
                </AlertProvider>
            </ReduxProvider>
        </URQLProvider>,
    );
}
