import styled from "styled-components";
import t from "../../shared/translations";
import type { IPage } from "./CreateAccount";
import type { IData } from "./index";
import type { JSX } from "react";

interface IProps {
    data: IData;
    setPage: (page: IPage) => void;
}

const Main = styled.div`
    flex-grow: 1;
`;

const Buttons = styled.div`
    margin-top: 3rem;
    text-align: center;
`;

function ChoosePage({ data, setPage }: IProps): JSX.Element {
    return (
        <Main>
            <h1>{t("public.create-account-choose.heading", { title: data.webappTitle })}</h1>
            <p>{t("public.create-account-choose.paragraph-1", { title: data.webappTitle })}</p>
            <p>{t("public.create-account-choose.paragraph-2", { title: data.webappTitle })}</p>
            <Buttons>
                <button type="button" onClick={() => setPage("Create")}>
                    {t("public.create-account-choose.button-create")}
                </button>{" "}
                <button type="button" onClick={() => setPage("Login")}>
                    {t("public.create-account-choose.button-login")}
                </button>
            </Buttons>
        </Main>
    );
}

export default ChoosePage;
