import { type JSX, memo } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { loadJSData } from "../../shared/jsData";
import t from "../../shared/translations";
import Modal from "../Modal";
import { type IDispatch, openModal } from "../state";
import PriceModal from "./PriceModal";

interface IProps {
    tasks: {
        shortTitle: string;
        length: number;
        learningCoachFeedback: boolean;
        price: number;
    }[];
    pModal?: boolean;
    width: string;
}

interface IWrapper {
    $width: string; // 150px
}

const Wrapper = styled.div<IWrapper>`
    width: ${({ $width }) => $width};
    display: grid;
    align-content: start;
`;

const H3 = styled.h3`
    font-size: 1.35rem;
    text-align: center;
    margin-bottom: -10px;
`;

const Price = styled.div`
    font-size: 1.2rem;
    text-align: center;
    padding: 3rem 0;
    line-height: 1;
`;

const Value = styled.span`
    font-size: 1.8rem;
`;

function PathPrice({ tasks, pModal, width }: IProps): JSX.Element {
    const dispatch = useDispatch<IDispatch>();
    let showPrice = false;
    let minPrice = 0;
    let maxPrice = 0;
    const { builder_base_price, builder_feedback_price, builder_price_range } = loadJSData().settings;
    if (tasks.length > 0) {
        showPrice = true;
        const days = tasks.reduce((acc, task) => acc + task.length, 0);
        let price = days * builder_base_price;
        const feedbacks = tasks.reduce((acc, task) => acc + Number(task.learningCoachFeedback), 0);
        price += feedbacks * builder_feedback_price;
        const extra = tasks.reduce((acc, task) => acc + task.price, 0);
        price += extra;
        minPrice = Math.max(price - price * builder_price_range);
        maxPrice = price + price * builder_price_range;
    }
    const open = () => {
        if (pModal) {
            dispatch(openModal("Builder/PathPrice"));
        }
    };
    return (
        <Wrapper $width={width}>
            <H3>{t("public.builder.heading-path-price")}</H3>
            {showPrice && (
                <Price onClick={open}>
                    <Value>
                        {minPrice.toFixed(0)} - {maxPrice.toFixed(0)}
                    </Value>
                    <br />
                    {t("public.builder.label-price")}
                </Price>
            )}
            {pModal && (
                <Modal modal="Builder/PathPrice">
                    <PriceModal tasks={tasks} />
                </Modal>
            )}
        </Wrapper>
    );
}

export default memo(PathPrice);
