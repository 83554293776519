import type { JSX } from "react";
import { type DropTargetMonitor, useDrop } from "react-dnd";
import { useDispatch, useSelector } from "react-redux";
import { type IDispatch, type IState, addTaskToPath, moveTaskOnPath } from "../state";
import ListHeading from "./ListHeading";
import type { ISourceTaskItem } from "./SourceTask";
import TargetTask, { type ITargetTaskItem } from "./TargetTask";
import TaskBox from "./TaskBox";

function ListView(): JSX.Element {
    const dispatch = useDispatch<IDispatch>();
    const tasks = useSelector((state: IState) => state.builder.tasks);
    const [_, holderDropRef] = useDrop({
        accept: ["SOURCE_TASK", "TARGET_TASK"],
        drop: (item: ISourceTaskItem | ITargetTaskItem, monitor: DropTargetMonitor) => {
            if (monitor.didDrop()) {
                return;
            }
            if (item.type === "SOURCE_TASK") {
                dispatch(addTaskToPath({ task: item.task, where: "end" }));
            } else {
                dispatch(moveTaskOnPath({ index: item.index, target: "end" }));
            }
        },
        collect: (monitor: DropTargetMonitor) => ({
            isHolderOver: monitor.isOver({ shallow: true }),
        }),
    });
    const [{ isHeadingOver }, headingDropRef] = useDrop({
        accept: ["SOURCE_TASK", "TARGET_TASK"],
        drop: (item: ISourceTaskItem | ITargetTaskItem) => {
            if (item.type === "SOURCE_TASK") {
                dispatch(addTaskToPath({ task: item.task, where: "start" }));
            } else {
                dispatch(moveTaskOnPath({ index: item.index, target: "start" }));
            }
        },
        collect: (monitor: DropTargetMonitor) => ({
            isHeadingOver: monitor.isOver({ shallow: true }),
        }),
    });
    return (
        <div>
            <div ref={holderDropRef}>
                <div style={{ paddingBottom: isHeadingOver ? 60 : 0 }} ref={headingDropRef}>
                    <ListHeading showDetails={true} />
                </div>
                {tasks.map((task, index) => (
                    <TargetTask key={index} index={index} vertical>
                        <TaskBox task={task} onPath={true} showDetails={true} index={index} />
                    </TargetTask>
                ))}
            </div>
        </div>
    );
}

export default ListView;
