import { useState, type JSX } from "react";
import { type UseFieldConfig, useField } from "react-final-form";
import styled from "styled-components";

interface IOwnProps {
    label?: string;
    placeholder?: string;
    name: string;
    className?: string;
    maxLength?: number;
    showPassword?: boolean;
    showLabel?: string;
    hideLabel?: string;
}

type IProps<T> = IOwnProps & UseFieldConfig<T>;

const Row = styled.div`
    display: flex;
`;

const Button = styled.button`
    padding: 0 1rem;
    margin-bottom: 0;
    text-align: center;
    font-size: .875rem;
    height: 2.3125rem;
    line-height: 2.3125rem;
`;

function Input<T = string>({
    name,
    label,
    placeholder,
    className,
    maxLength,
    showPassword,
    showLabel,
    hideLabel,
    ...props
}: IProps<T>): JSX.Element {
    const {
        input,
        meta: { touched, error },
    } = useField<any, HTMLInputElement>(name, props);
    const [show, setShow] = useState(false);

    return (
        <div className={touched && error ? `error ${className}` : className || ""}>
            <label>
                {label && `${label}: `}
                <Row>
                    <input
                        {...input}
                        placeholder={placeholder}
                        maxLength={maxLength}
                        type={show ? "text" : "password"}
                    />
                    {showPassword && (
                        <Button type="button" onClick={() => setShow((s) => !s)}>
                            {show ? hideLabel : showLabel}
                        </Button>
                    )}
                </Row>
                {touched && error && <small className="error">{error}</small>}
            </label>
        </div>
    );
}

export default Input;
