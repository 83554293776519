import { type CSSProperties, Fragment, type JSX, type MutableRefObject, memo } from "react";
import { Line, LineChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import type { LineType } from "../../../analysis/impactTracker/LinesConfig";
import * as colors from "../../colors";
import { formatVeryShort } from "../../dateFns";
import maxTextWidth from "../../maxTextWidth";
import t from "../../translations";
import { Legend, LegendLine } from "./stylings";

export type IChartDatum = {
    label: number;
    value: number;
};

interface IProps {
    fixed: boolean;
    chartData: IChartDatum[][][];
    lines: {
        statement: string;
        lineType: LineType;
        label: boolean;
    }[];
    labels: number[];
    chartRef?: MutableRefObject<HTMLDivElement>;
    style?: CSSProperties;
}

function yDomains(
    lines: { statement: string; lineType: LineType; label: boolean }[],
): [[number, number], [number, number] | null] {
    const hasNet = lines.some((line) => line.lineType === "net");
    const hasNotNet = lines.some((line) => line.lineType !== "net");

    if (hasNet && hasNotNet) {
        return [
            [0, 10],
            [-100, 100],
        ];
    }
    if (hasNet && !hasNotNet) {
        return [[-100, 100], null];
    }
    if (!hasNet && hasNotNet) {
        return [[0, 10], null];
    }
    return [[0, 10], null];
}

function opacity(line: { lineType: LineType }): number {
    if (line.lineType === "spread") {
        return 0.2;
    }
    return 1.0;
}

interface ILabelProps {
    x: number;
    y: number;
    stroke: string;
    value: number;
    index: number;
}

function renderLabel(props: ILabelProps): React.ReactElement<SVGElement> {
    const { x, y, stroke, value } = props;
    if (!value) {
        return null;
    }
    return (
        <text x={x} y={y} dy={-4} dx={1} fill={stroke} fontSize={15} textAnchor="middle">
            {value.toFixed(1)}
        </text>
    );
}

export function fmtTick(value: number, fixed: boolean): string {
    if (fixed) {
        const date = new Date(value);
        return formatVeryShort(date);
    }
    return t("shared.impact-tracker-chart.day", { day: value });
}

const FONT = '400 15px / 1.5 "Proxima Nova", Verdana, sans-serif';

function LinesChart({ fixed, chartData, chartRef, lines, labels, style }: IProps): JSX.Element {
    const [leftDomain, rightDomain] = yDomains(lines);
    const maxLeft = maxTextWidth(
        leftDomain.map((val) => val.toString()),
        FONT,
    );
    const maxRight = rightDomain
        ? maxTextWidth(
              rightDomain.map((val) => val.toString()),
              FONT,
          )
        : 0;
    return (
        <div style={style} ref={chartRef}>
            <div>
                <ResponsiveContainer width="100%" aspect={2}>
                    <LineChart>
                        <YAxis type="number" dataKey="value" yAxisId="left" domain={leftDomain} width={maxLeft + 10} />
                        {rightDomain && (
                            <YAxis
                                type="number"
                                dataKey="value"
                                yAxisId="right"
                                domain={rightDomain}
                                orientation="right"
                                width={maxRight + 10}
                            />
                        )}
                        <XAxis
                            dataKey="label"
                            ticks={labels}
                            padding={{ left: 20, right: 20 }}
                            type="category"
                            allowDuplicatedCategory={false}
                            tickFormatter={(value) => fmtTick(value, fixed)}
                        />
                        {lines.flatMap((line, idx) => {
                            const data = chartData[idx];
                            return data.map((datum, idx2) => (
                                <Line
                                    isAnimationActive={false}
                                    dataKey="value"
                                    stroke={colors.chart(idx)}
                                    strokeWidth={3}
                                    dot={false}
                                    key={idx2}
                                    data={datum}
                                    opacity={opacity(line)}
                                    yAxisId={line.lineType === "net" && rightDomain ? "right" : "left"}
                                    label={line.label && ((props) => renderLabel(props))}
                                />
                            ));
                        })}
                    </LineChart>
                </ResponsiveContainer>
            </div>
            <Legend>
                {lines.map((line, idx) => (
                    <Fragment key={idx}>
                        <LegendLine $color={colors.chart(idx)} $opacity={opacity(line)} />
                        <span>{line.statement}</span>
                        <span>{t(`shared.impact-line-type.${line.lineType}`)}</span>
                    </Fragment>
                ))}
            </Legend>
        </div>
    );
}

export default memo(LinesChart);
