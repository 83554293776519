import { type CSSProperties, type JSX, type PropsWithChildren, useRef } from "react";
import { type DragSourceMonitor, type DropTargetMonitor, useDrag, useDrop } from "react-dnd";
import { useDispatch } from "react-redux";
import { useHasRole } from "../CurrentPerson";
import { type IDispatch, addTaskToPathAtIndex, moveTaskOnPath } from "../state";
import type { ISourceTaskItem } from "./SourceTask";

type IProps = PropsWithChildren<{
    index: number;
    vertical?: boolean;
}>;

export interface ITargetTaskItem {
    type: "TARGET_TASK";
    index: number;
}

const TargetTask = ({ children, index, vertical }: IProps): JSX.Element => {
    const isAdmin = useHasRole(["admin"]);
    const dispatch = useDispatch<IDispatch>();
    const ref = useRef<HTMLDivElement>(null);
    const [{ isOver }, dropRef] = useDrop({
        accept: ["SOURCE_TASK", "TARGET_TASK"],
        drop: (item: ISourceTaskItem | ITargetTaskItem) => {
            if (item.type === "SOURCE_TASK") {
                dispatch(addTaskToPathAtIndex({ task: item.task, index }));
            } else {
                dispatch(moveTaskOnPath({ index: item.index, target: index }));
            }
        },
        collect: (monitor: DropTargetMonitor) => ({
            isOver: monitor.isOver(),
        }),
    });
    const [{ isDragging }, dragRef] = useDrag({
        type: "TARGET_TASK",
        item: () => ({ type: "TARGET_TASK", index }),
        collect: (monitor: DragSourceMonitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    dragRef(dropRef(ref));
    const style: CSSProperties = {};
    style[vertical ? "paddingTop" : "paddingLeft"] = isOver ? 90 : 0;
    return (
        <div ref={isAdmin ? ref : undefined} style={style}>
            {!isDragging && children}
        </div>
    );
};

export default TargetTask;
