import { memo } from "react";
import type { JSX } from "react";
import styled from "styled-components";
import { loadJSData } from "../../shared/jsData";

const Container = styled.div`
    text-align: center;

    & img {
        max-height: 100px;
        max-width: 290px;
        object-fit: scale-down;
    }
`;

function LogoContainer(): JSX.Element {
    return (
        <Container>
            <img src={loadJSData().settings.logo_src} />
        </Container>
    );
}

export default memo(LogoContainer);
