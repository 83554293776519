import { type ComponentPropsWithoutRef, memo, type JSX } from "react";
import * as colors from "../../shared/colors";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    flip?: boolean;
}

function PathArrow({ flip, ...props }: IProps): JSX.Element {
    return (
        <svg
            width="35"
            height="200"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            viewBox="0 0 35 200"
            xmlns="http://www.w3.org/2000/svg"
            style={{ cursor: "pointer" }}
            {...props}
        >
            <g transform={`rotate(${flip ? "180" : "0"}, 17.5, 100)`}>
                <path d="m5 30h5l15 70-15 70h-5l10-70z" fill={colors.primaryColor} />
            </g>
        </svg>
    );
}

export default memo(PathArrow);
