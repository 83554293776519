import type { JSX } from "react";
import styled from "styled-components";
import * as colors from "../shared/colors";
import { loadJSData } from "../shared/jsData";
import t from "../shared/translations";

const Main = styled.main`
    max-width: 1000px;
    padding: 0 15px;
    margin: 0 auto;
    text-align: center;
`;

const H1 = styled.h1`
    font-size: 3rem;
    font-weight: normal;
`;

const Img = styled.img`
    margin: 0 5px;
`;

const Logo = styled.img`
    max-width: 290px;
    max-height: 100px;
    object-fit: scale-down;
`;

const SmallLogo = styled.img`
    max-width: 290px;
    max-height: 100px;
    object-fit: scale-down;
`;

const AnchorButton = styled.a`
    display: inline-block;
    border-radius: 0.8rem;
    font-size: 2rem;
    color: ${colors.white};
    background-color: ${colors.primaryColor};
    padding: 1rem 2rem 1.0625rem 2rem;
    text-decoration: none;
    margin: 0 0 1.25rem;

    &:hover, &:focus, &:active {
        background-color: ${colors.primaryColor};
        color: ${colors.white};
    }
`;

function Start(): JSX.Element {
    return (
        <Main>
            <H1>{t("public.index.heading")}</H1>
            <div>
                <Img src="/static/images/avatar/male-1-jumping.png" />
                <Img src="/static/images/avatar/female-1-jumping.png" />
            </div>
            <AnchorButton href="/login">{t("public.index.button-play")}</AnchorButton>
            <p>
                <Logo src={loadJSData().settings.logo_src} alt="Logo" />
                <br />
                {loadJSData().show_secondary_logo && (
                    <>
                        <SmallLogo src="/static/images/TalentMiles-logo-liggande.svg" alt="TalentMiles Logo" />
                        <br />
                    </>
                )}
                {t("public.index.info-rights")}
            </p>
        </Main>
    );
}

export default Start;
