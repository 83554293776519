import { type JSX, useState } from "react";
import styled from "styled-components";
import t from "../../shared/translations";
import ResetForm from "./ResetForm";

const Main = styled.main`
    max-width: 1000px;
    padding: 0 15px;
    margin: 0 auto;
`;

function ResetPassword(): JSX.Element {
    const [sent, setSent] = useState(false);
    return (
        <Main id="reset-password-page">
            <h1>{t("public.reset-password.heading")}</h1>
            {sent ? (
                <p>{t("public.reset-password.info-reset-link-sent")}</p>
            ) : (
                <>
                    <p>{t("public.reset-password.info-reset-password")}</p>
                    <ResetForm setSent={setSent} />
                </>
            )}
        </Main>
    );
}

export default ResetPassword;
