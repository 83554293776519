import styled from "styled-components";
import t from "../../shared/translations";
import type { IData } from "./index";
import type { JSX } from "react";

interface IProps {
    data: IData;
}

const Main = styled.div`
    flex-grow: 1;
`;

function UsedPage({ data }: IProps): JSX.Element {
    return (
        <Main>
            <h1>{t("public.create-account-used.heading", { title: data.webappTitle })}</h1>
            <p>
                {t("public.create-account-used.info-login", { title: data.webappTitle })}{" "}
                <a href="/login">{t("public.create-account-used.info-login-link")}</a>
            </p>
            <p>{t("public.create-account-used.info-problems")}</p>
        </Main>
    );
}

export default UsedPage;
