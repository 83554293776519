import type { IV2ParsedData } from "./v2";

interface ICompletionsData {
    people: {
        id: string;
        name: string;
        teamName: string;
        orgName: string;
        groupName: string;
    }[];
    tasks: { id: string; shortTitle: string }[];
    completions: Map<string, Map<string, boolean>>;
    pastDeadlines: Map<string, Map<string, boolean>>;
    miles: Map<string, number> | undefined;
    counts: Map<string, Map<string, number>> | undefined;
    totals: Map<string, number>;
    state: {
        showNames: boolean;
        rowsPerColumn: number;
        groupBy: "person" | "group" | "org" | "team";
    };
}

interface IMilesBarData {
    chartData: {
        label: string;
        value: number;
    }[];
    checkpoint: number;
    goal: number;
    deadlineGoal: number;
}

interface IMilesLineData {
    chartData: (
        | {
              label: number;
              deadline: number;
          }
        | Record<string, number>
    )[];
    state: {
        yAxis: "total" | "average" | "team" | "group" | "org";
        xAxis: "day" | "week" | "month";
        relative: "calendar" | "path";
        linesDeadline: boolean;
        maxTicks: number;
    };
    teams: {
        id: string;
        name: string;
    }[];
    groups: {
        id: string;
        name: string;
    }[];
    orgs: {
        id: string;
        name: string;
    }[];
    checkpoint: number;
    goal: number;
}

interface ITaskCountLineData {
    chartData: (
        | {
              label: number;
          }
        | Record<string, number>
    )[];
    state: {
        xAxis: "day" | "week" | "month";
        relative: "calendar" | "path";
        maxTicks: number;
        dataLabels: boolean;
    };
    tasks: {
        id: string;
        shortTitle: string;
    }[];
    numMembers: number;
}

export type V3ChartDatum =
    | { chart: "miles-bar"; data: IMilesBarData; heading: string }
    | { chart: "miles-line"; data: IMilesLineData; heading: string }
    | { chart: "task-count"; data: ITaskCountLineData; heading: string }
    | { chart: "completions-table"; data: ICompletionsData; heading: string };

export interface IV3ParsedData {
    charts: V3ChartDatum[];
    version: 3;
    name: string;
}

export function v2tov3(v2Data: IV2ParsedData): IV3ParsedData {
    const v3Data: IV3ParsedData = {
        name: v2Data.name,
        version: 3,
        charts: v2Data.charts.map((chartDatum) => {
            if (chartDatum.chart === "task-count") {
                return {
                    ...chartDatum,
                    data: {
                        ...chartDatum.data,
                        state: {
                            ...chartDatum.data.state,
                            dataLabels: false,
                        },
                    },
                };
            }
            return chartDatum;
        }),
    };
    return v3Data;
}
