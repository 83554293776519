import type { JSX } from "react";
import styled from "styled-components";
import * as colors from "../shared/colors";
import t from "../shared/translations";
import Logo from "./Logo";

const FooterElem = styled.footer`
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.5rem;
    > * {
        text-align: center;
        font-size: 0.9em;
    }
    align-items: center;
    margin-top: 2rem;
    margin-block-end: 0.5rem;
    padding-top: 0.3rem;
    @media (min-width: 500px) {
        grid-template-columns: 1fr 1fr;
    }
    border-top: 1px solid ${colors.unlight};
`;

const FooterLogo = styled(Logo)`
    height: 1.5rem!important;
`;

function Footer(): JSX.Element {
    return (
        <FooterElem>
            <div>
                <FooterLogo />
            </div>
            <div>{t("shared.footer.copyright")}</div>
        </FooterElem>
    );
}

export default Footer;
