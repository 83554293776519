// Query pulse

export interface IArgs {
    id: string;
}

export const query = `
    query($id:String!) {
        pulse(id:$id) {
            id
            question1
            question2
        }
    }
`;

export interface IData {
    pulse: {
        id: string;
        question1: string;
        question2: string | null;
    } | null;
}

// Submit pulse mutation

export interface ISubmitArgs {
    id: string;
    answer1: string;
    answer2: string | null;
}

export const mutation = `
    mutation($id:String!,$answer1:String!,$answer2:String) {
        pulseSubmit(id:$id,answer1:$answer1,answer2:$answer2) {
            error
        }
    }
`;

export interface ISubmitData {
    pulseSubmit: {
        error: string | null;
    };
}
