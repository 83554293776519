import { sortBy } from "lodash-es";
import { memo, useMemo, useState } from "react";
import type { JSX } from "react";
import { Bar, BarChart, Cell, ResponsiveContainer, XAxis, YAxis } from "recharts";
import styled from "styled-components";
import * as colors from "../../shared/colors";
import t from "../../shared/translations";
import type { TaskTheme } from "../../shared/types";

interface IProps {
    tasks: {
        themes: {
            theme: TaskTheme;
            value: number;
        }[];
    }[];
    maxWidth: string;
}

interface IWrapper {
    $maxWidth: string;
}

const Wrapper = styled.div<IWrapper>`
    max-width: ${({ $maxWidth }) => $maxWidth};
    min-width: 270px;
    flex-grow: 1;
`;

const H3 = styled.h3`
    font-size: 1.35rem;
    text-align: center;
    margin-bottom: 0;
`;

const Center = styled.div`
    text-align: center;
`;

const FakeLink = styled.button`
    background-color: transparent;
    display: inline;
    color: ${colors.primaryColor};
    cursor: pointer;
    transition: none;
    padding: 0;
    margin: 0;
    font-size: 0.85rem;
    &:hover, &:active, &:focus, &:visited {
        color: ${colors.primaryColor};
        background-color: transparent;
    }
`;

function ThemesChart({ tasks, maxWidth }: IProps): JSX.Element {
    const [showMore, setShowMore] = useState(false);
    const allThemes = useMemo(() => {
        const themesObj = tasks.reduce(
            (obj, task) => {
                for (const theme of task.themes) {
                    if (obj[theme.theme] === undefined) {
                        obj[theme.theme] = 0;
                    }
                    obj[theme.theme] += theme.value;
                }
                return obj;
            },
            {} as { [index: string]: number },
        );
        const themesArr = Object.entries(themesObj).map(([theme, value]) => ({
            theme,
            label: t(`shared.task-themes.${theme}`),
            value,
        }));
        return sortBy(themesArr, (theme) => -theme.value);
    }, [tasks]);

    let themes = allThemes;
    if (!showMore) {
        themes = allThemes.slice(0, 6);
    }

    return (
        <Wrapper $maxWidth={maxWidth}>
            <H3>{t("public.builder.heading-themes-chart")}</H3>
            {themes.length > 0 && (
                <ResponsiveContainer width="100%" height={themes.length * 26 + 32}>
                    <BarChart
                        data={themes}
                        margin={{
                            top: 5,
                            right: 20,
                            left: 55,
                            bottom: -13,
                        }}
                        layout="vertical"
                        barGap={15}
                    >
                        <Bar dataKey="value" isAnimationActive={false}>
                            {themes.map((datum) => (
                                <Cell
                                    key={datum.theme}
                                    fill={colors.theme[datum.theme]}
                                    stroke={colors.theme[datum.theme]}
                                />
                            ))}
                        </Bar>
                        <YAxis dataKey="label" type="category" interval={0} tick={{ fontSize: 13 }} />
                        <XAxis type="number" interval="preserveStartEnd" tick={{ fontSize: 13 }} />
                    </BarChart>
                </ResponsiveContainer>
            )}
            {allThemes.length > 6 && (
                <Center>
                    {showMore ? (
                        <FakeLink onClick={() => setShowMore(false)}>- {t("public.builder.link-show-less")}</FakeLink>
                    ) : (
                        <FakeLink onClick={() => setShowMore(true)}>+ {t("public.builder.link-show-more")}</FakeLink>
                    )}
                </Center>
            )}
        </Wrapper>
    );
}

export default memo(ThemesChart);
