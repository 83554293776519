import { chunk } from "lodash-es";
import { type JSX, type MutableRefObject, memo } from "react";
import styled from "styled-components";
import * as colors from "../../colors";
import maxTextWidth from "../../maxTextWidth";
import t from "../../translations";
import { Legend, LegendLabel, LegendMark } from "./stylings";

interface IPerson {
    id: string;
    name: string;
    teamName: string;
    orgName: string;
    groupName: string;
}

type GroupBy = "person" | "group" | "org" | "team";

export interface IProps {
    people: IPerson[];
    tasks: { id: string; shortTitle: string }[];
    completions: Map<string, Map<string, boolean>>;
    pastDeadlines: Map<string, Map<string, boolean>>;
    miles: Map<string, number> | undefined;
    counts: Map<string, Map<string, number>> | undefined;
    totals: Map<string, number>;
    chartRef?: MutableRefObject<HTMLDivElement>;
    state: {
        showNames: boolean;
        rowsPerColumn: number;
        groupBy: GroupBy;
    };
}

const Container = styled.div`
    grid-column: span 3;
    display: flex;
`;

interface IFlexRow {
    $gap: string;
}

const FlexRow = styled.div<IFlexRow>`
    display: flex;
    gap: ${({ $gap }) => $gap};
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 1rem;
    > * {
        flex-shrink: 0;
    }
`;

const Table = styled.table`
    border: none;
    margin-bottom: 0;
`;

const Thead = styled.thead`
    background-color: ${colors.white};
`;

interface ICorner {
    $height: number;
}

const Corner = styled.th<ICorner>`
    height: ${({ $height }) => Math.floor($height) + 5}px;
    background-color: ${colors.white};
`;

interface ITaskTh {
    $height: number;
}

const TaskTh = styled.th<ITaskTh>`
    font-size: 0.85rem;
    height: ${({ $height }) => Math.floor($height) + 5}px;
    width: 25px;
    padding: 0;
    padding-bottom: 5px;
    font-weight: normal;
    overflow: hidden;
    vertical-align: bottom;
`;

const RotatedText = styled.div`
    transform: rotate(-90deg);
    white-space: nowrap;
    width: 25px;
    height: 25px;
    line-height: 25px;
`;

const Tbody = styled.tbody`
    tr:nth-of-type(even) {
        background-color: white;
        th {
            background-color: white;
        }
    }
    tr:nth-of-type(odd) {
        background-color: ${colors.secondaryColor};
        th {
            background-color: ${colors.secondaryColor};
        }
    }
`;

const PersonTh = styled.th`
    font-size: 0.85rem;
    height: 25px;
    line-height: 25px;
    text-align: right;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0;
    padding-right: 5px;
    font-weight: normal;
    overflow: hidden;
`;

const GroupingTh = styled.th`
    font-size: 0.85rem;
    height: 25px;
    line-height: 25px;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0;
    padding-left: 3px;
    font-weight: normal;
    overflow: hidden;
`;

interface ITd {
    $color: string;
}

const Td = styled.td<ITd>`
    width: 25px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    font-size: 25px;
    padding: 0;
    color: ${({ $color }) => $color};
`;

const MilesTd = styled.td`
    height: 25px;
    line-height: 25px;
    padding: 0;
    padding-right: 3px;
    text-align: right;
`;

const CountTd = styled.td`
    width: 25px;
    height: 25px;
    line-height: 25px;
    padding: 0;
    text-align: center;
`;

const FONT = '400 0.85rem / 1.5 "Proxima Nova", Verdana, sans-serif';

function markNcolor(
    taskId: string,
    personId: string,
    completions: Map<string, Map<string, boolean>>,
    pastDeadlines: Map<string, Map<string, boolean>>,
): [string, string] {
    const complete = completions.get(taskId)?.get(personId);
    const pastDeadline = pastDeadlines.get(taskId)?.get(personId);
    const color = complete ? colors.checkmarkGreen : pastDeadline ? colors.checkmarkRed : colors.checkmarkGrey;
    const mark = complete ? "✔" : "✘";
    return [mark, color];
}

function getGroupLabel(person: IPerson, groupBy: GroupBy): string {
    if (groupBy === "org") {
        return person.orgName || "";
    }
    if (groupBy === "group") {
        return person.groupName || "";
    }
    if (groupBy === "team") {
        return person.teamName || "";
    }
    return "";
}

function CompletionsTable({
    tasks,
    people,
    completions,
    pastDeadlines,
    chartRef,
    state,
    miles,
    counts,
    totals,
}: IProps): JSX.Element {
    const { showNames, rowsPerColumn, groupBy = "person" } = state;
    const maxTaskSize = maxTextWidth(
        tasks.map((task) => task.shortTitle),
        FONT,
    );
    let groupingLabel = null;
    const markNcolorClosure = (taskId: string, personId: string) =>
        markNcolor(taskId, personId, completions, pastDeadlines);
    let rows: JSX.Element[][] = [];
    let rowChunk: JSX.Element[] = [];
    for (const [idx, person] of people.entries()) {
        // If the grouping label changes switch start a new table
        const newGroupingLabel = getGroupLabel(person, groupBy);
        if (newGroupingLabel !== groupingLabel) {
            // If there are counts add them to previous chunk before starting a new chunk
            if (!!counts && rowChunk.length > 0) {
                const total = totals.get(groupingLabel);
                const taskCount = counts.get(groupingLabel);
                rowChunk.push(
                    <tr key={`count-${idx}`}>
                        {showNames && (
                            <PersonTh>{t("shared.learning-path-activity-charts.label-task-count", { total })}</PersonTh>
                        )}
                        {tasks.map((task) => (
                            <CountTd key={task.id}>{taskCount?.get(task.id)}</CountTd>
                        ))}
                        <MilesTd />
                    </tr>,
                );
            }

            // Start a new chunk
            rows.push(rowChunk);
            rowChunk = [];
            groupingLabel = newGroupingLabel;

            // If the rows are groups add heading
            if (groupBy !== "person") {
                rowChunk.push(
                    <tr key={`gouping-${idx}`}>
                        <GroupingTh colSpan={tasks.length + 1 + (showNames ? 1 : 0)}>{groupingLabel}</GroupingTh>
                    </tr>,
                );
            }
        }

        // Add a row for each person in the chunk
        rowChunk.push(
            <tr key={`person-${idx}`}>
                {showNames && <PersonTh>{person.name}</PersonTh>}
                {tasks.map((task) => {
                    const [mark, color] = markNcolorClosure(task.id, person.id);
                    return (
                        <Td key={task.id} $color={color}>
                            {mark}
                        </Td>
                    );
                })}
                {!!miles && <MilesTd>{miles.get(person.id)}</MilesTd>}
            </tr>,
        );
    }

    // Add counts for final chunk
    if (!!counts && rowChunk.length > 0) {
        const total = totals.get(groupingLabel ?? "");
        const taskCount = counts.get(groupingLabel ?? "");
        rowChunk.push(
            <tr key={"final"}>
                {showNames && (
                    <PersonTh>{t("shared.learning-path-activity-charts.label-task-count", { total })}</PersonTh>
                )}
                {tasks.map((task) => (
                    <CountTd key={task.id}>{taskCount?.get(task.id)}</CountTd>
                ))}
                <MilesTd />
            </tr>,
        );
    }
    // Add final chunk to rows
    rows.push(rowChunk);

    // If any chunk is longer than max length, split it up into multiple chunks
    rows = rows.flatMap((rowChunk) => chunk(rowChunk, rowsPerColumn));

    return (
        <Container>
            <div ref={chartRef}>
                <FlexRow $gap={showNames ? "1rem" : "10ch"}>
                    {rows.map((rowChunk, index) => (
                        <Table key={index}>
                            <Thead>
                                <tr>
                                    {showNames && <Corner $height={maxTaskSize} />}
                                    {tasks.map((task) => (
                                        <TaskTh key={task.id} $height={maxTaskSize}>
                                            <RotatedText>{task.shortTitle}</RotatedText>
                                        </TaskTh>
                                    ))}
                                    <TaskTh $height={maxTaskSize}>
                                        <RotatedText>
                                            {t("shared.learning-path-activity-charts.label-learning-miles")}
                                        </RotatedText>
                                    </TaskTh>
                                </tr>
                            </Thead>
                            <Tbody>{rowChunk}</Tbody>
                        </Table>
                    ))}
                </FlexRow>
                <Legend $left>
                    <LegendLabel $gap="0px">
                        <LegendMark $color={colors.checkmarkGreen}>✔</LegendMark>
                        {t("shared.learning-path-activity-charts.label-complete")}
                    </LegendLabel>
                    <LegendLabel $gap="0px">
                        <LegendMark $color={colors.checkmarkGrey}>✘</LegendMark>
                        {t("shared.learning-path-activity-charts.label-incomplete")}
                    </LegendLabel>
                    <LegendLabel $gap="0px">
                        <LegendMark $color={colors.checkmarkRed}>✘</LegendMark>
                        {t("shared.learning-path-activity-charts.label-incomplete-past-deadline")}
                    </LegendLabel>
                </Legend>
            </div>
        </Container>
    );
}

export default memo(CompletionsTable);
