import { useState, type JSX } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import * as colors from "../../shared/colors";
import t from "../../shared/translations";
import { type IDispatch, updatePathName } from "../state";

const EditButton = styled.button`
    display: inline-block;
    background-color: transparent;
    border: 1px solid ${colors.text};
    border-radius: 0;
    color: ${colors.text};
    margin-top: 0.2rem;
    font-size: 1rem;
    padding: 0.25rem 0.85rem;
    transition: none;
    line-height: 22px;
    margin-left: auto;

    &:hover, &:focus, &:active, &:visited {
        background-color: transparent;
        color: ${colors.text};
    }
`;

interface IProps {
    name: string;
    setEditName: (editName: boolean) => void;
    width: number;
}

function NameEditor({ name, setEditName, width }: IProps): JSX.Element {
    const dispatch = useDispatch<IDispatch>();
    const [value, setValue] = useState(name);
    const save = () => {
        dispatch(updatePathName(value));
        setEditName(false);
    };
    return (
        <>
            <input type="text" value={value} onChange={(e) => setValue(e.currentTarget.value)} style={{ width }} />
            <EditButton onClick={save}>{t("public.builder.button-save-name")}</EditButton>
        </>
    );
}

export default NameEditor;
