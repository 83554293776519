import type { ComponentPropsWithoutRef, JSX } from "react";
import BarChartIcon from "../icons/BarChartIcon";
import DensityIcon from "../icons/DensityIcon";
import LikertIcon from "../icons/LikertIcon";
import PerformanceIcon from "../icons/navIcons/PerformanceIcon";

type IChart = "lines" | "bar" | "spread" | "answer";

interface IProps extends ComponentPropsWithoutRef<"svg"> {
    chart: IChart;
}

function ChartIcon({ chart, ...props }: IProps): JSX.Element {
    switch (chart) {
        case "lines":
            return <PerformanceIcon {...props} />;
        case "bar":
            return <BarChartIcon {...props} />;
        case "spread":
            return <DensityIcon {...props} />;
        case "answer":
            return <LikertIcon {...props} />;
        default:
            throw new Error(`Unknown chart: ${chart}`);
    }
}

export default ChartIcon;
