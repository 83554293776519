import type { JSX } from "react";
import styled from "styled-components";
import { loadJSData } from "../../shared/jsData";
import t from "../../shared/translations";

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: center;
    border-top: 1px solid DarkGray;
    margin: 5px 0;
    gap: 5px;
    @media (min-width: 600px) {
        justify-items: space-around;
        grid-template-columns: 1fr 1fr;
    }
`;

const Img = styled.img`
    max-height: 2rem;
    max-width: 290px;
    object-fit: scale-down;
    object-position: center center;
    flex-shrink: 0;
`;

const Copyright = styled.div`
    text-align: center;
    font-size: 0.9em;
`;

function Footer(): JSX.Element {
    return (
        <Container>
            <Img src={loadJSData().player_logo_src} />
            <Copyright>{t("shared.footer.copyright")}</Copyright>
        </Container>
    );
}

export default Footer;
