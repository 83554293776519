import { useState } from "react";
import type { JSX } from "react";
import { type DropTargetMonitor, useDrop } from "react-dnd";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import PlusMinus from "../../shared/components/icons/PlusMinus";
import TaskIcon from "../../shared/components/icons/TaskIcon";
import t from "../../shared/translations";
import { useHasRole } from "../CurrentPerson";
import { type IDispatch, type IState, addTaskToPath, moveTaskOnPath, openModal, setModalTask } from "../state";
import Avatar from "./Avatar";
import ListView from "./ListView";
import PathArrow from "./PathArrow";
import type { ISourceTaskItem } from "./SourceTask";
import TargetTask, { type ITargetTaskItem } from "./TargetTask";

const Sticker = styled.div`
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white;
    overflow-y: auto;
    max-height: 100vh;
    max-width: calc(100vw - 30px);
`;

const OuterWrapper = styled.div`
    display: grid;
    grid-template-columns: 35px 1fr 35px;
    height: 200px;
    background: url("/static/images/background.png") repeat-x 0px 110px;
`;

const PathWrapper = styled.div`
    height: 200px;
    padding: 0;
    width: 100%;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
`;

const Holder = styled.div`
    display: grid;
    grid-template-columns: 90px;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    transition: 0.3s ease-in-out;
`;

const Task = styled.div`
    width: 80px;
    height: 200px;
    flex-shrink: 0;
    cursor: pointer;
    svg {
        width: 80px;
        height: 80px;
    }
`;

const H5 = styled.h4`
    font-size: 1.1rem;
    margin: 0;
    cursor: pointer;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px;
`;

const ListBox = styled.div`
    border: 2px solid black;
    border-top: none;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
    padding: 0.3rem;
    margin-bottom: 1.5rem;
`;

function PathView(): JSX.Element {
    const dispatch = useDispatch<IDispatch>();
    const tasks = useSelector((state: IState) => state.builder.tasks);
    const isAdmin = useHasRole(["admin"]);
    const [shift, setShift] = useState(0);
    const [showList, setShowList] = useState(!isAdmin);
    const [_, dropRef] = useDrop({
        accept: ["SOURCE_TASK", "TARGET_TASK"],
        drop: (item: ISourceTaskItem | ITargetTaskItem, monitor: DropTargetMonitor) => {
            if (monitor.didDrop()) {
                return;
            }
            if (item.type === "SOURCE_TASK") {
                dispatch(addTaskToPath({ task: item.task, where: "end" }));
            } else {
                dispatch(moveTaskOnPath({ index: item.index, target: "end" }));
            }
        },
    });
    return (
        <Sticker>
            <OuterWrapper>
                <PathArrow flip onClick={() => setShift((s) => Math.min(s + 180, 0))} />
                <PathWrapper ref={dropRef}>
                    <Holder style={{ transform: `translate3d(${shift}px, 0px, 0px)` }}>
                        <Avatar />
                        {tasks.map((task, index) => (
                            <TargetTask key={index} index={index}>
                                <Task
                                    onClick={() => {
                                        dispatch(setModalTask({ task, index }));
                                        dispatch(openModal("Builder/PathTaskDetails"));
                                    }}
                                >
                                    <TaskIcon icon={task.icon} />
                                </Task>
                            </TargetTask>
                        ))}
                    </Holder>
                </PathWrapper>
                <PathArrow onClick={() => setShift((s) => s - 180)} />
            </OuterWrapper>
            <ListBox>
                <H5 onClick={() => setShowList((o) => !o)}>
                    {t("public.builder.heading-path-list")}
                    <PlusMinus open={showList} size="1.1rem" />
                </H5>
                {showList && <ListView />}
            </ListBox>
        </Sticker>
    );
}

export default PathView;
