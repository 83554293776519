import styled from "styled-components";
import { LinkStyleButton } from "../../shared/stylings";
import t from "../../shared/translations";
import type { IPage } from "./CreateAccount";
import type { IData } from "./index";
import LoginForm from "./LoginForm";
import type { IAccountInvitation, IReusableAccountInvitation } from "./queries";
import type { JSX } from "react";

interface IProps {
    data: IData;
    invitation: IReusableAccountInvitation | IAccountInvitation;
    teamScan: boolean | null;
    reusable: boolean;
    setPage: (page: IPage) => void;
}

const Main = styled.div`
    flex-grow: 1;
`;

const Space = styled.span`
    display: inline-block;
    width: 30px;
`;

function LoginPage({ data, setPage, invitation, teamScan, reusable }: IProps): JSX.Element {
    return (
        <Main>
            <h1>{t("public.create-account-login.heading", { title: data.webappTitle })}</h1>
            {(invitation.learningPath || teamScan) && (
                <>
                    <div>{t("public.create-account-login.info-resource")}</div>
                    <ul>
                        {invitation.learningPath && (
                            <li>
                                {t("public.create-account-create.resource-path", { pathname: invitation.learningPath })}
                            </li>
                        )}
                        {teamScan && <li>{t("public.create-account-create.resource-team-scan")}</li>}
                    </ul>
                </>
            )}
            <LoginForm data={data} reusable={reusable} />
            <div className="text-center">
                <a href="/reset-password">{t("public.create-account-login.link-forgot-password")}</a>
                <Space />
                <LinkStyleButton
                    onClick={() => {
                        setPage("Create");
                    }}
                >
                    {t("public.create-account-login.link-create")}
                </LinkStyleButton>
            </div>
        </Main>
    );
}

export default LoginPage;
