import { useSelector } from "react-redux";
import t from "../../shared/translations";
import Modal from "../Modal";
import type { IState } from "../state";
import PathView from "./PathView";
import SaveModal from "./SaveModal";
import Summaries from "./Summaries";
import TaskModal from "./TaskModal";
import type { JSX } from "react";

function BuiltPath(): JSX.Element {
    const modalTask = useSelector((state: IState) => state.builder.modalTask);
    const modalIndex = useSelector((state: IState) => state.builder.modalIndex);
    return (
        <>
            <Summaries />
            <h3>{t("public.builder.heading-learning-path")}</h3>
            <PathView />
            <Modal modal="Builder/PathTaskDetails">
                <TaskModal task={modalTask} onPath={true} index={modalIndex} />
            </Modal>
            <Modal modal="Builder/SavePath">
                <SaveModal />
            </Modal>
        </>
    );
}

export default BuiltPath;
