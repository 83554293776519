import { useDrop } from "react-dnd";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { loadJSData } from "../../shared/jsData";
import { addTaskToPath, type IDispatch, moveTaskOnPath } from "../state";
import type { ISourceTaskItem } from "./SourceTask";
import type { ITargetTaskItem } from "./TargetTask";
import type { JSX } from "react";

const Holder = styled.div`
    width: 90px;
    height: 200px;
    flex-shrink: 0;
    img {
        height: 200px;
    }
`;

function Avatar(): JSX.Element {
    const dispatch = useDispatch<IDispatch>();
    const [_, dropRef] = useDrop({
        accept: ["SOURCE_TASK", "TARGET_TASK"],
        drop: (item: ISourceTaskItem | ITargetTaskItem) => {
            if (item.type === "SOURCE_TASK") {
                dispatch(addTaskToPath({ task: item.task, where: "start" }));
            } else {
                dispatch(moveTaskOnPath({ index: item.index, target: "start" }));
            }
        },
    });
    return (
        <Holder ref={dropRef}>
            <img src={`${loadJSData().avatar_path}/male-1-walking.png`} alt="Avatar walking" />
        </Holder>
    );
}

export default Avatar;
