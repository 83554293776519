import type { JSX } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useQuery } from "urql";
import { parseData } from "../../analysis/learningPathActivity/report/reportData";
import Loader from "../../shared/components/Loader";
import ShowError from "../../shared/components/ShowError";
import CompletionsTable from "../../shared/components/learningPathActivityCharts/CompletionsTable";
import MilesBarChart from "../../shared/components/learningPathActivityCharts/MilesBarChart";
import MilesLineChart from "../../shared/components/learningPathActivityCharts/MilesLineChart";
import TaskCountLineChart from "../../shared/components/learningPathActivityCharts/TaskCountLineChart";
import t from "../../shared/translations";
import Footer from "../Footer";
import type { IActivityReportParams } from "../PublicRoutes";
import Info from "./Info";
import { type IArgs, type IData, query } from "./queries";
import { H1, H2, Main, Wrapper } from "./stylings";

function ActivityReport(): JSX.Element {
    const { publicId } = useParams<IActivityReportParams>();
    const [searchParams] = useSearchParams();
    const [result] = useQuery<IData, IArgs>({
        query,
        variables: {
            id: publicId,
            pwd: searchParams.get("pwd"),
        },
    });

    let main = null;
    if (result.fetching) {
        main = <Loader />;
    } else if (result.error) {
        main = <ShowError error={t("public.error.error")} />;
    } else {
        const report = result.data.learningPathActivityReport;
        if (!report) {
            main = (
                <>
                    <h1>{t("public.learning-path-activity-report.info-no-report")}</h1>
                    <div>{t("public.learning-path-activity-report.info-no-report")}</div>
                </>
            );
        } else {
            const { name, data, people, learningPath, createdDatetime } = report;
            const charts = parseData(data, people).charts;
            main = (
                <>
                    <H1>{name}</H1>
                    <Info learningPath={learningPath} createdDatetime={createdDatetime} />
                    {charts.map(({ chart, data, heading }, index) => {
                        if (chart === "miles-bar") {
                            return (
                                <div key={index}>
                                    <H2>{heading}</H2>
                                    <MilesBarChart
                                        chartData={data.chartData}
                                        checkpoint={data.checkpoint}
                                        goal={data.goal}
                                        deadlineGoal={data.deadlineGoal}
                                    />
                                </div>
                            );
                        }
                        if (chart === "miles-line") {
                            return (
                                <div key={index}>
                                    <H2>{heading}</H2>
                                    <MilesLineChart
                                        chartData={data.chartData}
                                        state={data.state}
                                        teams={data.teams}
                                        groups={data.groups}
                                        orgs={data.orgs}
                                        checkpoint={data.checkpoint}
                                        goal={data.goal}
                                    />
                                </div>
                            );
                        }
                        if (chart === "task-count") {
                            return (
                                <div key={index}>
                                    <H2>{heading}</H2>
                                    <TaskCountLineChart
                                        chartData={data.chartData}
                                        state={data.state}
                                        tasks={data.tasks}
                                        numMembers={data.numMembers}
                                    />
                                </div>
                            );
                        }
                        if (chart === "completions-table") {
                            return (
                                <div key={index}>
                                    <H2>{heading}</H2>
                                    <CompletionsTable
                                        completions={data.completions}
                                        pastDeadlines={data.pastDeadlines}
                                        people={data.people}
                                        tasks={data.tasks}
                                        state={data.state}
                                        miles={data.miles}
                                        counts={data.counts}
                                        totals={data.totals}
                                    />
                                </div>
                            );
                        }
                        return null;
                    })}
                </>
            );
        }
    }
    return (
        <Wrapper>
            <Main>{main}</Main>
            <Footer />
        </Wrapper>
    );
}

export default ActivityReport;
