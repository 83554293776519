import { type ComponentPropsWithoutRef, memo, type JSX } from "react";

function BarChartIcon(props: ComponentPropsWithoutRef<"svg">): JSX.Element {
    return (
        <svg
            clipRule="evenodd"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="m14.086 81.485v4.367l72-.4c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5l-71.1.5h-2.4c-.4 0-.8-.1-1.1-.4-.2-.3-.4-.7-.4-1.1v-74.6c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5v3.624h39.85c2.259 0 4.093 1.834 4.093 4.092v8.488c0 2.259-1.834 4.092-4.093 4.092h-39.85v7.496h28.889c2.259 0 4.092 1.834 4.092 4.093v8.488c0 2.258-1.833 4.092-4.092 4.092h-28.889v7.496h50.555c2.259 0 4.092 1.833 4.092 4.092v8.488c0 2.258-1.833 4.092-4.092 4.092zm51.647-12.58c0-.603-.489-1.092-1.092-1.092h-50.549v10.672h50.549c.603 0 1.092-.489 1.092-1.092zm-10.704-48.337c0-.602-.49-1.092-1.093-1.092h-39.844v10.672h39.844c.603 0 1.093-.489 1.093-1.092zm-10.962 24.169c0-.603-.489-1.093-1.092-1.093h-28.883v10.673h28.883c.603 0 1.092-.49 1.092-1.092z" />
        </svg>
    );
}

export default memo(BarChartIcon);
