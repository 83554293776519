import { useDispatch } from "react-redux";
import styled from "styled-components";
import BuilderTaskDescription from "../../shared/components/BuilderTaskDescription";
import t from "../../shared/translations";
import type { TaskIcon, TaskTheme } from "../../shared/types";
import { useHasRole } from "../CurrentPerson";
import { addTaskToPath, closeModal, type IDispatch, moveTaskOnPath, removeTaskFromPath } from "../state";
import SimplePathView from "./SimplePathView";
import type { JSX } from "react";

interface IProps {
    onPath: boolean;
    index?: number;
    task: {
        id: string;
        icon: TaskIcon;
        miles: number;
        price: number;
        length: number;
        shortTitle: string;
        description: string | null;
        shortDescription: string | null;
        learningCoachFeedback: boolean;
        isGroupTask: boolean;
        isMedia: boolean;
        isPeopleEvent: boolean;
        isLecture: boolean;
        themes: {
            id: string;
            theme: TaskTheme;
            value: number;
        }[];
    };
}

const Center = styled.div`
    text-align: center;
`;

function TaskModal({ task, onPath, index }: IProps): JSX.Element {
    const dispatch = useDispatch<IDispatch>();
    const isAdmin = useHasRole(["admin"]);
    const add = () => {
        dispatch(addTaskToPath({ task, where: "end" }));
        dispatch(closeModal());
    };
    const remove = () => {
        dispatch(removeTaskFromPath(index));
        dispatch(closeModal());
    };
    const moveEarlier = () => {
        dispatch(moveTaskOnPath({ index, target: index - 1 }));
    };
    const moveLater = () => {
        dispatch(moveTaskOnPath({ index, target: index + 1 }));
    };
    return (
        <div>
            <BuilderTaskDescription task={task} />
            {isAdmin && (
                <Center>
                    {onPath ? (
                        <>
                            <div>
                                <button onClick={moveEarlier} type="button">
                                    {t("public.builder.button-move-earlier-on-path")}
                                </button>{" "}
                                <button onClick={remove} type="button">
                                    {t("public.builder.button-remove-from-path")}
                                </button>{" "}
                                <button onClick={moveLater} type="button">
                                    {t("public.builder.button-move-later-on-path")}
                                </button>
                            </div>
                            <SimplePathView current={index} />
                        </>
                    ) : (
                        <button onClick={add} type="button">
                            {t("public.builder.button-add-to-path")}
                        </button>
                    )}
                </Center>
            )}
        </div>
    );
}

export default TaskModal;
