import { type JSX, memo } from "react";
import styled from "styled-components";
import APIErrorIcon from "./icons/APIErrorIcon";

const OuterWrapper = styled.div`
    display: grid;
    place-items: center;
    height: 100%;
    min-height: 12rem;
`;

const InnerWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    justify-items: center;

    @media (min-width: 600px) {
        grid-template-columns: 10rem 1fr;
        align-items: center;
    }

    & > div {
        font-size: 1.25rem;
    }
`;

const StyledAPIErrorIcon = styled(APIErrorIcon)`
    width: 10rem;
    height: 10rem;
    margin-right: 1rem;
`;

interface IProps {
    error: string;
}

function ShowError({ error }: IProps): JSX.Element {
    return (
        <OuterWrapper>
            <InnerWrapper>
                <StyledAPIErrorIcon />
                <div>{error}</div>
            </InnerWrapper>
        </OuterWrapper>
    );
}

export default memo(ShowError);
