import { addDays, endOfDay, endOfMonth, endOfWeek, formatYMD, startOfDay } from "../../shared/dateFns";

type RangeType = "day" | "week" | "month";

function endOfGranularity(date: Date, granularity: RangeType): Date {
    switch (granularity) {
        case "day":
            return endOfDay(date);
        case "week":
            return endOfWeek(date);
        case "month":
            return endOfMonth(date);
        default:
            return date;
    }
}

export function* dateRange<T>(
    start: Date,
    end: Date,
    type: RangeType,
    value: (date: Date) => T,
): Generator<[string, T]> {
    const firstDate = endOfGranularity(start, type);
    const lastDate = endOfGranularity(end, type);
    for (let i = firstDate, iMax = lastDate; i <= iMax; i = addDays(i, 1)) {
        const date = endOfGranularity(i, type);
        yield [formatYMD(startOfDay(date)), value(date)];
    }
}

export function* numberRange<T>(
    start: number,
    end: number,
    incr: number,
    value: (index: number) => T,
): Generator<[number, T]> {
    for (let i = start, iMax = end; i <= iMax; i = i + incr) {
        yield [i, value(i)];
    }
}

export function* idRange<T>(ids: string[], value: (id: string) => T): Generator<[string, T]> {
    for (const id of ids) {
        yield [id, value(id)];
    }
}
