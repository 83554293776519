import { useMemo } from "react";
import { sortBy } from "lodash-es";
import { addDays, isBeforeDay, isSameOrBeforeDay, parseDate } from "../../shared/dateFns";
import fmtGroupName from "../../shared/fmtGroupName";
import type { GroupBy, IState } from "./CompletionsConfig";
import { fmtPersonName } from "./milesBarData";
import type { ILearningPath, IPathMembership, ISubmittedTask, ITask } from "./queries";

interface IPerson {
    id: string;
    name: string;
    teamName: string;
    orgName: string;
    groupName: string;
}

interface IProcessedData {
    people: IPerson[];
    tasks: { id: string; shortTitle: string }[];
    deadlines: Map<string, Map<string, Date>>;
    finishDates: Map<string, Map<string, Date>>;
    miles: Map<string, Map<string, number>>;
    name: string;
}

function taskDeadline(task: ITask, pm: IPathMembership, fixed: boolean): Date {
    const callToAction = fixed
        ? parseDate(task.fixedCallToAction)
        : addDays(parseDate(pm.floatingStartDate), task.callToActionDay);
    return addDays(callToAction, task.deadlineDay);
}

export function preprocessData(path: ILearningPath, includeNames = true): IProcessedData {
    // Do all preprocessing of the path data that does not depend on config.

    const fixed = path.fixed;

    // Collect all people into an Array.
    let people: IPerson[] = [];
    const pmMap: Map<string, IPathMembership> = new Map();
    for (const pm of path.pathMemberships) {
        const person = pm.person;
        pmMap.set(person.id, pm);
        people.push({
            id: person.id,
            name: fmtPersonName(person, includeNames),
            teamName: person.team?.name,
            orgName: person.organisation.name,
            groupName: pm.pathGroup ? fmtGroupName(pm.pathGroup) : null,
        });
    }

    // Collect all tasks into an Array.
    const tasks: ITask[] = path.learningPathItems.flatMap((item) => item.tasks);

    // Collect all completed SubmittedTasks into three nested Maps of miles,  finish dates and deadline dates. The key
    // of the outer map is the task id, and the key of the inner map is the person. Also collect miles each person has
    // earned for each task.
    const miles: Map<string, Map<string, number>> = new Map();
    const finishDates: Map<string, Map<string, Date>> = new Map();
    const deadlines: Map<string, Map<string, Date>> = new Map();
    for (const item of path.learningPathItems) {
        for (const task of item.tasks) {
            const submittedMap: Map<string, ISubmittedTask> = new Map();
            for (const submittedTask of task.submittedTasks) {
                if (submittedTask.status !== "accepted") {
                    continue;
                }
                for (const pers of submittedTask.people) {
                    if (!pmMap.has(pers.id)) {
                        // Skip submitters not in the learning path.
                        continue;
                    }
                    submittedMap.set(pers.id, submittedTask);
                }
            }
            const finishMap: Map<string, Date> = new Map();
            const deadlineMap: Map<string, Date> = new Map();
            const personMiles: Map<string, number> = new Map();
            for (const pm of path.pathMemberships) {
                const personId = pm.person.id;
                const submittedTask = submittedMap.get(personId);
                if (submittedTask) {
                    finishMap.set(personId, parseDate(submittedTask.finishDatetime));
                    personMiles.set(personId, submittedTask.miles);
                }
                // Calculate the personal deadline for the task to see if it in the past.
                if (task.deadlineDay !== null) {
                    const deadline = taskDeadline(task, pm, fixed);
                    deadlineMap.set(personId, deadline);
                }
            }
            finishDates.set(task.id, finishMap);
            deadlines.set(task.id, deadlineMap);
            miles.set(task.id, personMiles);
        }
    }

    // Sort the lists alphabetically by name for display in UI
    people = sortBy(people, (person) => pmMap.get(person.id).person.lastName);

    const name = "task-completions-table.png";
    return { people, tasks, finishDates, deadlines, miles, name };
}

interface IFormattedData {
    filteredPeople: IPerson[];
    filteredTasks: { id: string; shortTitle: string }[];
    filteredMiles: Map<string, number>;
    filteredCounts: Map<string, Map<string, number>>;
    filteredTotals: Map<string, number>;
    completions: Map<string, Map<string, boolean>>;
    pastDeadlines: Map<string, Map<string, boolean>>;
}

function getGroupLabel(person: IPerson, groupBy: GroupBy): string {
    if (groupBy === "org") {
        return person.orgName || "";
    }
    if (groupBy === "group") {
        return person.groupName || "";
    }
    if (groupBy === "team") {
        return person.teamName || "";
    }
    return "";
}

export function formatData(state: IState, processedData: IProcessedData): IFormattedData {
    // Format data for charting based on config

    const { yAxis, xAxis, startRow, limitRow, shownRows, groupBy, date } = state;
    const { people, tasks, miles, deadlines, finishDates } = processedData;

    let filteredPeople = people.filter((person) => yAxis[person.id]);
    if (groupBy === "group") {
        filteredPeople = sortBy(filteredPeople, (person) => person.groupName);
    } else if (groupBy === "org") {
        filteredPeople = sortBy(filteredPeople, (person) => person.orgName);
    } else if (groupBy === "team") {
        filteredPeople = sortBy(filteredPeople, (person) => person.teamName);
    }

    // Slice data to only show specific number of rows
    if (shownRows === "limited") {
        filteredPeople = filteredPeople.slice(startRow - 1, startRow + limitRow - 1);
    }

    const filteredTasks = tasks.filter((task) => xAxis[task.id]);

    // Go through all selected tasks and people and collect two nested maps:
    // One map going taskId -> personId -> boolean tracking if a specific person has done a specific task at given date.
    // Second map going taskId -> personId -> boolean tracking if a specific person has missed their deadline.
    const completions: Map<string, Map<string, boolean>> = new Map();
    const pastDeadlines: Map<string, Map<string, boolean>> = new Map();
    for (const task of filteredTasks) {
        const personCompletions: Map<string, boolean> = new Map();
        const personPastDeadlines: Map<string, boolean> = new Map();
        for (const person of filteredPeople) {
            const finishDate = finishDates.get(task.id).get(person.id);
            const deadline = deadlines.get(task.id).get(person.id);
            if (finishDate) {
                const complete = isSameOrBeforeDay(finishDate, date);
                personCompletions.set(person.id, complete);
            } else {
                personCompletions.set(person.id, false);
            }
            const pastDeadline = isBeforeDay(deadline, date);
            personPastDeadlines.set(person.id, pastDeadline);
        }
        completions.set(task.id, personCompletions);
        pastDeadlines.set(task.id, personPastDeadlines);
    }

    // A map of how many miles each person has earned
    const filteredMiles: Map<string, number> = new Map();
    for (const person of filteredPeople) {
        let personSum = 0;
        for (const task of filteredTasks) {
            if (completions.get(task.id).get(person.id)) {
                personSum += miles.get(task.id).get(person.id) ?? 0;
            }
        }
        filteredMiles.set(person.id, personSum);
    }

    // A for totals and completed count for task and each grouping: taskId -> label -> count
    const countMap: Map<string, Map<string, number>> = new Map();
    for (const task of filteredTasks) {
        const groupingCountMap: Map<string, number> = new Map();
        for (const person of filteredPeople) {
            const label = getGroupLabel(person, groupBy);
            const hasCompleted = completions.get(task.id).get(person.id);
            let taskCount = groupingCountMap.get(label) ?? 0;
            taskCount += hasCompleted ? 1 : 0;
            groupingCountMap.set(label, taskCount);
        }
        countMap.set(task.id, groupingCountMap);
    }

    // Invert to countMap to go label -> taskId -> count
    const filteredCounts: Map<string, Map<string, number>> = new Map();
    for (const [taskId, groupingCountMap] of countMap.entries()) {
        for (const [label, count] of groupingCountMap.entries()) {
            const taskMap = filteredCounts.get(label) ?? new Map();
            taskMap.set(taskId, count);
            filteredCounts.set(label, taskMap);
        }
    }

    // A map to total number of participants in each grouping
    const filteredTotals: Map<string, number> = new Map();
    for (const person of filteredPeople) {
        const label = getGroupLabel(person, groupBy);
        let currTotal = filteredTotals.get(label) ?? 0;
        currTotal += 1;
        filteredTotals.set(label, currTotal);
    }

    return { filteredTasks, filteredPeople, filteredMiles, filteredCounts, filteredTotals, completions, pastDeadlines };
}

interface IData {
    people: IPerson[];
    filteredPeople: IPerson[];
    tasks: { id: string; shortTitle: string }[];
    filteredTasks: { id: string; shortTitle: string }[];
    name: string;
    completions: Map<string, Map<string, boolean>>;
    pastDeadlines: Map<string, Map<string, boolean>>;
    filteredMiles: Map<string, number>;
    filteredCounts: Map<string, Map<string, number>>;
    filteredTotals: Map<string, number>;
}

export function useData(path: ILearningPath, state: IState): IData {
    // Split the computing of the chart data into two separate useMemos: One for preprocessing the Learning Path data
    // that does not depend on table config, and the other the processing that is based on table config.

    const processedData = useMemo(() => {
        return preprocessData(path);
    }, [path]);

    const formattedData = useMemo(() => {
        return formatData(state, processedData);
    }, [processedData, state]);

    return {
        people: processedData.people,
        tasks: processedData.tasks,
        name: processedData.name,
        ...formattedData,
    };
}
