import { type CSSProperties, Fragment, type JSX, type MutableRefObject, memo } from "react";
import { Bar, BarChart, Cell, ResponsiveContainer, XAxis, YAxis } from "recharts";
import * as colors from "../../colors";
import maxTextWidth from "../../maxTextWidth";
import { fmtTick } from "./LinesChart";
import { Legend, LegendLine } from "./stylings";

export type IChartDatum = {
    statement: string;
    value: number;
    date: number;
};

export interface IProps {
    chartData: IChartDatum[];
    fixed: boolean;
    questions: string[];
    dates: number[];
    summaryStat: "avg" | "net";
    chartRef?: MutableRefObject<HTMLDivElement>;
    style?: CSSProperties;
}

function formatter(value: number): string {
    if (!value) {
        return "";
    }
    return value.toFixed(1);
}

const FONT = '400 15px / 1.5 "Proxima Nova", Verdana, sans-serif';

function ImpactBarChart({ chartData, questions, dates, fixed, summaryStat, chartRef, style }: IProps): JSX.Element {
    const height = chartData.length * 32 + 40;
    const maxNameSize = maxTextWidth(
        dates.map((date) => fmtTick(date, fixed)),
        FONT,
    );
    const domain = summaryStat === "avg" ? [0, 10] : [-100, 100];
    return (
        <div style={style} ref={chartRef}>
            <div>
                <ResponsiveContainer width="100%" height={height}>
                    <BarChart data={chartData} layout="vertical">
                        <YAxis
                            type="category"
                            width={dates.length > 1 ? maxNameSize + 10 : 5}
                            tick={dates.length > 1 ? { fontSize: 15 } : false}
                            tickFormatter={(value) => fmtTick(value, fixed)}
                            dataKey="date"
                            allowDuplicatedCategory={true}
                        />
                        <XAxis type="number" domain={domain} />
                        <Bar
                            isAnimationActive={false}
                            dataKey="value"
                            label={{ fill: "white", position: "insideRight", fontSize: 14, formatter }}
                        >
                            {chartData.map((datum, index) => (
                                <Cell key={index} fill={colors.chart(questions.indexOf(datum.statement))} />
                            ))}
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </div>
            <Legend>
                {questions.map((question, idx) => (
                    <Fragment key={idx}>
                        <LegendLine $color={colors.chart(idx)} $wide />
                        <span style={{ gridColumn: "span 2" }}>{question}</span>
                    </Fragment>
                ))}
            </Legend>
        </div>
    );
}

export default memo(ImpactBarChart);
