import { type JSX, memo } from "react";
import styled from "styled-components";
import t from "../../shared/translations";

interface IProps {
    tasks: {
        length: number;
    }[];
    width: string;
}

interface IWrapper {
    $width: string;
}

const Wrapper = styled.div<IWrapper>`
    width: ${({ $width }) => $width};
    display: grid;
    align-content: start;
`;

const H3 = styled.h3`
    font-size: 1.35rem;
    text-align: center;
    margin-bottom: -10px;
`;

const Weeks = styled.div`
    font-size: 1.2rem;
    text-align: center;
    padding: 3rem 0;
`;

const Length = styled.span`
    font-size: 1.8rem;
`;

function PathLength({ tasks, width }: IProps): JSX.Element {
    let length: number | null = null;
    if (tasks.length > 0) {
        const days = tasks.reduce((acc, task) => acc + task.length, 0);
        length = Math.ceil(days / 7);
    }
    return (
        <Wrapper $width={width}>
            <H3>{t("public.builder.heading-path-length")}</H3>
            {length != null && (
                <Weeks>
                    ~ <Length>{length}</Length> {t("public.builder.label-length")}
                </Weeks>
            )}
        </Wrapper>
    );
}

export default memo(PathLength);
