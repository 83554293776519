import type { FormApi, ValidationErrors } from "final-form";
import { useCallback, useState, type JSX } from "react";
import { Form } from "react-final-form";
import styled from "styled-components";
import { useMutation } from "urql";
import * as colors from "../../shared/colors";
import { useAlerts } from "../../shared/components/Alerts";
import t from "../../shared/translations";
import Input from "../Input";
import Textarea from "../Textarea";

interface IArgs {
    id: string;
    feedback: string;
    email: string;
}

const mutation = `
    mutation($id:String!,$feedback:String!,$email:String) {
        feedbackRequestSubmit(id:$id,feedback:$feedback,email:$email) {
            error
            feedbackRequest {
                __typename
            }
        }
    }
`;

interface IData {
    feedbackRequestSubmit: {
        error: string | null;
        feedbackRequest: { __typename: string } | null;
    };
}

interface IFormValues {
    feedback: string;
    email: string;
}

interface IProps {
    id: string;
}

const DEFAUTL_VALUES: IFormValues = {
    feedback: "",
    email: "",
};

const StyledTextarea = styled(Textarea)`
    textarea {
        border-radius: 1rem;
        border: 1px solid ${colors.text};
    }
    &.error textarea {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    &.error small.error {
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        border: 1px solid #222;
        border-top: none;
    }
`;

const StyledInput = styled(Input)`
    input {
        border-radius: 1rem;
        border: 1px solid ${colors.text};
    }
    &.error input {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    &.error small.error {
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        border: 1px solid #222;
        border-top: none;
    }
`;

const SuccessMessage = styled.div`
    text-align: center;
    font-size: 1.5rem;
    margin: 2rem 0;
`;

function validate(values: IFormValues): ValidationErrors {
    const errors: ValidationErrors = {};
    if (!values.feedback) {
        errors.feedback = t("public.outside-feedback.form-error-feedback-required");
    }
    if (!!values.email && !values.email.includes("@")) {
        errors.email = t("public.outside-feedback.form-error-invalid-email");
    }
    return errors;
}

function FeedbackForm({ id }: IProps): JSX.Element {
    const [_, executeMutation] = useMutation<IData, IArgs>(mutation);
    const addAlert = useAlerts();
    const [showForm, setShowForm] = useState(true);

    const submit = useCallback(
        async (values: IFormValues, form: FormApi<IFormValues>) => {
            const data: IArgs = {
                id,
                feedback: values.feedback,
                email: values.email,
            };
            const result = await executeMutation(data);
            if (result.error) {
                addAlert(t("public.outside-feedback.submit-feedback-failed"), "alert");
            } else if (result.data.feedbackRequestSubmit.error) {
                addAlert(t("public.outside-feedback.submit-feedback-failed"), "alert");
            } else {
                form.restart();
                setShowForm(false);
            }
        },
        [id, executeMutation],
    );

    if (showForm) {
        return (
            <Form<IFormValues> onSubmit={submit} validate={validate} initialValues={DEFAUTL_VALUES}>
                {({ handleSubmit, submitting }) => (
                    <form onSubmit={handleSubmit}>
                        <StyledTextarea
                            name="feedback"
                            placeholder={t("public.outside-feedback.form-label-feedback")}
                        />
                        <StyledInput name="email" label={t("public.outside-feedback.form-label-e-mail")} />
                        <div style={{ textAlign: "center" }}>
                            <button type="submit" disabled={submitting}>
                                {t("public.outside-feedback.form-submit")}
                            </button>
                        </div>
                    </form>
                )}
            </Form>
        );
    }
    return (
        <div>
            <SuccessMessage>{t("public.outside-feedback.submit-feedback-succeded")}</SuccessMessage>
        </div>
    );
}

export default FeedbackForm;
