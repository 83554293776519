import { useState, type JSX } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useMutation } from "urql";
import Loader from "../../shared/components/Loader";
import t from "../../shared/translations";
import type { IResetTokenParams } from "../PublicRoutes";
import ResetForm from "./ResetForm";

interface IArgs {
    token: string;
}

const mutation = `
    mutation($token:String!) {
        resetTokenSendPassword(token:$token) {
            error
        }
    }
`;

interface IData {
    resetTokenSendPassword: {
        error?: string;
    };
}

const Main = styled.main`
    max-width: 1000px;
    padding: 0 15px;
    margin: 0 auto;
`;

function ResetToken(): JSX.Element {
    const { token } = useParams<IResetTokenParams>();
    const [result, executeMutation] = useMutation<IData, IArgs>(mutation);
    const [sent, setSent] = useState(false);
    const send = () => {
        void executeMutation({ token });
    };

    let main = null;
    if (result.error) {
        main = (
            <>
                <p>{t("public.reset-token.info-new-reset")}</p>
                {sent ? <p>{t("public.reset-password.info-reset-link-sent")}</p> : <ResetForm setSent={setSent} />}
            </>
        );
    } else if (result.data) {
        if (result.data.resetTokenSendPassword.error) {
            main = (
                <>
                    <p>{result.data.resetTokenSendPassword.error}</p>
                    <p>{t("public.reset-token.info-new-reset")}</p>
                    {sent ? <p>{t("public.reset-password.info-reset-link-sent")}</p> : <ResetForm setSent={setSent} />}
                </>
            );
        } else {
            main = <p>{t("public.reset-token.info-password-sent")}</p>;
        }
    } else if (result.fetching) {
        main = <Loader />;
    } else {
        main = (
            <button onClick={send} type="button">
                {t("public.reset-token.button-send-password")}
            </button>
        );
    }

    return (
        <Main id="reset-password-page">
            <h1>{t("public.reset-token.heading")}</h1>
            {main}
        </Main>
    );
}

export default ResetToken;
