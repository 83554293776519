import type { JSX } from "react";
import styled from "styled-components";
import t from "../shared/translations";
import Logo from "./Logo";

const Wrapper = styled.div`
    display: grid;
    place-items: center;
    gap: 1rem;
    margin: 1rem 0;
`;

const Img = styled.img`
    width: 300px;
    height: 300px;
    max-width: 100%;
`;

function Finished(): JSX.Element {
    return (
        <Wrapper>
            <h2>{t("shared.collect-feedback.heading-finished")}</h2>
            <Img src="/static/images/highfive.svg" />
            <Logo />
        </Wrapper>
    );
}

export default Finished;
