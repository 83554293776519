import { useState } from "react";
import type { JSX } from "react";
import styled from "styled-components";
import { useQuery } from "urql";
import Loader from "../../shared/components/Loader";
import ShowError from "../../shared/components/ShowError";
import t from "../../shared/translations";
import ChoosePage from "./ChoosePage";
import CreatePage from "./CreatePage";
import Footer from "./Footer";
import Header from "./Header";
import LoginPage from "./LoginPage";
import UsedPage from "./UsedPage";
import type { IData as IPageData } from "./index";
import { type IArgs, type IData, query } from "./queries";

interface IProps {
    data: IPageData;
}

export type IPage = "Choose" | "Create" | "Login";

const Wrapper = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 15px;
    min-height: 100vh;
    display: grid;
    grid-template-rows: auto 1fr auto;
    position: relative;
`;

function CreateAccount({ data }: IProps): JSX.Element {
    const [page, setPage] = useState<IPage>("Choose");
    const [result] = useQuery<IData, IArgs>({
        query,
        variables: {
            id: data.publicId,
        },
    });
    let main = null;
    if (result.fetching) {
        main = <Loader />;
    } else if (result.error) {
        main = <ShowError error={t("public.error.error")} />;
    } else {
        const invitation = result.data.accountInvitation ?? result.data.reusableAccountInvitation;
        const reusable = result.data.reusableAccountInvitation !== null;
        if (!invitation) {
            main = <UsedPage data={data} />;
        } else if (invitation.closed) {
            main = <UsedPage data={data} />;
        } else {
            if (page === "Choose") {
                main = <ChoosePage data={data} setPage={setPage} />;
            } else if (page === "Login") {
                main = (
                    <LoginPage
                        data={data}
                        setPage={setPage}
                        invitation={invitation}
                        teamScan={result.data.accountInvitation?.teamScan}
                        reusable={reusable}
                    />
                );
            } else if (page === "Create") {
                main = (
                    <CreatePage
                        data={data}
                        setPage={setPage}
                        invitation={invitation}
                        teamScan={result.data.accountInvitation?.teamScan}
                        email={result.data.accountInvitation?.email}
                        reusable={reusable}
                    />
                );
            }
        }
    }

    return (
        <Wrapper>
            <Header />
            {main}
            <Footer />
        </Wrapper>
    );
}

export default CreateAccount;
