import { getJson } from "../api/base";

export default function (): void {
    const page = document.getElementById("quickscan-page");
    if (!page) {
        return;
    }

    const searchParams = new URLSearchParams(window.location.search);
    const url = `/quickscan/process-results?respid=${searchParams.get("respid")}`;
    getJson(url)
        .then((foldername: string) => {
            const container = document.getElementById("content-container");
            container.innerHTML = `
        <div class="chart">
            <img src="/quickscan/chart/${foldername}/matrix-learning-practices.png" class="matrix">
        </div>
        <div class="chart">
            <img src="/quickscan/chart/${foldername}/matrix-vc-vc.png" class="matrix">
        </div>
        <div class="chart">
            <img src="/quickscan/chart/${foldername}/tripod-agility-practice.png" class="agility">
        </div>
        `;
        })
        .catch((err) => {
            console.error(err);
        });
}
