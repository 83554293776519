import { type JSX, memo } from "react";
import type { ITaskThreadItem } from "./OutsideFeedback";
import ThreadItem from "./ThreadItem";
import ThreadItemAnswer from "./ThreadItemAnswer";

interface IProps {
    thread: ITaskThreadItem[];
}

function Thread({ thread }: IProps): JSX.Element {
    return (
        <section>
            {thread.map((tti) => {
                if (tti.type === "answer") {
                    return <ThreadItemAnswer key={tti.id} tti={tti} />;
                }
                return <ThreadItem tti={tti} key={tti.id} />;
            })}
        </section>
    );
}

export default memo(Thread);
