import { memo } from "react";
import type { JSX } from "react";
import type { DragElementWrapper, DragPreviewOptions } from "react-dnd";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import * as colors from "../../shared/colors";
import TaskIcon from "../../shared/components/icons/TaskIcon";
import CalendarIcon from "../../shared/components/icons/taskIcons/CalendarIcon";
import NetworkOfPeopleIcon from "../../shared/components/icons/taskIcons/NetworkOfPeopleIcon";
import PersonShadowIcon from "../../shared/components/icons/taskIcons/PersonShadowIcon";
import GlassesIcon from "../../shared/components/icons/teamScanIcons/GlassesIcon";
import t from "../../shared/translations";
import type { TaskTheme } from "../../shared/types";
import { type IBuilderTask, type IDispatch, openModal, setModalTask } from "../state";

interface IProps {
    task: IBuilderTask;
    onPath: boolean;
    previewRef?: DragElementWrapper<DragPreviewOptions>;
    showDetails: boolean;
    count?: number;
    index?: number;
}

interface IWrapper {
    $onPath?: boolean;
}

const Wrapper = styled.div<IWrapper>`
    page-break-inside: avoid;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin: 0.1rem 0;
    padding: 0.2rem 0.2rem 0 0.2rem;
    border: ${({ $onPath }) => ($onPath ? 2 : 1)}px solid ${colors.primaryColor};
    border-radius: 1rem;
    font-size: 0.85rem;
    > * {
        margin: 0 0.1rem;
    }
`;

const IconCol = styled.div`
    width: 50px;
    height: 50px;
    flex-shrink: 0;
    margin-bottom: 0.2rem;
    svg {
        width: 50px;
        height: 50px;
    }
`;

const NameCol = styled.div`
    flex-grow: 0;
    flex-basis: calc(100% - 50px - 0.4rem);
    margin-bottom: 0.2rem;
    @media print, (min-width: 800px) {
        flex-grow: 1;
        flex-basis: 0;
    }
`;

interface IH4 {
    $count?: number;
}

const H4 = styled.h4<IH4>`
    font-size: 1.1rem;
    margin: 0;
    font-weight: ${({ $count }) => ($count ? "bold" : "normal")};
`;

const SmallIconsCol = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 32px;
    width: 100%;
    justify-content: start;
    svg {
        width: 32px;
        height: 32px;
    }
    @media (min-width: 560px) {
        width: 148px;
    }
`;

const MilesCol = styled.div`
    font-size: 1rem;
    width: 60px;
    margin-bottom: 0.2rem;
    text-align: left;
`;

const ThemeCol = styled.div`
    margin-bottom: 0.2rem;
    width: 160px;
`;

const ThemeBox = styled.div`
    display: flex;
    align-items: center;
    gap: 0.2rem;
    line-height: 1.2;
`;

interface IThemeValue {
    $theme: TaskTheme;
}

const ThemeValue = styled.div<IThemeValue>`
    width: 10px;
    height: 10px;
    border-radius: 100%;
    border: 5px solid ${({ $theme }) => colors.theme[$theme]};
`;

const ButtonCol = styled.div`
    margin-bottom: 0.2rem;
    text-align: center;
    width: 80px;
    @media print, (min-width: 560px) {
        width: 80px;
    }
`;

const Button = styled.button`
    font-size: 0.9rem;
    margin-bottom: 0;
    padding: 0.3rem 1rem 0.4rem 1rem;
`;

function TaskBox({ task, onPath, previewRef, showDetails, count, index }: IProps): JSX.Element {
    const dispatch = useDispatch<IDispatch>();
    const details = () => {
        dispatch(setModalTask({ task, index: index ?? null }));
        dispatch(openModal(onPath ? "Builder/PathTaskDetails" : "Builder/TaskDetails"));
    };
    return (
        <Wrapper $onPath={onPath}>
            <IconCol ref={previewRef}>
                <TaskIcon icon={task.icon} />
            </IconCol>
            <NameCol>
                <H4 $count={count}>
                    {task.shortTitle} {count > 1 && ` (${count})`}
                </H4>
                <div>{task.shortDescription}</div>
            </NameCol>
            <SmallIconsCol>
                {task.isGroupTask && <PersonShadowIcon />}
                {task.isMedia && <GlassesIcon />}
                {task.isPeopleEvent && <NetworkOfPeopleIcon />}
                {task.isLecture && <CalendarIcon />}
            </SmallIconsCol>
            <ThemeCol>
                {task.themes.map((theme) => (
                    <ThemeBox key={theme.id}>
                        <ThemeValue $theme={theme.theme} />
                        <div>{t(`shared.task-themes.${theme.theme}`)}</div>
                    </ThemeBox>
                ))}
            </ThemeCol>
            <MilesCol>{task.miles}</MilesCol>
            {showDetails && (
                <ButtonCol>
                    <Button onClick={details}>{t("public.builder.task-button-details")}</Button>
                </ButtonCol>
            )}
        </Wrapper>
    );
}

export default memo(TaskBox);
