import * as Sentry from "@sentry/browser";
import CollectFeedback from "./collectFeedback/index";
import Builder from "./public/builder";
import CreateAccount from "./public/createAccount";
import Public from "./public/index";
import Quickscan from "./public/Quickscan";
import { config, userConfig } from "./sentry.config";
import { initDateLocale } from "./shared/dateFns";
import runOnLoad from "./shared/runOnLoad";

function init(): void {
    Sentry.init(config);
    if (userConfig) {
        Sentry.setUser(userConfig);
    }

    try {
        initDateLocale();
        if (document.getElementById("create-account-page")) {
            CreateAccount();
        } else if (document.getElementById("builder-page")) {
            Builder();
        } else if (document.getElementById("public-page")) {
            Public();
        } else if (document.getElementById("quickscan-page")) {
            Quickscan();
        } else if (document.getElementById("collect-feedback-page")) {
            CollectFeedback();
        }
    } catch (e) {
        console.error(e);
        Sentry.captureException(e);
    }
}

runOnLoad(init);
