import styled from "styled-components";

export const Legend = styled.div`
    display: grid;
    grid-template-columns: auto auto auto;
    align-items: center;
    justify-content: center;
    gap: 0.25rem 1rem;
`;

interface ILegendLine {
    $color: string;
    $borderStyle?: string;
    $opacity?: number;
    $wide?: boolean;
}

export const LegendLine = styled.span<ILegendLine>`
    display: inline-block;
    border-top: ${({ $wide }) => ($wide ? 12 : 4)}px ${({ $borderStyle }) => $borderStyle ?? "solid"} ${({ $color }) => $color};
    width: 3rem;
    opacity: ${({ $opacity }) => $opacity ?? 1};
`;
