import { formatYMD, parseDate } from "../../shared/dateFns";
import t from "../../shared/translations";
import type { ILearningPath } from "./queries";
import { Box } from "./stylings";
import type { JSX } from "react";

interface IProps {
    learningPath: ILearningPath;
    createdDatetime: string;
}

function Info({ learningPath, createdDatetime }: IProps): JSX.Element {
    const pathname = learningPath.pathname;
    const date = formatYMD(parseDate(createdDatetime));
    return (
        <Box>
            <div>{t("public.learning-path-activity-report.info-pathname", { pathname })}</div>
            <div>{t("public.learning-path-activity-report.info-report-date", { date })}</div>
        </Box>
    );
}

export default Info;
