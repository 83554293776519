import type { JSX } from "react";
import styled from "styled-components";
import t from "../shared/translations";
import Logo from "./Logo";

const Wrapper = styled.div`
    display: grid;
    place-items: center;
    min-height: 70vh;
    text-align: center;
    gap: 3rem;
    margin: 3rem;

    > * {
        margin: 0;
    }
`;

function Closed(): JSX.Element {
    return (
        <Wrapper>
            <h2>{t("shared.collect-feedback.heading-closed")}</h2>
            <Logo />
        </Wrapper>
    );
}

export default Closed;
