import { type UseFieldConfig, useField } from "react-final-form";
import type { JSX } from "react";

interface IOwnProps {
    label?: string;
    placeholder?: string;
    name: string;
    className?: string;
}

type IProps<T> = IOwnProps & UseFieldConfig<T>;

function Input<T = string>({ name, label, placeholder, className, ...props }: IProps<T>): JSX.Element {
    const {
        input,
        meta: { touched, error },
    } = useField<any, HTMLTextAreaElement>(name, props);
    return (
        <div className={touched && error ? `error ${className || ""}` : className || ""}>
            <label>
                {label && `${label}: `}
                <textarea {...input} placeholder={placeholder} />
                {touched && error && <small className="error">{error}</small>}
            </label>
        </div>
    );
}

export default Input;
